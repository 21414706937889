import React from "react";

import {
  ClickAwayListener,
  Dialog,
  Divider,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import { Clear, MoreVert } from "@material-ui/icons";

import MenuItem from "@material-ui/core/MenuItem";

export function MenuBar({ menuList, actionList, width, elemId }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, index) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    handleToggle(false);

    setTimeout(() => {
      try {
        actionList[index]();
      } catch (error) {}
    }, 100);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const menuListView = (
    <MenuList
      style={
        {
          //  zIndex: 2
        }
      }
      autoFocusItem={open}
      id="composition-menu"
      aria-labelledby="composition-button"
      onKeyDown={handleListKeyDown}
    >
      {menuList &&
        menuList.length > 0 &&
        menuList.map((item, index) => (
          <div key={index}>
            {Object.keys(item).length > 0 ? (
              <MenuItem
                key={index}
                onClick={(e) => handleClose(e, index)}
                disableRipple
              >
                {item.icon}
                {item.text}
              </MenuItem>
            ) : (
              <Divider sx={{ my: 0.5 }} />
            )}
          </div>
        ))}
      {width === "xs" && (
        <MenuItem onClick={handleClose} disableRipple>
          <Clear className="menu-icon" />
          Cancel
        </MenuItem>
      )}
    </MenuList>
  );
  return (
    <div
      style={{
        zIndex: 2,
      }}
    >
      <IconButton
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        size="small"
        onClick={handleToggle}
      >
        <MoreVert id={elemId ? elemId : ""} />
      </IconButton>
      {width === "xs" ? (
        <Dialog open={open} onClose={handleClose}>
          {menuListView}
        </Dialog>
      ) : (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          style={{
            left: "-8em",
            // zIndex: 2,
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                // transformOrigin:
                //   placement === "bottom-start" ? "left top" : "left bottom",
                transformOrigin: { horizontal: "right", vertical: "top" },
                anchorOrigin: { horizontal: "right", vertical: "bottom" },
                marginLeft: "-14em",
                // zIndex: 2,
              }}
            >
              <Paper variant="outlined">
                <ClickAwayListener onClickAway={handleClose}>
                  {menuListView}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </div>
  );
}
export default MenuBar;

// const StyledMenu = styled((props) => (
//     <Menu
//       elevation={0}
//       anchorOrigin={{
//         vertical: "bottom",
//         horizontal: "right",
//       }}
//       transformOrigin={{
//         vertical: "top",
//         horizontal: "right",
//       }}
//       {...props}
//     />
//   ))(({ theme }) => ({
//     "& .MuiPaper-root": {
//       borderRadius: 6,
//       marginTop: theme.spacing(1),
//       minWidth: 180,
//       color:
//         theme.palette.mode === "light"
//           ? "rgb(55, 65, 81)"
//           : theme.palette.grey[300],
//       boxShadow:
//         "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
//       "& .MuiMenu-list": {
//         padding: "4px 0",
//       },
//       "& .MuiMenuItem-root": {
//         "& .MuiSvgIcon-root": {
//           fontSize: 18,
//           color: theme.palette.text.secondary,
//           marginRight: theme.spacing(1.5),
//         },
//         "&:active": {
//           backgroundColor: alpha(
//             theme.palette.primary.main,
//             theme.palette.action.selectedOpacity
//           ),
//         },
//       },
//     },
//   }));

// export default function MenuBar({ menuList, actionList }) {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = (index) => {
//     setAnchorEl(null);
//     actionList[index]();
//   };
//   console.log(menuList);
//   return (
//     <>
//       {menuList && menuList.length > 0 && (
//         <>
//           <IconButton
//             id="demo-customized-button"
//             aria-controls={open ? "demo-customized-menu" : undefined}
//             aria-haspopup="true"
//             aria-expanded={open ? "true" : undefined}
//             variant="contained"
//             disableElevation
//             size="small"
//             onClick={handleClick}
//           >
//             <MoreVert />
//           </IconButton>
//           <StyledMenu
//             id="demo-customized-menu"
//             MenuListProps={{
//               "aria-labelledby": "demo-customized-button",
//             }}
//             anchorEl={anchorEl}
//             open={open}
//             className="drop-shadow-sm"
//             onClose={handleClose}
//           >
//             {menuList.map((item, index) => (
//               <>
//                 {Object.keys(item).length > 0 ? (
//                   <MenuItem
//                     key={index}
//                     onClick={() => handleClose(index)}
//                     disableRipple
//                   >
//                     {item.icon}
//                     {item.text}
//                   </MenuItem>
//                 ) : (
//                   <Divider sx={{ my: 0.5 }} />
//                 )}
//               </>
//             ))}
//           </StyledMenu>
//         </>
//       )}
//     </>
//   );
// }
