import React from "react";
import PropTypes from "prop-types";

import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { Grid, withWidth } from "@material-ui/core";

import { createTheme } from "@material-ui/core";

import Box from "@material-ui/core/Box";

import Dialog from "@material-ui/core/Dialog";

import formService from "../../../files/apis";

import QuestionsTab from "./QuestionsTab";
import ResponseTab from "../Response/ResponseTab";
import UserView from "../Responding/UserView";
import FormSettings from "./FormSettings";
import {
  THEME_VIEW,
  SETTINGS_VIEW,
  PREVIEW_FORM,
} from "../../../files/Constants";

import { formDefault } from "../../../files/Lists";

import { PreviewForm } from "./FormUtilComponents";
import { deleteArrayElement } from "../../../files/util";
import FormPageNavbar from "./FormPageNavbar";
import FormCopyDetails from "./FormCopyDetails";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "fixed",
    width: "100%",
    zIndex: 3,
    top: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 128,
    alignItems: "flex-start",
    paddingTop: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    justifySelf: "center",
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    display: "flex",
    alignContent: "space-between",
    alignItems: "center",
  },
}));

function EditForm(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState(props.userid);
  const [formID, setFormID] = React.useState("");
  const [sideView, setSideView] = React.useState(false);
  const [formDetails, setFormDetails] = React.useState({ configuration: {} });
  const [answerData, setAnswerData] = React.useState({});
  const [openOfAlert, setOpenOfAlert] = React.useState(false);
  const [forceUpdate, setForceUpdate] = React.useState(false);
  const [questions, setQuestions] = React.useState([]);

  let theme = createTheme({
    palette: {
      primary: {
        main:
          formDetails.configuration && formDetails.configuration.theme
            ? formDetails.configuration.theme
            : "#0052cc",
      },
      secondary: {
        main:
          formDetails.configuration && formDetails.configuration.theme
            ? formDetails.configuration.theme
            : "#0052cc",
      },
    },
  });
  const clipToClipboard = () => {
    navigator.clipboard.writeText(
      window.location.origin + "/" + formDetails._id
    );
    handleClickOfAlert();
    handleClose();
  };

  const handleClickOfAlert = () => {
    setOpenOfAlert(true);
  };

  const handleCloseOfAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenOfAlert(false);
  };

  function sendForm() {
    handleClickOpen();
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAnswerChange = (quesId, opId, marks, feedback) => {
    let newAnswer = answerData[quesId];
    if (!newAnswer) {
      newAnswer = {};
    }

    if (opId) {
      if (!newAnswer.answer) newAnswer["answer"] = [opId];
      else {
        //answer exist, check if opid already included
        if (newAnswer.answer.includes(opId))
          newAnswer.answer = deleteArrayElement(
            newAnswer.answer,
            newAnswer.answer.indexOf(opId)
          );
        else newAnswer.answer.push(opId);
      }
    }
    if (marks && marks > -1) newAnswer["marks"] = parseInt(marks);

    if (feedback || feedback === "") newAnswer["feedback"] = feedback;
    newAnswer["time"] = new Date().getTime();

    setAnswerData({ ...answerData, [quesId]: newAnswer });
  };
  console.log("AnswerData", answerData);
  React.useEffect(() => {
    var formId = props.formId;
    if (formId !== undefined) {
      setFormID(formId);
      fetchFormFromServer(formId);
      fetchFormAnswerFromServer(formId);
    }
  }, [props.formId]);
  const fetchFormFromServer = (formId) => {
    formService.getForm(formId).then(
      (data) => {
        //console.log(data);
        if (!data.configuration) data = { ...data, configuration: {} };
        if (!data.questions) data.questions = [];
        setFormDetails(data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
      }
    );
  };
  const fetchFormAnswerFromServer = (formId) => {
    formService.getAnswer(formId).then(
      (data) => {
        console.log("Answer response", data);
        setAnswerData(data.data ? data.data : {});
        // else
        //   console.log(
        //     "No answer found",
        //     Boolean(data),
        //     Boolean(data.status),
        //     data.status === 200
        //   );
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
      }
    );
  };
  console.log("formDetails", formDetails, "user", user);
  const handleOpenTheme = () => {
    setSideView(THEME_VIEW);
  };
  const handleOpenSettings = () => {
    setSideView(SETTINGS_VIEW);
  };
  const updateView = () => {
    setForceUpdate(!forceUpdate);
  };
  function saveQuestions() {
    console.log("auto saving questions initiated");
    var data = {
      ...formDetails,
      formId: formDetails._id,
      name: formDetails.name,
      description: formDetails.description,
      questions: questions,
    };

    formService.autoSave(data, answerData).then(
      (result) => {
        // console.log(result);
        setQuestions(result.questions);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // console.log(resMessage);
      }
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        id="main-form-section"
        style={{
          backgroundColor:
            formDetails.configuration["bgColor"] ?? formDefault.backgroundColor,
          backgroundImage: formDetails.configuration["bgImage"]
            ? `url(${formDetails.configuration["bgImage"]})`
            : "",
        }}
        className="bg-fixed bg-no-repeat bg-cover"
      >
        {formDetails.createdBy === user ? (
          <>
            {sideView !== PREVIEW_FORM ? (
              <div>
                <FormPageNavbar
                  classes={classes}
                  formDetails={formDetails}
                  handleChange={handleChange}
                  handleOpenSettings={handleOpenSettings}
                  handleOpenTheme={handleOpenTheme}
                  sendForm={sendForm}
                  setSideView={setSideView}
                  user={user}
                  userimage={props.userimage}
                  value={value}
                  width={props.width}
                />
                <div>
                  <FormCopyDetails
                    classes={classes}
                    clipToClipboard={clipToClipboard}
                    formId={formDetails._id}
                    handleClose={handleClose}
                    handleCloseOfAlert={handleCloseOfAlert}
                    open={open}
                    openOfAlert={openOfAlert}
                  />
                </div>

                <>
                  <div
                    style={{
                      paddingTop: props.width === "xs" ? "15px" : "10em",
                      paddingBottom: "30px",
                      textAlign: "-webkit-center",
                      margin: props.width === "xs" ? "1em" : "0",
                    }}
                    id="form-main"
                  >
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item xs={12} sm={9} lg={6}>
                        <TabPanel value={value} index={0}>
                          <QuestionsTab
                            formData={formDetails}
                            saveQuestions={saveQuestions}
                            setQuestions={setQuestions}
                            questions={questions}
                            answerData={answerData}
                            setAnswerData={handleAnswerChange}
                          />
                        </TabPanel>
                        <TabPanel
                          style={{ width: "100%" }}
                          value={value}
                          index={1}
                        >
                          <ResponseTab
                            formData={formDetails}
                            formId={formID}
                            answerData={answerData}
                          />
                        </TabPanel>
                        {sideView && (
                          <>
                            {props.width === "xs" ? (
                              <Dialog
                                fullScreen
                                open={true}
                                onClose={() => setSideView(null)}
                              >
                                <FormSettings
                                  forceUpdate={updateView}
                                  configJson={formDetails.configuration}
                                  type={sideView}
                                  saveQuestions={saveQuestions}
                                  closeView={() => setSideView(null)}
                                />
                              </Dialog>
                            ) : (
                              <div
                                className={
                                  props.width === "xs"
                                    ? "side-bar-full"
                                    : "side-bar"
                                }
                              >
                                <FormSettings
                                  forceUpdate={updateView}
                                  configJson={formDetails.configuration}
                                  type={sideView}
                                  saveQuestions={saveQuestions}
                                  closeView={() => setSideView(null)}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </>
              </div>
            ) : (
              <>
                <PreviewForm
                  formDetails={formDetails}
                  formID={formID}
                  user={user}
                  width={props.width}
                  userimage={props.userimage}
                  setSideView={setSideView}
                />
              </>
            )}
          </>
        ) : (
          <>
            {formDetails && formDetails.createdBy && (
              <UserView
                user={user}
                formId={formID}
                forceSmall={props.width === "xs"}
                answerData={answerData}
                userimage={props.userimage}
                formProps={formDetails}
              />
            )}
          </>
        )}
      </div>
    </ThemeProvider>
  );
}

export default withWidth()(EditForm);

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
