import React from "react";
import { useParams } from "react-router-dom";
import "tailwindcss/tailwind.css";
import "@material-tailwind/react/tailwind.css";
import { useState } from "react";
import { getSession } from "../server/auth";
import Forms from "../components/Forms";
function Home(props) {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  if (!user) getSession(setUser, true);
  console.log(props);
  return <>{user ? <Forms session={user} id={id} /> : <div>Loading</div>}</>;
}

export default Home;
