import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";

export default function FormResponseHeader({ formData, userImage, userId }) {
  return (
    <Grid
      style={{
        borderTop:
          formData.configuration && formData.configuration["theme"]
            ? `10px solid ${formData.configuration["theme"]}`
            : "10px solid teal",
        borderRadius: 10,
      }}
      item
    >
      <Paper
        style={{
          width: "100%",
          backgroundSize: "cover",
          backgroundAttachment: "local",

          backgroundImage:
            formData.configuration && formData.configuration["headerImage"]
              ? `linear-gradient(270deg,${formData.configuration["bgColor"]}00 , ${formData.configuration["bgColor"]} 80%),
                            url(${formData.configuration["headerImage"]}`
              : "",
        }}
        elevation={2}
      >
        {formData.configuration && formData.configuration["headerImage"] && (
          <Paper
            variant="outlined"
            className="header-image-section"
            style={{ opacity: 0 }}
          ></Paper>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: "15px",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Typography
            variant="h4"
            style={{
              fontFamily: "sans-serif Roboto",
              marginBottom: "15px",
            }}
          >
            {formData.name}
          </Typography>
          <Typography variant="subtitle1">{formData.description}</Typography>
        </div>
        <Divider style={{ width: "100%" }} />
        <div style={{ padding: "15px" }}>
          <div className="flex items-center">
            {userImage && (
              <img
                name="apps"
                alt=""
                loading="lazy"
                className="cursor-pointer h-8 w-8 rounded-full mr-2"
                src={userImage}
                size="2xl"
              />
            )}
            <p>
              <b>{userId}</b>
            </p>
          </div>
          <p className="text-red-500">* Required</p>
        </div>
      </Paper>
    </Grid>
  );
}
