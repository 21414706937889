import { IconButton, TextField, useTheme } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import dayjs from "dayjs";
import React from "react";
import DateValidationUI from "../validations/DateValidationUI";
import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
export default function DateField({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  time,
  edit,
  removeOption,
  questionViewData,
}) {
  console.log(questionViewData);

  return (
    <React.Fragment>
      {edit && edit === "edit" ? (
        <EditView
          optionData={optionData}
          handleOptionValue={handleOptionValue}
          uploadImage={uploadImage}
          questionIndex={questionIndex}
          optionIndex={optionIndex}
          removeOption={removeOption}
          time={time}
        />
      ) : (
        <QuestionView {...questionViewData} time={time} />
      )}
    </React.Fragment>
  );
}

const EditView = ({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  removeOption,
  time,
}) => {
  const handleValue = (e) => {
    handleOptionValue(
      { ...optionData, optionText: e.target.value },
      questionIndex,
      optionIndex
    );
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "-12.5px",
          justifyContent: "space-between",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <TextField
          fullWidth
          className="data-field"
          onChange={handleValue}
          // type={time ? "time" : "date"}
          variant="outlined"
          value={
            optionData && optionData.optionText ? optionData.optionText : ""
          }
          label="Option Text"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <IconButton
          aria-label="delete"
          onClick={() => {
            removeOption(questionIndex, optionIndex);
          }}
        >
          <Close />
        </IconButton>
      </div>
      {!time && (
        <DateValidationUI
          handleOptionValue={handleOptionValue}
          optionData={optionData}
          optionIndex={optionIndex}
          questionIndex={questionIndex}
        />
      )}
    </>
  );
};

const QuestionView = ({
  questionId,
  responseData,
  options,
  actionMethod,
  time,
  removeError,
}) => {
  const theme = useTheme();
  const handleValue = (value, optionId) => {
    // responseData[questionId] = value;
    actionMethod(questionId, value);
    removeError(questionId);
  };
  let value = responseData[questionId];
  return (
    <>
      {options.map((option, index) => (
        <div key={index} className="pr-3 w-full">
          <TextField
            // fullWidth
            focused={actionMethod !== null && Boolean(option.optionText)}
            onChange={(e) => handleValue(e.target.value, option.id)}
            type={time ? "time" : "date"}
            variant="outlined"
            disabled={actionMethod === null}
            value={value}
            label={option.optionText}
            className={actionMethod === null ? "custom-disabled" : ""}
            style={
              actionMethod === null
                ? {
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                    marginTop: 10,
                  }
                : null
            }
          />
        </div>
      ))}
    </>
  );
};
