import {
  Button,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import PaletteIcon from "@material-ui/icons/PaletteOutlined";
import { PREVIEW_FORM } from "../../../files/Constants";
import { MenuView } from "./FormUtilComponents";
export default function FormPageNavbar({
  value,
  handleChange,
  user,
  userimage,
  classes,
  formDetails,
  width,
  handleOpenTheme,
  setSideView,
  handleOpenSettings,
  sendForm,
}) {
  return (
    <header className={` ${width === "xs" ? "flex-grow-0" : classes.root}`}>
      <Paper>
        <div className="inline justify-between mr-4 ml-2 sm:flex">
          <div className="flex justify-center">
            <IconButton
              edge="start"
              className={classes.menuButton}
              style={{ color: "#140078" }}
            >
              <img src="/forms.png" className="w-10" />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              style={{ marginTop: "8.5px", color: "black" }}
            >
              {formDetails.name}
            </Typography>
          </div>

          <div>
            <Tooltip title="Theme">
              <IconButton onClick={handleOpenTheme} aria-label="search">
                <PaletteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Preview">
              <IconButton
                onClick={() => setSideView(PREVIEW_FORM)}
                aria-label="search"
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Settings">
              <IconButton onClick={handleOpenSettings} aria-label="search">
                <SettingsIcon />
              </IconButton>
            </Tooltip>
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: "capitalize" }}
              aria-label="search"
              onClick={sendForm}
            >
              Send
            </Button>
            <MenuView user={user} formId={formDetails._id} />

            <IconButton aria-label="display more actions" edge="end">
              <img
                name="apps"
                alt="user"
                loading="lazy"
                className="cursor-pointer h-8 w-8 rounded-full ml-2"
                src={userimage}
                size="2xl"
              />
            </IconButton>
          </div>
        </div>
        <Tabs
          className={classes.title}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab style={{ textTransform: "capitalize" }} label="Questions" />
          <Tab style={{ textTransform: "capitalize" }} label="Responses" />
        </Tabs>
      </Paper>
    </header>
  );
}
