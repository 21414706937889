import { Button, IconButton, TextField } from "@material-ui/core";
import React, { useState } from "react";
import Icon from "@material-tailwind/react/Icon";
import Close from "@material-ui/icons/Close";
import FileUploader from "../../FileUploader";
import AttachmentTypes from "../validations/AttachmentTypes";
import ErrorBoundary from "../../errors/ErrorBoundary";
import {
  checkIfArrayHaveAnyElement,
  checkIfTwoArrayHaveCommonElement,
  downloadFile,
  fetchNameFromUrl,
} from "../../../files/util";
import { imageTypeList } from "../../../files/Lists";
import { UseStorageDeletion } from "../../../hooks/useStorage";
import { GetApp } from "@material-ui/icons";

function FormUploadFileView({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  edit,
  removeOption,
  questionViewData,
}) {
  console.log("formfileuploader", questionViewData);

  return (
    <React.Fragment>
      {edit && edit === "edit" ? (
        <EditView
          optionData={optionData}
          handleOptionValue={handleOptionValue}
          optionIndex={optionIndex}
          questionIndex={questionIndex}
          removeOption={removeOption}
        />
      ) : (
        <QuestionView {...questionViewData} />
      )}
    </React.Fragment>
  );
}

const EditView = ({
  optionData,
  handleOptionValue,
  questionIndex,
  optionIndex,
  removeOption,
}) => {
  const handleValue = (e) => {
    handleOptionValue(
      { ...optionData, optionText: e.target.value },
      questionIndex,
      optionIndex
    );
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "-12.5px",
          justifyContent: "space-between",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <div style={{ placeSelf: "center", marginRight: 12, marginLeft: 6 }}>
          <Icon
            name="attach_file"
            color="gray"
            size="2xl"
            className="place-self-center align-center"
          />
        </div>
        <ErrorBoundary>
          <TextField
            fullWidth={true}
            variant="outlined"
            placeholder="Option text"
            style={{ marginTop: "5px" }}
            value={optionData?.optionText}
            onChange={handleValue}
          />
        </ErrorBoundary>
        <IconButton
          aria-label="delete"
          onClick={() => {
            removeOption(questionIndex, optionIndex);
          }}
        >
          <Close />
        </IconButton>
        <br />
      </div>
      <ErrorBoundary defaultMessage={"Error in attachment"}>
        <AttachmentTypes
          handleOptionValue={handleOptionValue}
          optionData={optionData}
          optionIndex={optionIndex}
          questionIndex={questionIndex}
        />
      </ErrorBoundary>
    </div>
  );
};
const QuestionView = ({
  questionId,
  responseData,
  options,
  actionMethod,
  buttonProps,
  removeError,
  customUpdate,
}) => {
  const [file, setFile] = React.useState([]);
  const [fileDialogProps, setFileDialogProps] = React.useState(null);
  const [forceUpdate, setForceUpdate] = React.useState(false);
  const [responseDataState, setResponseDataState] = React.useState(
    !responseData ? {} : responseData
  );
  const removeFile = (index) => {
    let list = responseData[questionId].split(",");
    list[index] = null;
    actionMethod(questionId, list.toString());
    let tempFile = file;
    tempFile[index] = null;
    setFile(tempFile);
    setForceUpdate(!forceUpdate);
    if (customUpdate) {
      let newResponseState = responseData;
      newResponseState[questionId] = list.toString();
      setResponseDataState(newResponseState);
      console.log("new response state", responseDataState);
    }
    if (removeError) removeError(questionId);
  };
  const addFile = ({ fileName, fileUrl, index }) => {
    let list = [];
    if (responseData[questionId]) {
      list = responseData[questionId].split(",");
      list[index] = fileUrl;
    } else {
      list.push(fileUrl);
      responseData[questionId] = list;
    }

    actionMethod(questionId, list.toString());
    let tempFile = file;
    tempFile[index] = { fileName, fileUrl };
    setFile(tempFile);
    if (removeError) removeError(questionId);
  };
  const handleFileAddClick = (index) => {
    setFileDialogProps({
      file: file[index],
      allowedFiles: options[index].allowed,
      setFileFn: addFile,
      index,
      setOpen: setFileDialogProps,
      removeFile,
    });
  };
  const handleDelete = (index) => {
    let fileUrl = responseData[questionId].split(",")[index];
    console.log(index, fileUrl, responseData);
    removeFile(index);
    UseStorageDeletion(fileUrl);
  };
  let type = (index) => {
    try {
      console.log(file);
      return actionMethod
        ? file[index].fileName.substring(
            file[index].fileName.lastIndexOf(".") + 1
          )
        : fetchNameFromUrl(
            responseData[questionId].split(",")[index]
          ).substring(
            fetchNameFromUrl(
              responseData[questionId].split(",")[index]
            ).lastIndexOf(".") + 1
          );
    } catch (error) {
      console.log(error);
    }
    return "";
  };
  return (
    <>
      {options.map((option, index) => (
        <div key={option.id}>
          {file[index] || responseData[questionId] ? (
            <>
              {checkIfTwoArrayHaveCommonElement(
                option.allowed,
                imageTypeList
              ) &&
              checkIfArrayHaveAnyElement(imageTypeList, "." + type(index)) ? (
                <>
                  <img
                    style={{ width: "50%", border: "1px solid #f5f2f2" }}
                    src={responseData[questionId].split(",")[index]}
                    alt=""
                  />
                  <br />
                </>
              ) : (
                <div className={"file flex "}>
                  <img style={{ width: 50 }} src={"/fileIcon.svg"} alt="file" />
                  <p className={"fileIcon"} style={{ marginTop: 28 }}>
                    {type(index).length > 4
                      ? type(index).substring(0, 4)
                      : type(index)}
                  </p>
                </div>
              )}
              {!actionMethod && (
                <>
                  <small>
                    {fetchNameFromUrl(
                      responseData[questionId].split(",")[index]
                    )}
                  </small>
                  <br />
                  <Button
                    color="primary"
                    onClick={() =>
                      downloadFile(
                        "file." + type(index),
                        responseData[questionId].split(",")[index]
                      )
                    }
                    startIcon={<GetApp />}
                  >
                    Download
                  </Button>
                </>
              )}
              {actionMethod && (
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ textTransform: "capitalize" }}
                  onClick={(e) => handleDelete(index)}
                  startIcon={<Close />}
                >
                  {file[index] && file[index].fileName
                    ? file[index].fileName
                    : "Remove file"}
                </Button>
              )}
            </>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              style={{ textTransform: "capitalize" }}
              onClick={(e) => handleFileAddClick(index)}
              startIcon={
                <Icon
                  name={buttonProps ? buttonProps.iconName : "file_upload"}
                />
              }
            >
              {option.optionText ? option.optionText : "Upload"}
            </Button>
          )}
          <br />
          {actionMethod && <small>Allowed types {option.allowed}</small>}
        </div>
      ))}
      {fileDialogProps && <FileUploader {...fileDialogProps} />}
    </>
  );
};

export default FormUploadFileView;
