import React, { Component } from "react";
import downloadjs from "downloadjs";

import {
  Button,
  // Card,
  // CardContent,
  Collapse,
  Divider,
  // IconButton,
  // List,
  // ListItem,
  // ListItemText,
  Paper,
  Typography,
  // TablePagination,
  // Typography,
} from "@material-ui/core";
import {
  Check,
  Clear,
  // ArrowBackIos,
  // ArrowBackIosSharp,
  ExpandLess,
  ExpandMore,
  GetApp,
  // NavigateNext,
} from "@material-ui/icons";
import ResponseTabHeader from "./ResponseTabHeader";
import {
  checkIfArrayHaveAnyElement,
  checkIfTwoArrayHaveCommonElement,
  downloadFile,
  fetchNameFromUrl,
  groupResponses,
} from "../../../files/util";
import { Alert } from "@material-ui/lab";
import {
  formTypes,
  groupByListFormType,
  imageTypeList,
  skipMarks,
} from "../../../files/Lists";
import ErrorBoundary from "../../errors/ErrorBoundary";
class QuestionTab extends Component {
  state = {
    selectedQuestionIndex: 0,
    openCollapse: false,
    responseData: {},
  };
  constructor(props) {
    super(props);
    let res = groupResponses(props.responses, props.questions);
    console.log("quesTab", res);
    this.state = {
      responseData: res,
      selectedQuestionIndex: 0,
      openCollapse: false,
    };
  }
  handleQuestionChange = (pageNumber) => {
    this.setState({ selectedQuestionIndex: pageNumber });
  };

  getUI = () => {
    try {
      let ques = this.props.questions[this.state.selectedQuestionIndex];
      let quesId = ques.id;
      let isMultiple = formTypes[ques.type].multiple === true;
      let isFile = ques.type === "file";
      console.log(
        "quesId",
        this.state.selectedQuestionIndex,
        this.props.questions[this.state.selectedQuestionIndex].id
      );
      let response = this.state.responseData[quesId];
      console.log("GetUI", response);
      const view = Object.keys(response.data).map((opIds, index2) => {
        let res = response.data[opIds];
        console.log("inside loop res", res, opIds);
        return (
          <Paper key={index2} className="mt-3 file-view">
            {isFile ? (
              <FileListView
                opIds={opIds}
                response={response}
                fileType={ques["options"][0].allowed}
              />
            ) : (
              <ResponseListView
                isMultiple={isMultiple}
                opIds={opIds}
                response={response}
              />
            )}
          </Paper>
        );
      });

      return isFile ? <div className="flex flex-wrap">{view}</div> : view;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  handleCollapseOption = () => {
    this.setState({ ...this.state, openCollapse: !this.state.openCollapse });
  };
  isOptionCorrect = (opId) => {
    try {
      let ans =
        this.props.answerData[
          this.props.questions[this.state.selectedQuestionIndex].id
        ].answer;
      return ans.includes(opId);
    } catch (error) {
      return false;
    }
  };
  render() {
    const { questions, responses, answerData, isQuiz } = this.props;
    console.log("QuestionTab props", this.props);
    console.log("QuestionTab state", this.state);

    return (
      <>
        <ErrorBoundary>
          <ResponseTabHeader
            DropdownTextArr={questions.map((item) => item.questionText)}
            handleChange={this.handleQuestionChange}
            selectedIndex={this.state.selectedQuestionIndex}
          />
          <Paper variant="outlined" className="mt-4">
            <div
              variant="body1"
              className="question-text p-6 flex justify-between"
            >
              <p> {questions[this.state.selectedQuestionIndex].questionText}</p>
              <Button
                variant="text"
                color="primary"
                onClick={this.handleCollapseOption}
                style={{ textTransform: "capitalize" }}
                startIcon={
                  !this.state.openCollapse ? <ExpandMore /> : <ExpandLess />
                }
              >
                {!this.state.openCollapse ? "View options" : "Hide options"}
              </Button>
            </div>
            <Collapse in={this.state.openCollapse} timeout="auto" unmountOnExit>
              <ErrorBoundary>
                <div className="pl-2 pb-2">
                  {questions[this.state.selectedQuestionIndex]
                    .questionImage && (
                    <div>
                      <img
                        src={
                          questions[this.state.selectedQuestionIndex]
                            .questionImage
                        }
                        width="400px"
                        height="auto"
                        alt=""
                      />
                    </div>
                  )}
                  {questions[this.state.selectedQuestionIndex].options.map(
                    (op, index) => {
                      let isOptionCorrect = this.isOptionCorrect(op.id);
                      let showStatus = !groupByListFormType.includes(
                        questions[this.state.selectedQuestionIndex].type
                      );
                      return (
                        <div
                          className={`flex justify-between answer-item ${
                            isQuiz && showStatus
                              ? isOptionCorrect
                                ? " answer-correct-item"
                                : " answer-wrong-item"
                              : ""
                          }`}
                          key={op.id}
                        >
                          <div>
                            {index + 1}
                            {". "}
                            {op.optionText}
                          </div>
                          {showStatus && isQuiz && (
                            <div>
                              {isOptionCorrect ? (
                                <Check htmlColor="green" />
                              ) : (
                                <Clear htmlColor="red" />
                              )}
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}
                  {/* If Quiz, then below view */}
                  {isQuiz &&
                    groupByListFormType.includes(
                      questions[this.state.selectedQuestionIndex].type
                    ) &&
                    !skipMarks.includes(
                      questions[this.state.selectedQuestionIndex].type
                    ) && (
                      <div>
                        <Typography color="primary">Answer Key</Typography>
                        {answerData &&
                          Object.keys(answerData).length > 0 &&
                          answerData[
                            questions[this.state.selectedQuestionIndex].id
                          ].answer.map((item, itemIndex) => (
                            <div
                              key={itemIndex + 1}
                              className="answer-item answer-correct-item"
                            >
                              {item}
                            </div>
                          ))}
                      </div>
                    )}
                </div>
              </ErrorBoundary>
            </Collapse>
          </Paper>
          <ErrorBoundary>{this.getUI()}</ErrorBoundary>
        </ErrorBoundary>
      </>
    );
  }
}

export default QuestionTab;

const ResponseListView = ({ response, opIds, isMultiple }) => {
  return (
    <>
      <div className="p-3 ">
        <p className="section-title"></p>
        <small>
          <b>{response.data[opIds].length} Responses </b>
        </small>
      </div>
      <Divider />
      <div className="m-4 pb-3">
        {isMultiple ? (
          response.data[opIds][0].optionText.split(",").map((txt, index) => (
            <Alert key={index} className="mt-3" severity="info" icon={false}>
              {txt}
            </Alert>
          ))
        ) : (
          <Alert className="mt-3" severity="info" icon={false}>
            {response.data[opIds][0].optionText ?? opIds}
          </Alert>
        )}
      </div>
    </>
  );
};

const FileListView = ({ response, opIds, fileType }) => {
  console.log("file type is", fileType, response);
  let file = fetchNameFromUrl(opIds);
  let type = file.substring(file.lastIndexOf(".") + 1);
  let userid = response.data[opIds][0].userId;
  let isImg =
    checkIfTwoArrayHaveCommonElement(imageTypeList, fileType) &&
    checkIfArrayHaveAnyElement(imageTypeList, "." + type);

  return (
    <>
      <div className="p-3 ">
        <p className="section-title"></p>
        <small>
          <p>
            File Uploaded by <b>{userid}</b>
          </p>
        </small>
      </div>
      <Divider />
      <div className="m-4 pb-3">
        <div className={`flex ${isImg ? "flex-col items-start" : ""}`}>
          {isImg ? (
            <img src={opIds} alt="" className="w-60" />
          ) : (
            <div className={"file flex "}>
              <img style={{ width: 50 }} src={"/fileIcon.svg"} alt="file" />
              <p className={"fileIcon"}>
                {type.length > 4 ? type.substring(0, 4) : type}
              </p>
            </div>
          )}
          <div className="flex flex-col">
            <small>{file}</small>
            <Button
              color="primary"
              onClick={() => downloadFile(userid + "." + type, opIds)}
              startIcon={<GetApp />}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
