import React from "react";
import { createClient } from "pexels";
import {
  CircularProgress,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  InputBase,
  Paper,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Progress } from "@material-tailwind/react";
import { useState } from "react";
import { temp_images } from "../../files/Lists";
import { createApi } from "unsplash-js";

export default function SearchImage({
  forQuestion = true,
  selectImage,
  setConfigData,
  configData,
  isSmall,
}) {
  const client = createClient(
    "563492ad6f91700001000001318ed3bf4c6640a89c80839dcd85400f"
  );
  const unsplash = createApi({
    accessKey: "tfAEtOQV5UnKXeAGvEeYRKvgS5jvpYoRkjQbjnXxPDQ",
    // fetch: fetch,
  });
  const [searchValue, setSearchValue] = React.useState("");
  const [photoList, setPhotoList] = useState(
    configData.photoList ? configData.photoList : []
  );
  const [searching, setSearching] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    configData.selectedIndex ? configData.selectedIndex : -1
  );
  React.useEffect(() => {
    if (searchValue && searchValue.length > 2) setSearching(true);
    const timeOutId = setTimeout(() => searchImage(), 2000);
    return () => clearTimeout(timeOutId);
  }, [searchValue]);
  const searchImage = () => {
    // return;
    if (searchValue && searchValue.length > 2) {
      setSearching(true);
      console.log("searching started", searchValue);
      // let images = [];
      // temp_images.photos.forEach((item) => {
      //   images.push({
      //     small: item.src.tiny,
      //     big: forQuestion ? item.src.medium : item.src.portrait,
      //     title: item.alt,
      //   });
      // });
      // setPhotoList(images);
      // setSearching(false);
      // setTimeout(() => {
      //   setConfigData("search", { photoList: images });
      // }, 1000);
      // return;

      let temp = [];
      setPhotoList(temp);
      setTimeout(() => {
        pexelApiSearch(temp);
        pixabayApiSearch(temp);
        unsplashedApiSearch(temp);
      }, 1000);
    } else {
      setSearching(false);
    }
  };
  const pexelApiSearch = async (temp) => {
    client.photos
      .search({ query: searchValue, per_page: 100 })
      .then((photos) => {
        console.log(photos);
        // let images = [];
        photos.photos.forEach((item) => {
          temp.push({
            small: item.src.tiny,
            big: forQuestion ? item.src.large : item.src.landscape,
            title: item.alt,
            from: "pexel",
          });
        });
        setPhotoList(temp);
        setSearching(false);
        setTimeout(() => {
          setConfigData("search", { photoList: temp });
        }, 1000);
      });
  };
  const pixabayApiSearch = async (images, isVideo = false) => {
    fetch(
      `https://pixabay.com/api/?key=30688794-694014507dc780ec2c5ff4084&q=${encodeURI(
        searchValue
      )}&image_type=${isVideo ? "video" : "photo"}&pretty=true`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.total) {
          data.hits.forEach((item) => {
            images.push({
              small: item.webformatURL,
              big: item.largeImageURL,
              title: item.tags,
              from: "pixabay",
            });
          });
          setPhotoList(images);
          setSearching(false);
          setTimeout(() => {
            setConfigData("search", { photoList: images });
          }, 1000);
        }

        console.log("pixabay", data);
      });
  };
  const unsplashedApiSearch = async (images, isVideo = false) => {
    unsplash.search
      .getPhotos({ query: searchValue, perPage: 100, orderBy: "relevant" })
      // .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          data.response.results.forEach((item) => {
            images.push({
              small: item.urls.small,
              big: item.urls.regular,
              title: item.alt_description,
              from: "unsplashed",
            });
          });
          setPhotoList(images);
          setSearching(false);
          setTimeout(() => {
            setConfigData("search", { photoList: images });
          }, 1000);
        }
        console.log("unsplashed", data);
      });
  };
  const handleSearchValue = (event) => {
    setSearchValue(event.target.value);
    setConfigData("search", { searchValue: event.target.value });
  };
  console.log(searchValue, photoList);
  const handleImageClick = (img, index) => {
    setSelectedIndex(index);
    selectImage({
      fileName: img.title.length > 20 ? img.title.substring(0, 20) : img.title,
      fileUrl: img.big,
      file: img,
      fileSize: 100,
      timestamp: new Date().getTime(),
    });
    setConfigData("search", { selectedIndex: index });
  };
  return (
    <div>
      <CustomizedInputBase
        handleValue={handleSearchValue}
        isSearching={searching}
        forceSearch={searchImage}
        searchValue={searchValue ? searchValue : configData.searchValue}
      />
      <br />
      <ImageList variant="masonry" cols={isSmall ? 2 : 3} gap={8}>
        {photoList.map((item, index) => (
          <ImageListItem
            key={item.small}
            style={
              isSmall
                ? { width: 130, height: 130 }
                : { width: 200, height: 200 }
            }
            className={`cursor-pointer ${
              index === selectedIndex ? "selected-image" : ""
            }`}
            onClick={() => handleImageClick(item, index)}
          >
            <img
              src={`${item.small}`}
              // srcSet={`${item.small}`}
              style={
                isSmall
                  ? { width: 110, height: 110 }
                  : { width: 180, height: 180 }
              }
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
function CustomizedInputBase({
  searchValue,
  handleValue,
  isSearching,
  forceSearch,
}) {
  return (
    <Paper
      style={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        justifyContent: " space-between",
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      <InputBase
        style={{ width: "100%" }}
        value={searchValue}
        onChange={handleValue}
        placeholder="Search images"
        inputProps={{ "aria-label": "search google maps" }}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={forceSearch}
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
        >
          <Search />
        </IconButton>
        {isSearching && (
          <div style={{ display: "flex" }}>
            <Divider style={{ width: 1, height: 28 }} orientation="vertical" />
            <CircularProgress
              style={{ width: 20, height: 20, marginLeft: 10, marginRight: 10 }}
            />
          </div>
        )}
      </div>
    </Paper>
  );
}
