import React from "react";
import ErrorBoundary from "../errors/ErrorBoundary";

import { Pie } from "react-chartjs-2";
import { getRandomColors } from "../../files/util";

export default class PieGraph extends React.Component {
  state = {
    data: null,
  };
  componentDidMount() {
    let colors = getRandomColors(this.props.data.length);
    this.setState({
      data: {
        labels: this.props.labels,
        datasets: [
          {
            label: "",
            backgroundColor: colors,
            hoverBackgroundColor: colors,
            data: this.props.data,
            hoverBorderWidth: "5",
          },
        ],
      },
    });
  }
  render() {
    console.log(this.state);

    return (
      <div className="max-h-80 w-80">
        {this.state.data && (
          <ErrorBoundary>
            <Pie
              data={this.state.data}
              options={{
                plugins: {
                  title: {
                    display: false,
                    text: this.props.title,
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                    position: this.props.isSmall ? "bottom" : "right",
                  },
                  labels: {
                    render: "percentage",
                    fontColor: this.props.data.map((item, index) => "#ffffff"),
                    precision: 2,
                  },
                },
              }}
            />
          </ErrorBoundary>
        )}
      </div>
    );
  }
}
