import { useState } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import formService from "../files/apis";

import { getSession } from "../server/auth";
import Forms from "../components/Forms";
import { REQUIRE_LOGIN } from "../files/Constants";

function ViewForm(props) {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  React.useEffect(() => {
    //check form configuration
    console.log("use effect");
    getSession(handleResponse, false);
  }, []);
  const handleResponse = (res) => {
    console.log("Handle response ", res);
    if (res) {
      if (!res.uid) {
        formService.getForm(id).then(
          (data) => {
            console.log(data);
            let initiateLogin = false;
            if (!data.configuration) initiateLogin = true;
            else {
              if (data.configuration[REQUIRE_LOGIN]) initiateLogin = true;
              else setUser({ email: "", name: "", image: "", uid: "" });
            }
            if (initiateLogin) getSession(setUser, true);
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            console.log(resMessage);
          }
        );
      } else setUser(res);
    } else console.log("Handle response failed", res);
  };
  //   if (!user) getSession(setUser, true);
  console.log(props);
  return <>{user ? <Forms session={user} id={id} /> : <div>Loading</div>}</>;
}

export default ViewForm;
