import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import Icon from "@material-tailwind/react/Icon";
import React from "react";
import ErrorBoundary from "../../errors/ErrorBoundary";

export default function ResponseTabHeader({
  DropdownTextArr,
  handleChange,
  selectedIndex,
}) {
  console.log("dropdown", DropdownTextArr, selectedIndex);
  return (
    <Paper variant="outlined" className="p-3">
      {DropdownTextArr && DropdownTextArr.length > 0 && (
        <Grid container>
          <Grid item>
            <DropdownView
              DropdownTextArr={DropdownTextArr}
              handleChange={handleChange}
              selectedIndex={selectedIndex}
            />
          </Grid>
          <Grid
            item
            className="flex space-x-3 place-items-center ml-2 form-detail"
          >
            <IconButton
              size="small"
              onClick={() => handleChange(selectedIndex - 1)}
              disabled={selectedIndex === 0}
            >
              <Icon name="chevron_left" size="2xl" />
            </IconButton>
            <Typography variant="body2">
              {selectedIndex + 1} of {DropdownTextArr.length}
            </Typography>
            <IconButton
              size="small"
              onClick={() => {
                handleChange(selectedIndex + 1);
              }}
              disabled={selectedIndex === DropdownTextArr.length - 1}
            >
              <NavigateNext />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

const DropdownView = ({ DropdownTextArr, handleChange, selectedIndex }) => {
  const handleClick = (e) => {
    // console.log(e.target.value);
    // document.getElementById("dropdown-button").click();
    handleChange(parseInt(e.target.value));
    // console.log("drop down clicked");
  };
  return (
    <ErrorBoundary>
      <select
        id="dropdown-button"
        value={selectedIndex}
        className=" border-2 font-semibold py-2 px-4 rounded inline-flex items-center"
        ripple="light"
        onChange={handleClick}
      >
        {DropdownTextArr.map((option, index) => (
          <option color="lightBlue" ripple="light" key={index} value={index}>
            {option.length > 100 ? option.subString(0, 100) : option}
          </option>
        ))}
      </select>
    </ErrorBoundary>
  );
};
