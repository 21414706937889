import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
} from "@material-ui/core";
import React from "react";
import Alert from "@material-ui/lab/Alert";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-tailwind/react/Icon";
import { FileUploaderToStorage } from "./imageSelctor/LocalImage";
import { useParams } from "react-router-dom";
import { UseStorageDeletion } from "../hooks/useStorage";
function FileUploader({
  setOpen,
  setFileFn,
  file,
  allowedFiles,
  index,
  removeFile,
}) {
  const { id } = useParams();
  let dropArea;
  const [draggedOn, setDraggedOn] = React.useState(false);
  const [fileSelected, setFileSelected] = React.useState(null);
  const [showProgressLoader, setShowProgressLoader] = React.useState(false);
  const [startUpload, setStartUpload] = React.useState(false);

  const [message, setMessage] = React.useState({ error: null, message: null });
  React.useEffect(() => {
    setListeners();
  }, []);
  const setListeners = () => {
    try {
      if (!dropArea) dropArea = document.getElementById("drop-area");
      dropArea.addEventListener("dragenter", changeViewOnDragEnter, false);
      dropArea.addEventListener("dragleave", changeViewOnDragLeave, false);
      dropArea.addEventListener("dragover", changeVIewOnDragOver, false);
      dropArea.addEventListener("drop", handleFile, false);
    } catch (error) {}
  };
  const changeVIewOnDragOver = (event) => {
    event.preventDefault();
  };
  const changeViewOnDragEnter = (event) => {
    event.preventDefault();

    event.dataTransfer.effectAllowed = "copy"; // required to enable drop on DIV
    if (message) setMessage({ error: null, message: null });
    setDraggedOn(true);
  };
  const changeViewOnDragLeave = (event) => {
    if (dropArea && !dropArea.contains(event.relatedTarget)) {
      // Here it is only dragleave on the parent
      event.preventDefault();
      setDraggedOn(false);
    }
  };
  const handleFile = (event, fileClick) => {
    console.log(event, fileClick);
    setDraggedOn(false);
    try {
      event.preventDefault();
      let dt;
      if (!fileClick) dt = event.dataTransfer;
      else dt = event.target;
      let file = dt.files[0];
      var reader = new FileReader();
      let preview = document.getElementById("user-preview-img-upload");
      reader.onloadend = function () {
        preview.src = reader.result;
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        preview.src = "";
      }
      let fileData = {
        fileName:
          file.name.length > 20
            ? file.name.substring(0, 20) +
              file.name.substring(file.name.lastIndexOf("."))
            : file.name,
        fileUrl: "",
        index,
        file: file,
        fileSize: file.size,
      };
      setFileSelected(fileData);
      setStartUpload(true);
      // setOpen(null);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUploadedFile = (file) => {
    console.log(file, fileSelected);
    fileSelected["fileUrl"] = file;
    setFileFn(fileSelected);
    setOpen(null);
  };
  const activateMediaSelector = () => {
    let type = allowedFiles;
    const fileInput = document.getElementById("file");
    fileInput.accept = type;
    fileInput.click();
  };
  console.log(file);
  return (
    <React.Fragment>
      <Dialog open={true} fullWidth>
        <DialogTitle>Insert File</DialogTitle>
        <Divider />
        <div style={{ textAlign: "-webkit-center" }}>
          {!file ? (
            <Paper
              elevation={2}
              id="drop-area"
              style={{
                // width: "64%",
                // marginTop: "2%",
                // marginBottom: "2%",
                backgroundColor: "#f2f6fc",
                // minHeight: "302px",
                // maxHeight: "30em",
                padding: 15,
              }}
            >
              <Icon
                name="file_upload"
                color="blue"
                size="3xl"
                alt="select folder"
                style={{ width: 100, height: 81 }}
              />
              {!showProgressLoader && (
                <p className="upload-text">
                  {!draggedOn
                    ? ` Drag your ${allowedFiles} file here to start uploading.`
                    : "Drop your file here"}
                </p>
              )}
              {!draggedOn && !showProgressLoader && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      placeContent: "center",
                    }}
                  >
                    <Divider className="divider-horizontal" />
                    <p style={{ margin: 10 }}>OR</p>
                    <Divider className="divider-horizontal" />
                  </div>

                  <div style={{ display: "flex", placeContent: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className="browse-bt"
                      onClick={activateMediaSelector}
                    >
                      Browse files
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        textTransform: "capitalize",
                        borderRadius: 10,
                        marginLeft: 10,
                      }}
                      onClick={() => setOpen(null)}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              )}
              {startUpload && (
                <FileUploaderToStorage
                  file={fileSelected}
                  userInfo={{ data: id }}
                  // context={contextData}
                  setFile={handleUploadedFile}
                />
              )}
              {message.error && (
                <React.Fragment>
                  <br />
                  <Alert className="alert-box" severity="error">
                    {message.error}
                  </Alert>
                </React.Fragment>
              )}
              {message.message && (
                <React.Fragment>
                  <br />
                  <Alert className="alert-box" severity="success">
                    {message.message}
                  </Alert>
                </React.Fragment>
              )}
              <input
                type="file"
                id="file"
                onChange={(e) => handleFile(e, true)}
                hidden="hidden"
              />
            </Paper>
          ) : (
            <div>
              <table>
                <tr>
                  <td>{file.fileName}</td>
                  <td>
                    <img
                      style={{
                        border: "1px dotted #eae9e9",
                        padding: 4,
                        marginTop: 10,
                        width: 200,
                      }}
                      id="user-preview-img-upload"
                      alt={file.fileName}
                    />
                  </td>
                  <td>
                    <Icon
                      onClick={() => {
                        removeFile(index);
                        UseStorageDeletion(file.url);
                      }}
                      name="close"
                    />
                  </td>
                </tr>
              </table>
            </div>
          )}
        </div>
      </Dialog>
    </React.Fragment>
  );
}

export default FileUploader;
