import { Dialog } from "@material-ui/core";
import { DialogTitle, Divider } from "@material-ui/core";
import React from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
const ConfirmationDialog = ({ open, dialogProps }) => {
  const {
    title,
    message,
    primaryAction,
    secondAction,
    primaryButtonText,
    secondButtonText,
    primaryButtonColor,
  } = dialogProps;
  var messageMarkup;
  messageMarkup =
    message && !message.includes("\n") ? (
      message
    ) : (
      <React.Fragment>
        <h3>{message.substring(0, message.indexOf("\n"))}</h3>
        <ul>
          {message
            .substring(message.indexOf("\n"))
            .trim()
            .split("\n")
            .map((item) => (
              <React.Fragment>
                {item && item.trim() !== "" && <li key={item}>{item}</li>}
              </React.Fragment>
            ))}
        </ul>
      </React.Fragment>
    );
  return (
    <Dialog
      onClose={secondAction}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {messageMarkup}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {primaryButtonText && (
          <Button
            style={primaryButtonColor ? { color: primaryButtonColor } : null}
            onClick={() => primaryAction(title)}
            color="primary"
          >
            {primaryButtonText}
          </Button>
        )}
        {secondButtonText && (
          <Button onClick={secondAction} color="primary" autoFocus>
            {secondButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
