import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import {
  addExtraDataInArray,
  fetchMarks,
  getRandomColors,
  getStats,
  groupBy,
} from "../../files/util";

export default class ScoreInsights extends Component {
  state = {
    data: [],
    labels: [],
  };
  constructor(props) {
    super(props);
    console.log(props.data);
    let marksArray = fetchMarks(props.data);
    let data = groupBy(marksArray);
    data = addExtraDataInArray(data, props.totalMarks);
    console.log(marksArray, data);
    let colors = getRandomColors(1);
    let labels = Object.keys(data);
    let values = Object.values(data);
    this.state = {
      colors: colors,
      values: values,
      marksArray: marksArray,
      data: {
        labels: labels,
        datasets: [
          {
            label: "# of respondents",
            backgroundColor: colors,
            hoverBackgroundColor: colors,
            data: values,
          },
        ],
      },
    };
  }
  render() {
    const { mean, median, range } = getStats(this.state.marksArray);
    console.log(mean, median, range);
    return (
      <div>
        <div className="flex">
          <div className="insights-item">
            <p>Average</p>
            <p>
              {mean} / {this.props.totalMarks} points
            </p>
          </div>
          <div className="insights-item">
            <p>Median</p>
            <p>
              {median} / {this.props.totalMarks} points
            </p>
          </div>
          <div className="insights-item">
            <p>Range</p>
            <p>{range[0] + " - " + range[1]} points</p>
          </div>
        </div>
        {this.props.data && (
          <>
            <Bar
              data={this.state.data}
              options={{
                indexAxis: "x",
                responsive: true,
                plugins: {
                  title: {
                    display: false,
                    text: this.props.title,
                    fontSize: 20,
                  },
                  legend: {
                    display: false,
                    position: this.props.isSmall ? "bottom" : "right",
                  },
                },
                scales: {
                  y: {
                    title: {
                      display: true,
                      text: "# of respondents",
                    },
                    // type: "linear",
                    suggestedMin: 0,
                    ticks: {
                      precision: 0,
                    },
                  },
                  x: {
                    title: {
                      display: true,
                      text: "Points Scored",
                    },
                    ticks: {
                      maxTicksLimit: 20,
                    },
                  },
                },
              }}
            />
          </>
        )}
      </div>
    );
  }
}
