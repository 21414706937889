import { Paper, Snackbar } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { connect } from "react-redux";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "../store/actiontypes";
import store from "../store/store";

function GlobalMessage(props) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  React.useEffect(() => {
    console.log("Error triggered");
    setError(props.errorMessage);
    setSuccess("");
    if (props.errorMessage)
      setTimeout(() => {
        store.dispatch({ type: ERROR_MESSAGE, payload: "" });
      }, 8000);
  }, [props.errorMessage]);

  React.useEffect(() => {
    console.log("success triggered");

    setSuccess(props.successMessage);
    setError("");
    if (props.successMessage)
      setTimeout(() => {
        store.dispatch({ type: SUCCESS_MESSAGE, payload: "" });
      }, 8000);
  }, [props.successMessage]);
  return (
    <>
      {(error || success) && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={Boolean(success || error)}
          autoHideDuration={8000}
        >
          <Paper variant="elevation">
            <Alert
              severity={success ? "success" : "error"}
              //   sx={{ width: "100%" }}
            >
              {success ? success : error}
            </Alert>
          </Paper>
        </Snackbar>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.data.errorMessage,
    successMessage: state.data.successMessage,
  };
};

export default connect(mapStateToProps, null)(GlobalMessage);
