import {
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React from "react";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import Close from "@material-ui/icons/Close";

export default function SingleSelection({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  edit,
  removeOption,
  answerData,
  questionViewData,
}) {
  console.log("questionViewData", questionViewData, edit);

  return (
    <React.Fragment>
      {edit === "edit" && (
        <EditView
          optionData={optionData}
          handleOptionValue={handleOptionValue}
          uploadImage={uploadImage}
          questionIndex={questionIndex}
          optionIndex={optionIndex}
          removeOption={removeOption}
        />
      )}
      {edit === "question" && <QuestionView {...questionViewData} />}
      {edit === "answer" && (
        <>
          <p>Answer view 1</p>
          {/* <AnswerView
          optionData={optionData}
          handleOptionValue={handleOptionValue}
          uploadImage={uploadImage}
          questionIndex={questionIndex}
          optionIndex={optionIndex}
          removeOption={removeOption}
          answerData={answerData}
        /> */}
        </>
      )}
    </React.Fragment>
  );
}

const EditView = ({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  removeOption,
}) => {
  const handleValue = (e) => {
    handleOptionValue(
      { ...optionData, optionText: e.target.value },
      questionIndex,
      optionIndex
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginLeft: "-12.5px",
        justifyContent: "space-between",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
    >
      <Radio disabled />
      <TextField
        fullWidth={true}
        variant="outlined"
        placeholder="Option text"
        style={{ marginTop: "5px" }}
        value={optionData.optionText}
        onChange={handleValue}
      />

      <IconButton
        aria-label="upload image"
        onClick={() => {
          uploadImage(questionIndex, optionIndex);
        }}
      >
        <CropOriginalIcon />
      </IconButton>
      <IconButton
        aria-label="delete"
        onClick={() => {
          removeOption(questionIndex, optionIndex);
        }}
      >
        <Close />
      </IconButton>
    </div>
  );
};

const QuestionView = ({
  questionId,
  responseData,
  removeError,
  options,
  actionMethod,
}) => {
  const [forceUpdate, setForceUpdate] = React.useState(false);
  const handleChange = (e) => {
    if (actionMethod) actionMethod(questionId, e.target.value);
    if (removeError) removeError(questionId);
    setForceUpdate(!forceUpdate);
  };
  return (
    <RadioGroup
      aria-label="quiz"
      name="quiz"
      value={responseData[questionId] ? responseData[questionId] : ""}
      onChange={handleChange}
    >
      {options.map((option, index) => (
        <div key={option.id}>
          <div
            style={{
              display: "flex",
              marginLeft: "7px",
            }}
          >
            <FormControlLabel
              value={option.id}
              className={
                actionMethod === null && option.id === responseData[questionId]
                  ? "custom-disabled"
                  : ""
              }
              control={
                <Radio disabled={actionMethod === null} name={option.id} />
              }
              label={option.optionText}
            />
          </div>

          <div
            style={{
              display: "flex",
              marginLeft: "10px",
            }}
          >
            {option.optionImage !== "" ? (
              <img src={option.optionImage} width="64%" height="auto" alt="" />
            ) : (
              ""
            )}
            <Divider />
          </div>
        </div>
      ))}
    </RadioGroup>
  );
};

const AnswerView = ({
  questionId,
  responseData,
  removeError,
  options,
  answerData,
  actionMethod,
}) => {
  console.log("Answer view");
  const [forceUpdate, setForceUpdate] = React.useState(false);
  const handleChange = (optionId) => {
    let list = [];
    if (answerData[questionId]) list = answerData[questionId].split(",");

    if (!list.includes(optionId)) {
      list.push(optionId);
    } else {
      const index = list.indexOf(optionId);
      if (index > -1) {
        list.splice(index, 1);
      }
    }
    return (
      <>
        {options.map((option, index) => (
          <div key={option.id}>
            <div
              style={{
                display: "flex",
                marginLeft: "7px",
              }}
            >
              <FormControlLabel
                value={option.id}
                className={actionMethod === null ? "custom-disabled" : ""}
                control={
                  <Radio
                    disabled={actionMethod === null}
                    checked={answerData[questionId].includes(option.id)}
                    name={option.id}
                  />
                }
                label={option.optionText}
                onClick={() => handleChange(option.id)}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginLeft: "10px",
              }}
            >
              {option.optionImage !== "" ? (
                <img src={option.optionImage} width="64%" height="auto" />
              ) : (
                ""
              )}
              <Divider />
            </div>
          </div>
        ))}
      </>
    );
  };
};
