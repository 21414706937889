/* eslint-disable no-undef */
import DateField from "../components/forms/components/DateField";
import Dropdown from "../components/forms/components/Dropdown";
import FormFileUploader from "../components/FormFileUploader";
import MultiSelection from "../components/forms/components/MultiSelection";
import FormRating from "../components/forms/components/FormRating";
import SingleSelection from "../components/forms/components/SingleSelection";
import TextDataField from "../components/forms/components/TextDataField";
import PassageView from "../components/forms/components/PassageView";
import { v4 as uuid } from "uuid";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import exportFromJSON from "export-from-json";
import dayjs from "dayjs";
import FormUploadFileView from "../components/forms/components/FormUploadFileView";
const {
  fileTypes,
  ignoreFormTypeInResponse,
  formTypes,
  colors,
  groupByListFormType,
  groupByListFormMarksOptional,
  skipMarks,
} = require("./Lists");

export const getFileType = (fileName) => {
  var path;
  fileTypes.forEach((item) => {
    if (item.dbPath === fileName) path = item.path;
  });
  return path ? "/" + path : "";
};

export const prepareSheetData = (oldState, newState) => {
  console.log("util old state", oldState, newState);
  var arr = [];

  if (oldState.length === 1 && oldState[0].name !== newState.name) {
    arr.push(oldState[0]);
    arr.push(newState);
  } else if (oldState.length !== 1) arr.push(newState);
  else
    oldState.forEach((item) => {
      if (newState.name !== item.name) arr.push(newState);
      else arr.push(item);
    });
  console.log("prepared sheet data", arr);
  return arr;
};

export const generateUniqueId = () => {
  const unique_id = uuid();
  return unique_id;
  // const chars =
  //   "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  // let autoId = "";
  // while (autoId.length < 20) {
  //   const bytes = randomBytes(40);
  //   bytes.forEach((b) => {
  //     // Length of `chars` is 62. We only take bytes between 0 and 62*4-1
  //     // (both inclusive). The value is then evenly mapped to indices of `char`
  //     // via a modulo operation.
  //     const maxValue = 62 * 4 - 1;
  //     if (autoId.length < 20 && b <= maxValue) {
  //       autoId += chars.charAt(b % 62);
  //     }
  //   });
  // }
  // return autoId;
};

export const isNumber = (text, getMessage) => {
  text += "";
  let test = /^\d+$/.test(text);
  if (getMessage) return test ? null : "Only number is allowed";
  return test;
};

export const getFormComponent = (
  type,
  edit,
  editViewProps,
  questionViewProps
) => {
  console.log("type", type);
  switch (type) {
    case "singleSelection":
      return (
        <SingleSelection
          {...editViewProps}
          edit={edit}
          questionViewData={questionViewProps}
        />
      );
    case "multiSelection":
      return (
        <MultiSelection
          {...editViewProps}
          edit={edit}
          questionViewData={questionViewProps}
        />
      );
    case "text":
      return (
        <TextDataField
          {...editViewProps}
          edit={edit}
          questionViewData={questionViewProps}
        />
      );
    case "date":
      return (
        <DateField
          {...editViewProps}
          edit={edit}
          questionViewData={questionViewProps}
        />
      );
    case "time":
      return (
        <DateField
          time
          {...editViewProps}
          edit={edit}
          questionViewData={questionViewProps}
        />
      );
    case "rating":
      return (
        <FormRating
          {...editViewProps}
          edit={edit}
          questionViewData={questionViewProps}
        />
      );
    case "file":
      return (
        <FormUploadFileView
          {...editViewProps}
          edit={edit}
          questionViewData={questionViewProps}
        />
      );
    case "list":
      return (
        <Dropdown
          {...editViewProps}
          edit={edit}
          questionViewData={questionViewProps}
        />
      );
    case "passage":
      return (
        <PassageView
          {...editViewProps}
          edit={edit}
          questionViewData={questionViewProps}
        />
      );
    default:
      return null;
  }
};
export const findObj = (value, arrJson, key) => {
  if (!value || !key || !arrJson) return -1;
  for (let i = 0; i < arrJson.length; i++) {
    const element = arrJson[i][key];
    if (element === value) return i;
  }
  return -1;
};
export const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;
  console.log("to shuffled ", array);

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  console.log("shuffled array", array);
  return array;
};
export const checkIfRequired = (data, questions) => {
  let json = {};
  questions.forEach((ques) => {
    if (!data[ques.id] && ques.required) json[ques.id] = "Cannot be empty";
  });
  if (Object.keys(json).length === 0) return null;
  else return json;
};
export const validations = (validationList, value) => {
  try {
    if (value)
      for (let index = 0; index < validationList.length; index++) {
        const validationType = validationList[index];
        const validationMethod = getValidationMethod(validationType);
        console.log(validationType, "value", value);

        try {
          const response = validationMethod(value, true);
          if (response) return response;
        } catch (error) {}
      }
  } catch (error) {}
  return false;
};

export const getValidationMethod = (type) => {
  switch (type) {
    case "number":
      return isNumber;
    case "email":
      return isEmail;
    case "phone":
      return isPhone;
    case "mobile":
      return isMobile;
    case "between":
      return isNumberBetween;
    case "range":
      return isDateRange;

    default:
      return;
  }
};

export const isEmail = (value) => {
  let test = /\S+@\S+\.\S+/.test(value);
  if (getMessage) return test ? null : "Invalid email id";

  return test;
};

export const isPhone = (value) => {
  let test = isNumber(value) && value.length === 6;

  if (getMessage) return test ? null : "Phone number should be of length 6";

  return test;
};

export const isMobile = (value) => {
  let test = isNumber(value) && value.length === 10;
  if (getMessage) return test ? null : "Mobile number should be of length 10";

  return test;
};

export const isNumberBetween = (value) => {
  let test = isNumber(value);
  if (getMessage) return test ? null : "Number should be between";

  return test;
};
export const isDateRange = (value) => {
  let test = true;
  if (getMessage) return test ? null : "Date should be between";

  return test;
};

export const prepareFormResponse = (questions, response) => {
  console.log("received props ques", questions, "res", response);
  let responseCount = [];
  questions.forEach((ques) => {
    if (!ignoreFormTypeInResponse.includes(ques.type)) {
      let option = {};
      ques.options.forEach((op) => {
        if (!formTypes[ques.type].multiple)
          option[ques.type] = {
            optionText: op.optionText,
            response: [],
            responseIds: [],
          };
        else
          option[op.id] = {
            optionText: op.optionText,
            response: [],
            responseIds: [],
          };
      });
      responseCount.push({
        quesId: ques.id,
        questionText: ques.questionText,
        options: option,
        type: ques.type,
      });
    }
  });

  response.forEach((res) => {
    let userResponse = res.response;
    Object.keys(userResponse).forEach((quesId) => {
      addResponseInQuestion(
        responseCount,
        quesId,
        userResponse[quesId],
        res._id
      );
    });
  });
  console.log("initial", responseCount);

  //format options
  let finalResponse = {};
  responseCount.forEach((item) => {
    let option = { optionText: [], optionValue: [], optionIds: [] };
    Object.keys(item.options).forEach((opsId) => {
      let ops = item.options[opsId];
      let arr = ops.response.length === 0 ? [0] : ops.response;
      option.optionValue = [...option.optionValue, ...arr];
      option.optionIds.push(opsId);
      option.optionText.push(ops.optionText);
      if (!formTypes[item.type].isMultiple) {
        option["valueCount"] = groupBy(option.optionValue);
      }
    });
    console.log(item);
    finalResponse[item.quesId] = { ...item, ...option };
  });

  return finalResponse;
};
export function groupBy(arr) {
  return arr.reduce((r, c) => ((r[c] = (r[c] || 0) + 1), r), {});
}
export const addExtraDataInArray = (json, maxLimit) => {
  let newArr = [];
  for (let index = 0; index <= maxLimit; index++) {
    if (json[index]) newArr.push({ key: index, value: json[index] });
    else newArr.push({ key: index, value: 0 });
  }
  let finalJson = {};
  newArr.forEach((item) => (finalJson[item.key] = item.value));
  return finalJson;
};
export const fetchMarks = (arrResponse) => {
  let arr = [];
  arrResponse.forEach((item) => {
    if (item.score) arr.push(item.score.marks);
  });
  return arr;
};
export const getStats = (arr) => {
  if (arr.length === 0) return { mean: 0, median: 0, range: [0, 0] };

  return { mean: mean(arr), median: median(arr), range: range(arr) };
};
const mean = (arr) => {
  let total = 0;
  for (let i = 0; i < arr.length; i++) {
    total += arr[i];
  }
  let res = new Number(total / arr.length);
  return res.toPrecision(2);
};
const median = (arr) => {
  const { length } = arr;

  arr.sort((a, b) => a - b);

  if (length % 2 === 0) {
    return (arr[length / 2 - 1] + arr[length / 2]) / 2;
  }

  return arr[(length - 1) / 2];
};
const range = (arr) => {
  arr.sort((a, b) => a - b);

  return [arr[0], arr[arr.length - 1]];
};
export const sumMarks = (json) => {
  let sum = 0;
  Object.keys(json).forEach((item) => (sum += json[item].marks));
  return sum;
};
function addResponseInQuestion(
  responseCount,
  quesId,
  responseText,
  responseId
) {
  let index = responseCount.findIndex((item) => item.quesId === quesId);
  try {
    // console.log("responseText", responseText, "index", index, "quesId", quesId);

    if (index !== -1) {
      let quesRef = responseCount[index];
      let isMultiple = formTypes[quesRef.type].multiple;

      let opRef;
      if (isMultiple) {
        responseText.split(",").forEach((resId) => {
          opRef = quesRef.options[resId];
          let value = (opRef.response.length === 0 ? 0 : opRef.response[0]) + 1;
          opRef.response = [value++];
          opRef.responseIds.push(responseId);
        });
      } else {
        opRef = quesRef.options[quesRef.type];
        opRef.response.push(responseText);
        opRef.responseIds.push(responseId);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const deleteArrayElement = (array, index) => {
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
};

export const getRandomColors = (n) => {
  var result = new Array(n),
    len = colors.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = colors[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};

export const groupResponses = (response, questions) => {
  let res = [];
  response.forEach((item) => {
    Object.keys(item.response).forEach((key) => {
      let optionId = item.response[key];
      let quesIndex = questions.findIndex((i) => i.id === key);
      let optionText;
      if (formTypes[questions[quesIndex].type].multiple) {
        let opList = [];
        optionId.split(",").forEach((divId) => {
          let optionIndex = questions[quesIndex].options.findIndex(
            (op) => op.id === divId
          );
          try {
            opList.push(
              questions[quesIndex]["options"][optionIndex].optionText
            );
          } catch (error) {
            opList.push("not found");
          }
        });
        optionText = opList.toString();
      }

      // console.log(
      //   quesIndex,
      //   optionText,
      //   optionId,
      //   formTypes[questions[quesIndex].type].multiple,
      //   questions[quesIndex].type
      // );
      res.push({
        quesId: key,
        optionId: optionId,
        resId: item._id,
        userId: item.userId,
        optionText: optionText,
      });
    });
  });

  var groubedByQuesId = groupByJson(res, "quesId");
  console.log(groubedByQuesId);
  var resFinal = {};

  Object.keys(groubedByQuesId).forEach((quesId) => {
    let quesIndex = questions.findIndex((i) => i.id === quesId);
    let resGrouped = groupByJson(groubedByQuesId[quesId], "optionId");
    resFinal[quesId] = {
      data: resGrouped,
      options: groupByJson(questions[quesIndex].options, "id"),
    };
  });
  return resFinal;
};

export const groupByJson = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

/**pass value for @selector in actual form. Eg for id= #id and for class .class*/
export const handleDownloadHtml = async (selector, removeSectionId) => {
  setTimeout(async () => {
    const element = document.querySelector(selector);
    let elementToHide;
    if (removeSectionId) elementToHide = element.querySelector(removeSectionId);
    if (!element) {
      console.log(selector + " not found");
      return;
    }
    if (elementToHide) elementToHide.style = "visibility:hidden";
    const canvas = await html2canvas(element);
    if (elementToHide) elementToHide.style = "visibility:visible";
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, "download.png", "image/png");
  }, 100);
};

export const createCSV = (data, name, labels, type) => {
  let dateJson;
  let dates;
  let count;
  if (groupByListFormType.includes(type)) {
    dateJson = groupBy(data);
    dates = Object.keys(dateJson);
    count = Object.values(dateJson);
  } else {
    dates = labels;
    count = data;
  }
  console.log(dates, count);

  let record = [];
  try {
    for (let index = 0; index < dates.length; index++) {
      const element = dates[index];
      const value = count[index];
      record.push({
        Question: index === 0 ? name : "",
        Value: element,
        Data: value,
      });
    }
    console.log(record);
    exportFromJSON({ data: record, fileName: name, exportType: "csv" });
  } catch (error) {
    console.log(error);
  }
};

export const createCSVForAllResponse = async (
  questionsList,
  responseList,
  isQuiz
) => {
  console.log("Downloading csv....", questionsList, responseList);

  let questionIdOrder = [];
  let data = [];

  //add Questions in the header
  questionsList.forEach((item) => {
    questionIdOrder.push(item.id);
  });
  //now add response one by one
  responseList.forEach((userResponse) => {
    let responseToRecord = {};
    //add user
    responseToRecord["user"] = userResponse.userId;

    questionIdOrder.forEach((quesId, index) => {
      let optionId = userResponse.response[quesId];
      if (!optionId) {
        responseToRecord[question.questionText] = "";
      } else {
        let question = questionsList.find((ques) => ques.id === quesId);
        //check if question have that optionId
        let option = question.options.find((op) => op.id === optionId);
        //check if type id in groupByListFormType
        if (groupByListFormType.includes(question.type))
          responseToRecord[question.questionText] = optionId;
        else {
          if (question.type === "multiSelection") {
            let ansStr = "";
            optionId.split(",").forEach((item) => {
              let tempOption = question.options.find((op) => op.id === item);
              if (tempOption) ansStr += tempOption.optionText + ",";
              else ansStr += "Option not found,";
            });
            ansStr =
              ansStr.lastIndexOf(",") === ansStr.length - 1
                ? ansStr.substring(0, ansStr.lastIndexOf(","))
                : ansStr;
            responseToRecord[question.questionText] = ansStr;
          } else if (option) {
            responseToRecord[question.questionText] = option.optionText;
          } else responseToRecord[question.questionText] = "";
        }
        if (isQuiz)
          if (userResponse.score) {
            responseToRecord["Point " + (index + 1)] =
              userResponse.score.stats[quesId].point +
              "\\" +
              userResponse.score.stats[quesId].total;
          } else responseToRecord["Point " + (index + 1)] = "";
      }
    });
    if (isQuiz) {
      if (userResponse.score) {
        responseToRecord["Score"] = userResponse.score.marks;
        responseToRecord["Total Score"] = userResponse.score.totalMarks;
        responseToRecord["Answer Release Date"] = dayjs(
          userResponse.score.scoreReleaseDate
        ).format("MMM DD YYYY HH:mm");
      } else {
        responseToRecord["Score"] = "Pending";
        responseToRecord["Answer Release Date"] = "-";
      }
    }
    responseToRecord["Response Date"] = dayjs(
      userResponse.timeStamp.toMillis()
    ).format("MMM DD YYYY HH:mm");
    data.push(responseToRecord);
  });
  console.log("Excel json", data);
  exportFromJSON({ data, fileName: "response", exportType: "csv" });
};
export const fetchNameFromUrl = (url) => {
  let temp = url.split("%2F");
  let name = temp[temp.length - 1].substring(
    0,
    temp[temp.length - 1].indexOf("?")
  );
  let finalName =
    name.substring(0, name.lastIndexOf("-")) +
    name.substring(name.lastIndexOf("."));
  return finalName.replaceAll("%20", " ");
};
export const isArraySame = (arr1, arr2) => {
  const set1 = new Set(arr1.toString().toLowerCase().split(","));
  const set2 = new Set(arr2.toString().toLowerCase().split(","));
  return (
    arr1.every((item) => set2.has(item)) && arr2.every((item) => set1.has(item))
  );
};
export const checkIfArrayHaveAnyElement = (arr1, text) => {
  return arr1.toString().toLowerCase().split(",").includes(text.toLowerCase());
};
export const checkIfTwoArrayHaveCommonElement = (arr1, arr2) => {
  let str = arr1.toString().toLowerCase();
  let isFound = false;
  arr2.forEach((item) => {
    if (str.includes(item)) isFound = true;
  });
  return isFound;
};
export const getLatestTime = (json) => {
  let latest = 0;
  Object.keys(json).forEach((item) => {
    if (latest < json[item].time) latest = json[item].time;
  });
  return latest;
};
export const calculateMarks = (
  responseData,
  showPoints,
  questions,
  defaultPoint
) => {
  console.log(
    "Marks calculation started",
    responseData,
    showPoints,
    questions,
    defaultPoint
  );
  let marks = 0;
  let totalMarks = 0;
  let stats = {};
  questions.forEach((question) => {
    let tempStats = {};
    try {
      let point = 0;
      let total = 0;
      try {
        point = skipMarks.includes(question.type)
          ? 0
          : showPoints[question.id]
          ? showPoints[question.id].marks
          : defaultPoint
          ? defaultPoint
          : 0;
        total = point;
      } catch (error) {}
      totalMarks += point;
      let response = responseData[question.id];
      if (response) {
        if (!showPoints[question.id] || !showPoints[question.id].answer)
          marks += point;
        else if (
          groupByListFormMarksOptional.includes(question.type) &&
          checkIfArrayHaveAnyElement(showPoints[question.id].answer, response)
        )
          marks += point;
        else if (
          isArraySame(showPoints[question.id].answer, response.split(","))
        )
          marks += point;
        else {
          console.log("no match");
          point = 0;
        }
      }
      tempStats["point"] = point;
      tempStats["response"] = response
        ? groupByListFormType.includes(question.type)
          ? [response]
          : response.split(",")
        : "";
      tempStats["total"] = total;
      stats[question.id] = tempStats;
    } catch (error) {
      console.error(error);
      console.log("Error occurred for", question);
    }
  });
  console.log("Marks is ", marks, " total", totalMarks, stats);
  return {
    marks,
    totalMarks,
    scoreReleaseDate: getLatestTime(showPoints),
    stats,
  };
};

export const downloadFile = (name, url) => {
  var link = document.createElement("a");
  name = name[name?.length - 1];
  link.setAttribute("download", name);
  link.href = url;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  link.remove();
};
