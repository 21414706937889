import React from "react";
import Icon from "@material-tailwind/react/Icon";
import { IconButton, TextField, useTheme } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import ErrorBoundary from "../../errors/ErrorBoundary";
export default function Dropdown({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  edit,
  removeOption,
  questionViewData,
}) {
  return (
    <ErrorBoundary>
      {edit && edit === "edit" ? (
        <EditView
          optionData={optionData}
          handleOptionValue={handleOptionValue}
          uploadImage={uploadImage}
          questionIndex={questionIndex}
          optionIndex={optionIndex}
          removeOption={removeOption}
        />
      ) : (
        <QuestionView {...questionViewData} />
      )}
    </ErrorBoundary>
  );
}

const EditView = ({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  removeOption,
}) => {
  const handleValue = (e) => {
    handleOptionValue(
      { ...optionData, optionText: e.target.value },
      questionIndex,
      optionIndex
    );
  };
  try {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "-12.5px",
          justifyContent: "space-between",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <div style={{ placeSelf: "center", marginRight: 12, marginLeft: 10 }}>
          <Icon
            name="remove"
            color="gray"
            className="place-self-center align-center"
          />
        </div>
        <TextField
          fullWidth={true}
          variant="outlined"
          placeholder="Option text"
          style={{ marginTop: "5px" }}
          value={optionData.optionText}
          onChange={handleValue}
        />
        <IconButton
          aria-label="delete"
          onClick={() => {
            removeOption(questionIndex, optionIndex);
          }}
        >
          <Close />
        </IconButton>
      </div>
    );
  } catch (error) {
    return null;
  }
};

const QuestionView = ({
  questionId,
  responseData,
  options,
  removeError,
  actionMethod,
}) => {
  const theme = useTheme();

  const handleCheckbox = () => {
    let value = document.getElementById(questionId).value;
    if (value === "Select option") value = "";
    removeError(questionId);
    actionMethod(questionId, value);
  };
  let value = responseData[questionId];
  console.log("dropdown value", value);
  return (
    <ErrorBoundary>
      <select
        id={questionId}
        disabled={actionMethod === null}
        value={value}
        className="w-40 border-2 font-semibold py-2 px-4 rounded inline-flex items-center"
        onChange={handleCheckbox}
        style={
          actionMethod === null
            ? {
                color: "rgb(0, 82, 204)",
                borderColor: "rgb(0, 82, 204)",
                marginTop: 10,
              }
            : null
        }
      >
        <option
          className="rounded-t  py-2 px-4 block whitespace-no-wrap"
          value={""}
        >
          Select option
        </option>
        {options &&
          options.map((option, index) => (
            <option
              // onClick={() => handleOptionClick(option.id)}
              key={option.id}
              className="rounded-t  py-2 px-4 block whitespace-no-wrap"
              value={option.id}
            >
              {option.optionText}
            </option>
          ))}
      </select>
    </ErrorBoundary>
  );
};
