import { Button, Grid, Paper, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import React from "react";
import {
  ERROR_CODE,
  FORM_START_DATE,
  RESPONSE_IMAGE,
  RESPONSE_MESSAGE,
  RESULT_SUMMERY,
} from "../../../files/Constants";
import { fileTypes } from "../../../files/Lists";
import { findObj } from "../../../files/util";
import FormFooter from "../Responding/FormFooter";
import CreateNew from "../../../components/CreateNew";
function FormErrors({ errorCode, formConfig, user, showAnswerView }) {
  console.log("error props", errorCode, formConfig);
  const [showModel, setShowModel] = React.useState(false);
  const openCreate = () => {
    setShowModel(true);
  };
  const getErrorMessage = () => {
    switch (errorCode) {
      case ERROR_CODE.FORM_ENDED:
        return (
          <Typography variant="subtitle1">
            The form is not currently accepting the responses
          </Typography>
        );
      case ERROR_CODE.FORM_EXPIRED:
        return <Typography variant="subtitle1">The form is expired</Typography>;
      case ERROR_CODE.FORM_NOT_START:
        return (
          <Typography variant="subtitle1">
            The form is not yet started. You can access the form from{" "}
            {dayjs(formConfig[FORM_START_DATE]).format("DD/MM/YYYY HH:mm")}
          </Typography>
        );
      case ERROR_CODE.FORM_HAVE_RESPONSE:
        return (
          <div className="flex flex-col">
            {formConfig[RESPONSE_IMAGE] && (
              <img className="w-72" src={formConfig[RESPONSE_IMAGE]} alt="" />
            )}
            <Typography variant="subtitle1">
              {formConfig[RESPONSE_MESSAGE] ??
                "We have received your response."}
            </Typography>
            {formConfig[RESULT_SUMMERY] && (
              <Button
                style={{ width: "fit-content" }}
                variant="contained"
                color="primary"
                onClick={showAnswerView}
              >
                View Response Summery
              </Button>
            )}
          </div>
        );
      case ERROR_CODE.FORM_SUBMITTED:
        return (
          <div className="flex flex-col">
            {formConfig[RESPONSE_IMAGE] && (
              <img className="w-72" src={formConfig[RESPONSE_IMAGE]} alt="" />
            )}
            <small>Form Submitted</small>

            <Typography variant="subtitle1">
              {formConfig[RESPONSE_MESSAGE] ??
                "We have received your response."}
            </Typography>
            {formConfig[RESULT_SUMMERY] && (
              <Button
                style={{ width: "fit-content" }}
                variant="contained"
                color="primary"
                onClick={showAnswerView}
              >
                View Response Summery
              </Button>
            )}
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <div>
        <br />
        <br />
        <Grid container direction="column" alignContent="center">
          {formConfig && formConfig["headerImage"] && (
            <Grid item lg={6} xs={12}>
              <Paper
                className="headerImage"
                style={{
                  borderRadius: 10,

                  width: "100%",
                  backgroundImage: `url(${formConfig["headerImage"]})`,
                }}
                elevation={2}
              ></Paper>
            </Grid>
          )}
          <br />
          <Grid
            item
            lg={6}
            xs={12}
            style={{
              borderTop:
                formConfig && formConfig["theme"]
                  ? `10px solid ${formConfig["theme"]}`
                  : "10px solid teal",
              borderRadius: 10,
            }}
          >
            <Paper
              style={{
                width: "100%",
              }}
              elevation={2}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginLeft: "15px",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: "sans-serif Roboto",
                    marginBottom: "15px",
                  }}
                >
                  {formConfig.name}
                </Typography>
                {getErrorMessage()}
              </div>
            </Paper>
          </Grid>
          <Grid item lg={6} xs={12} className="place-self-center">
            <br />

            <Button
              style={{ textTransform: "capitalize" }}
              variant="contained"
              color="primary"
              onClick={openCreate}
            >
              Create my own form
            </Button>
          </Grid>
          <Grid item lg={6} xs={12}>
            <br />
            <br />
            <FormFooter />
          </Grid>
        </Grid>
      </div>
      <CreateNew
        dbPath={fileTypes[findObj("form", fileTypes, "path")].dbPath}
        setShowModel={setShowModel}
        showModel={showModel}
        user={user}
      />
    </>
  );
}

export default FormErrors;
