import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";

export default function AnswerFeedback({
  handleClose,
  addFeedback,
  defaultValue,
}) {
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  return (
    <Dialog fullWidth open={true} onClose={handleClose}>
      <DialogTitle>Feedback</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => addFeedback(value)}
        >
          Done
        </Button>
        <Button color="primary" variant="text" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
