import React, { useState } from "react";

import { Divider, Grid } from "@material-ui/core";

import { Paper, Typography } from "@material-ui/core";

import formService from "../../../files/apis";

import Button from "@material-ui/core/Button";

import FormFooter from "./FormFooter";
import {
  ACCEPT_RESPONSE,
  ALLOW_EDIT,
  COLLECT_EMAIL,
  DISABLE_COPY_PASTE,
  ERROR_CODE,
  FORM_END_DATE,
  FORM_START_DATE,
  QUESTION_POINTS_DEFAULT,
  RELEASE_GRADE_NOW,
  SHOW_CORRECT_ANS,
  SHOW_POINT_VALUE,
  SHOW_PROGRESS,
  SHUFFLE_QUES_ORDER,
  SINGLE_QUESTION_AT_A_TIME,
  TYPE_QUIZ,
} from "../../../files/Constants";
import {
  calculateMarks,
  checkIfRequired,
  deleteArrayElement,
  findObj,
  getFormComponent,
  shuffleArray,
} from "../../../files/util";
import FormErrors from "../components/FormErrors";
import ErrorBoundary from "../../errors/ErrorBoundary";
import IndividualResponse from "../Response/IndividualResponse";
import { ShowAllQuestions } from "./ShowAllQuestions";
import { ShowOneQuestion } from "./ShowOneQuestion";
import FormResponseHeader from "./FormResponseHeader";
import ResponseProgress from "./ResponseProgress";

function UserView(props) {
  // console.clear();
  console.log(props);
  const [userId, setUserId] = React.useState("");
  const [showAnswerView, setShowAnswerView] = useState(false);
  const [formData, setFormData] = React.useState({});
  const [responseData, setResponseData] = React.useState({});
  const [showPoints, setShowPoints] = React.useState(null);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [configCheckDone, setConfigCheckDone] = React.useState(false);
  const [questions, setQuestions] = React.useState([]);
  const [error, setError] = React.useState(-1);
  const [formError, setFormError] = React.useState({});
  const [responseIfExist, setResponseIfExist] = useState(null);
  const [responseId, setResponseId] = React.useState(null);
  React.useEffect(() => {
    if (props.user) {
      setUserId(props.user);
    }
  }, []);

  const handleRadioChange = (questionId, optionId) => {
    console.log("radio clicked ", optionId, questionId);
    responseData[questionId] = optionId;
    console.log("Response data", responseData);
  };

  React.useEffect(() => {
    var formId = props.formId;
    console.log(formId, "form props", props.formProps);
    if (props.formProps) initForm(props.formProps);
    else
      formService.getForm(formId).then(
        (data) => {
          initForm(data);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(resMessage);
        }
      );
  }, [props.formId, props.formProps]);
  function initForm(data) {
    try {
      if (data.createdBy) {
        console.log(data);
        setFormData(data);
        setQuestions(data.questions);
        //get user response if any
        formService.getUserResponse(props.formId, props.user).then(
          (res) => {
            // if (res) {
            //   {
            //     initConfigurationCheck(res);
            //   }
            // } else initInitialResponse();
            initConfigurationCheck(res);
            console.log("response if exist", res);
            setResponseIfExist(res);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }
  function initConfigurationCheck(response) {
    let configJson = props.formProps.configuration;
    console.log("config", configJson, formData, response);
    if (!configJson[ACCEPT_RESPONSE]) {
      setError(ERROR_CODE.FORM_EXPIRED);
      return;
    }
    if (configJson[SHOW_POINT_VALUE]) {
      setShowPoints(props.answerData);
    }
    if (
      configJson[FORM_START_DATE] &&
      new Date(configJson[FORM_START_DATE]).getTime() >= new Date().getTime()
    ) {
      setError(ERROR_CODE.FORM_NOT_START);
      return;
    }
    if (
      configJson[FORM_END_DATE] &&
      new Date(configJson[FORM_END_DATE]).getTime() <= new Date().getTime()
    ) {
      setError(ERROR_CODE.FORM_EXPIRED);
      return;
    }
    if (response) {
      if (configJson[ALLOW_EDIT]) {
        setResponseId(response._id);
        setResponseData(response.response);
      } else setError(ERROR_CODE.FORM_HAVE_RESPONSE);
    } else {
      initInitialResponse();
    }
    if (configJson[SHUFFLE_QUES_ORDER])
      setQuestions(shuffleArray(props.formProps.questions));
    if (configJson[COLLECT_EMAIL]) {
      setQuestions([
        {
          id: COLLECT_EMAIL,
          questionText: "Add email id",
          options: [
            { optionText: "Email id", id: COLLECT_EMAIL, allowed: "email" },
          ],
          type: "text",
          open: true,
          hidden: true,
          isDefault: true,
        },
        ...props.formProps.questions,
      ]);
      // let index = findObj(COLLECT_EMAIL, props.formProps.questions, "id");
      // if (index != -1) {
      // let ele = props.formProps.questions[index];
      // let questions = [...props.formProps.questions];
      // questions = deleteArrayElement(questions, index);
      // questions.unshift(ele);

      // }
    }

    if (configJson[DISABLE_COPY_PASTE]) {
      document.oncontextmenu = new Function("return false");
      document.body.oncut = new Function("return false");
      document.body.oncopy = new Function("return false");
      document.body.onpaste = new Function("return false");
      var e = document.getElementsByTagName("body")[0];
      if (e) {
        e.setAttribute("class", "unselectable");
      }
    }

    setConfigCheckDone(true);
  }
  function initInitialResponse() {
    var initialResponse = {};
    questions.forEach((option) => {
      initialResponse[option.id] = "";
    });
    setResponseData(initialResponse);
  }
  function submitResponse() {
    console.log("submitting response", responseData);
    let status = checkIfRequired(responseData, questions);
    if (status) {
      setFormError(status);
      console.log("Errors found", status);
      return;
    }
    let id;
    if (formData.configuration[COLLECT_EMAIL]) {
      id = responseData[COLLECT_EMAIL];
      delete responseData[COLLECT_EMAIL];
    } else id = userId;
    var submissionData = {
      formId: formData._id,
      userId: id,
      response: responseData,
    };
    console.log(submissionData);
    if (
      showPoints &&
      formData.configuration[COLLECT_EMAIL] === RELEASE_GRADE_NOW
    ) {
      submissionData["score"] = calculateMarks(
        responseData,
        showPoints,
        formData.questions,
        formData.configuration[QUESTION_POINTS_DEFAULT]
      );
    }
    // return;
    formService
      .submitResponse(submissionData, responseId, setResponseIfExist)
      .then(
        (data2) => {
          setError(ERROR_CODE.FORM_SUBMITTED);
          console.log(data2);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(resMessage);
        }
      );
  }

  function reloadForAnotherResponse() {
    window.location.reload(true);
  }
  function getUiType(type, questionId, options) {
    return getFormComponent(type, "question", null, {
      questionId: questionId,
      options: options,
      actionMethod: handleRadioChange,
      responseData: responseData,
      error: formError[questionId],
      removeError: (questionId) => {
        let errors = { ...formError };
        delete errors[questionId];
        setFormError(errors);
        console.log("removed form error", formError);
      },
    });
  }
  function clearForm() {
    var res = {};
    // Object.keys(responseData).forEach((responseKey) => {
    //   res[responseKey] = "";
    // });
    console.log("new response", res);
    setResponseData(res);
  }
  console.log("responseData", responseData);
  return (
    <div style={{ minHeight: "100vh" }}>
      {error === -1 ? (
        <>
          {configCheckDone && (
            <div className={props.forceSmall ? "" : "pt-10"}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                {!isSubmitted ? (
                  <>
                    {!props.formProps.configuration[
                      SINGLE_QUESTION_AT_A_TIME
                    ] ? (
                      <Grid
                        item
                        xs={props.forceSmall ? 12 : 12}
                        sm={props.forceSmall ? 12 : 6}
                        lg={props.forceSmall ? 12 : 6}
                        style={{ width: "100%" }}
                      >
                        <ShowAllQuestions
                          formError={formError}
                          formData={formData}
                          userId={userId}
                          userImage={props.userimage}
                          getUiType={getUiType}
                          questions={questions}
                          defaultPoint={
                            props.formProps.configuration[
                              QUESTION_POINTS_DEFAULT
                            ]
                          }
                          showPoints={showPoints}
                          submitResponse={submitResponse}
                        />
                      </Grid>
                    ) : (
                      <ShowOneQuestion
                        formData={formData}
                        userId={userId}
                        userImage={props.userimage}
                        formError={formError}
                        getUiType={getUiType}
                        isSmall={props.forceSmall}
                        responseData={responseData}
                        questions={questions}
                        defaultPoint={
                          props.formProps.configuration[QUESTION_POINTS_DEFAULT]
                        }
                        showPoints={showPoints}
                        submitResponse={submitResponse}
                      />
                    )}
                    {props.formProps.configuration[SHOW_PROGRESS] && (
                      <ResponseProgress
                        forceSmall={props.forceSmall}
                        questions={questions}
                        responseData={responseData}
                      />
                    )}
                  </>
                ) : (
                  <Grid
                    item
                    xs={props.forceSmall ? 12 : 12}
                    sm={props.forceSmall ? 12 : 6}
                    lg={props.forceSmall ? 12 : 6}
                    style={{ width: "100%" }}
                  >
                    <FormResponseHeader
                      formData={formData}
                      userId={userId}
                      userImage={props.userimage}
                    />
                    <br />
                    <Paper variant="outlined">
                      <Typography variant="body1">Form submitted</Typography>
                      <Typography variant="body2">
                        Thanks for submitting form
                      </Typography>

                      <Button onClick={reloadForAnotherResponse}>
                        Submit another response
                      </Button>
                    </Paper>
                  </Grid>
                )}
                {/* </Grid> */}
              </Grid>

              <FormFooter />
            </div>
          )}
        </>
      ) : (
        <>
          {showAnswerView ? (
            <ErrorBoundary defaultMessage="Error in UI">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={props.forceSmall ? 12 : 12}
                  sm={props.forceSmall ? 12 : 6}
                  lg={props.forceSmall ? 12 : 6}
                  style={{ width: "100%" }}
                >
                  <br />
                  <Grid
                    style={{
                      borderTop:
                        formData.configuration &&
                        formData.configuration["theme"]
                          ? `10px solid ${formData.configuration["theme"]}`
                          : "10px solid teal",
                      borderRadius: 10,
                    }}
                    item
                  >
                    <Paper
                      style={{
                        width: "100%",
                        backgroundSize: "cover",
                        backgroundAttachment: "local",

                        backgroundImage:
                          formData.configuration &&
                          formData.configuration["headerImage"]
                            ? `linear-gradient(270deg,${formData.configuration["bgColor"]}00 , ${formData.configuration["bgColor"]} 80%),
                            url(${formData.configuration["headerImage"]}`
                            : "",
                      }}
                      elevation={2}
                    >
                      {formData.configuration &&
                        formData.configuration["headerImage"] && (
                          <Paper
                            variant="outlined"
                            className="header-image-section"
                            style={{ opacity: 0 }}
                          ></Paper>
                        )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          marginLeft: "15px",
                          paddingTop: "20px",
                          paddingBottom: "20px",
                        }}
                      >
                        <Typography
                          variant="h4"
                          style={{
                            fontFamily: "sans-serif Roboto",
                            marginBottom: "15px",
                          }}
                        >
                          {formData.name}
                        </Typography>
                        <Typography variant="subtitle1">
                          {formData.description}
                        </Typography>
                      </div>
                      <Divider style={{ width: "100%" }} />
                      <div style={{ padding: "15px" }}>
                        <div className="flex items-center">
                          {props.userimage && (
                            <img
                              name="apps"
                              alt=""
                              loading="lazy"
                              className="cursor-pointer h-8 w-8 rounded-full mr-2"
                              src={props.userimage}
                              size="2xl"
                            />
                          )}
                          <p>
                            <b>{userId}</b>
                          </p>
                        </div>
                      </div>
                    </Paper>
                  </Grid>

                  <br />
                  <br />
                  <IndividualResponse
                    responses={[responseIfExist]}
                    questions={props.formProps.questions}
                    isQuiz={props.formProps.configuration[TYPE_QUIZ]}
                    answerData={props.answerData}
                    isUserView={true}
                    notShowCorrectAnswer={
                      !Boolean(props.formProps.configuration[SHOW_CORRECT_ANS])
                    }
                  />
                  <br />
                </Grid>
              </Grid>
            </ErrorBoundary>
          ) : (
            <FormErrors
              user={userId}
              showAnswerView={() => setShowAnswerView(!showAnswerView)}
              errorCode={error}
              formConfig={{ name: formData.name, ...formData.configuration }}
            />
          )}
        </>
      )}
    </div>
  );
}

export default UserView;
