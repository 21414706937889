import { Button, IconButton, TextField } from "@material-ui/core";
import React, { useState } from "react";
import Icon from "@material-tailwind/react/Icon";
import Close from "@material-ui/icons/Close";
import AttachmentTypes from "./forms/validations/AttachmentTypes";

import FileUploader from "./FileUploader";
import ImageUplaodModel from "./forms/Form/ImageUplaodModel";
function FormFileUploader({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  edit,
  removeOption,
  questionViewData,
}) {
  console.log("formfileuploader", questionViewData);

  return (
    <React.Fragment>
      {edit && edit === "edit" ? (
        <EditView {...questionViewData} />
      ) : (
        <QuestionView {...questionViewData} />
      )}
    </React.Fragment>
  );
}

const EditView = ({
  setOpenPopup,
  actionMethod,
  file,
  removeImage,
  handleFileAddClick,
  buttonProps,
  options,
  bgColor,
}) => {
  const [openSelector, setOpenSelector] = useState(false);
  const handleImageSelection = (link, context) => {
    console.log("file received", link);
    actionMethod(options[0].id, link);
  };
  return (
    <>
      {/* {file ? ( */}
      <div className="flex">
        {file && (
          <img
            src={file}
            alt=""
            style={{
              width: "66px",
              border: "1px solid grey",
              background: "gray",
              padding: 1,
            }}
          />
        )}
        <div style={{ marginTop: file ? -10 : 0 }}>
          {file && (
            <>
              <Button
                variant="text"
                color="primary"
                style={{ textTransform: "capitalize" }}
                onClick={(e) => removeImage(options[0].id)}
                startIcon={<Close />}
              >
                {file.fileName ? file.fileName : "Remove file"}
              </Button>
              <br />
            </>
          )}
          <Button
            variant="text"
            color="primary"
            style={{ textTransform: "capitalize" }}
            onClick={() => setOpenSelector(true)}
            startIcon={
              <Icon name={buttonProps ? buttonProps.iconName : "file_upload"} />
            }
          >
            {buttonProps ? buttonProps.btText : "Upload"}
          </Button>
        </div>
      </div>
      {/* ) } */}

      <ImageUplaodModel
        bgColor={bgColor}
        contextData={null}
        updateImageLink={handleImageSelection}
        handleImagePopOpen={openSelector}
        isBigImage={true}
        handleImagePopClose={() => setOpenSelector(false)}
      />
    </>
  );
};

const QuestionView = ({
  questionId,
  responseData,
  options,
  actionMethod,
  buttonProps,
  removeError,
  customUpdate,
}) => {
  const [file, setFile] = React.useState([]);
  const [fileDialogProps, setFileDialogProps] = React.useState(null);
  const [forceUpdate, setForceUpdate] = React.useState(false);
  const [responseDataState, setResponseDataState] = React.useState(
    !responseData ? {} : responseData
  );
  const removeFile = (index) => {
    let list = responseData[questionId].split(",");
    list[index] = null;
    actionMethod(questionId, list.toString());
    let tempFile = file;
    tempFile[index] = null;
    setFile(tempFile);
    setForceUpdate(!forceUpdate);
    if (customUpdate) {
      let newResponseState = responseData;
      newResponseState[questionId] = list.toString();
      setResponseDataState(newResponseState);
      console.log("new response state", responseDataState);
    }
    if (removeError) removeError(questionId);
  };
  const addFile = ({ fileName, fileUrl, index }) => {
    let list = responseData[questionId].split(",");
    list[index] = fileUrl;
    actionMethod(questionId, list.toString());
    let tempFile = file;
    tempFile[index] = { fileName, fileUrl };
    setFile(tempFile);
    if (removeError) removeError(questionId);
  };
  const handleFileAddClick = (index) => {
    setFileDialogProps({
      file: file[index],
      allowedFiles: options[index].allowed,
      setFileFn: addFile,
      index,
      setOpen: setFileDialogProps,
      removeFile,
    });
  };
  return (
    <>
      {options.map((option, index) => (
        <div key={option.id}>
          {file[index] || responseData[option.id] ? (
            <Button
              variant="outlined"
              color="primary"
              style={{ textTransform: "capitalize" }}
              onClick={(e) => removeFile(index)}
              startIcon={<Close />}
            >
              {file[index] && file[index].fileName
                ? file[index].fileName
                : "Remove file"}
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              style={{ textTransform: "capitalize" }}
              onClick={(e) => handleFileAddClick(index)}
              startIcon={
                <Icon
                  name={buttonProps ? buttonProps.iconName : "file_upload"}
                />
              }
            >
              {buttonProps ? buttonProps.btText : "Upload"}
            </Button>
          )}
          <br />
          <small>Allowed types {option.allowed}</small>
        </div>
      ))}
      {fileDialogProps && <FileUploader {...fileDialogProps} />}
    </>
  );
};

export default FormFileUploader;
