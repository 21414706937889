import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import CloseIcon from "@material-ui/icons/Close";
import Radio from "@material-ui/core/Radio";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import AccordionActions from "@material-ui/core/AccordionActions";
import Divider from "@material-ui/core/Divider";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import SaveIcon from "@material-ui/icons/Save";
import { Checkbox, Grid, RadioGroup } from "@material-ui/core";
import Icon from "@material-tailwind/react/Icon";
import React, { useState } from "react";
import {
  formTypes,
  groupByListFormType,
  questionMenu,
  skipMarks,
  skipRequired,
} from "../../../files/Lists";
import { COLLECT_EMAIL, SHUFFLE_OPTIONS } from "../../../files/Constants";
import {
  deleteArrayElement,
  isArraySame,
  pickColor,
} from "../../../files/util";
import MenuBar from "../../MenuBar";
import {
  AssessmentOutlined,
  Assignment,
  AssignmentTurnedIn,
  AssignmentTurnedInOutlined,
  Check,
  Clear,
  Close,
} from "@material-ui/icons";
import InfiniteTextField from "../../InfiniteTextField";
import AnswerFeedback from "../AnswerFeedback";

export const EditQuestionView = ({
  index,
  ques,
  handleQuestionValue,
  uploadImage,
  checkImageHereOrNotForQuestion,
  updateImageLink,
  checkImageHereOrNotForOption,
  getOptionTypeUi,
  removeOption,
  addOption,
}) => {
  console.log("ques", ques);
  return (
    <AccordionDetails>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginLeft: "15px",
          marginTop: "-15px",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Typography style={{ marginTop: "20px" }}>{index + 1}.</Typography>
          <TextField
            fullWidth={true}
            variant="outlined"
            placeholder="Question Text"
            style={{ marginBottom: "18px" }}
            minRows={1}
            maxRows={20}
            multiline={true}
            value={ques.questionText}
            onChange={(e) => {
              handleQuestionValue(e.target.value, index);
            }}
          />
          <IconButton
            aria-label="upload image"
            style={{ width: 30 }}
            onClick={() => {
              uploadImage(index, null);
            }}
          >
            <CropOriginalIcon />
          </IconButton>
        </div>

        <div>
          {checkImageHereOrNotForQuestion(ques.questionImage) ? (
            <div>
              <div
                style={{
                  width: "150px",
                  display: "flex",
                  alignItems: "flex-start",
                  paddingLeft: "20px",
                }}
              >
                <img src={ques.questionImage} width="150px" height="auto" />
                <IconButton
                  style={{
                    marginLeft: "-15px",
                    marginTop: "-15px",
                    zIndex: 1,
                    backgroundColor: "lightgrey",
                    color: "grey",
                  }}
                  size="small"
                  onClick={() => {
                    updateImageLink("", {
                      question: index,
                      option: null,
                    });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <QuestionUI
          checkImageHereOrNotForOption={checkImageHereOrNotForOption}
          getOptionTypeUi={getOptionTypeUi}
          index={index}
          ques={ques}
          removeOption={removeOption}
          updateImageLink={updateImageLink}
        />
        {formTypes[ques.type]["multiple"] && (
          <div>
            <FormControlLabel
              disabled
              control={<Radio />}
              label={
                <Button
                  size="small"
                  onClick={() => {
                    addOption(index);
                  }}
                  style={{
                    textTransform: "none",
                    marginLeft: "-5px",
                  }}
                >
                  Add Option
                </Button>
              }
            />
          </div>
        )}

        <br></br>
        <br></br>
      </div>
    </AccordionDetails>
  );
};

export const QuestionDemoView = ({ questions, index, ques }) => {
  return (
    <AccordionSummary
      aria-controls="panel1a-content"
      id="panel1a-header"
      elevation={1}
      style={{ width: "100%" }}
    >
      {!questions[index].open ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: "3px",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <Typography variant="subtitle1" style={{ marginLeft: "0px" }}>
            {/* {index + 1}.  */}
            {ques.questionText}
          </Typography>

          {ques.questionImage !== "" ? (
            <div>
              <img src={ques.questionImage} width="400px" height="auto" />
            </div>
          ) : (
            ""
          )}

          {ques.options.map((op, j) => (
            <div key={j}>
              <div style={{ display: "flex" }}>
                {ques.type === "passage" ? (
                  <p>{ques.options[j].optionText}</p>
                ) : (
                  <FormControlLabel
                    disabled
                    control={<Radio style={{ marginRight: "3px" }} />}
                    label={
                      <Typography style={{ color: "#555555" }}>
                        {ques.options[j].optionText}
                      </Typography>
                    }
                  />
                )}
              </div>

              <div>
                {op.optionImage !== "" ? (
                  <img src={op.optionImage} width="160px" height="auto" />
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </AccordionSummary>
  );
};

export const FormActions = ({ addMoreQuestionField, saveQuestions }) => {
  const addMoreClicked = (type) => {
    // setShowFormAction();
    addMoreQuestionField(type);
  };
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={saveQuestions}
        style={{ margin: "15px", marginBottom: "7em" }}
        endIcon={<SaveIcon />}
      >
        Save Questions{" "}
      </Button>
      <Paper
        className="type-selector flex flex-row bottom-0 left-0 right-0 sm:left-auto overflow-x-auto sm:right-0 fixed sm:flex-col sm:bottom-9 "
        variant="outlined"
      >
        <div className="bg-green-300 text-center hidden  sm:visible ">
          <Icon color="white" name="add" size="2xl" />
        </div>
        {Object.keys(formTypes).map((form, index) => {
          let type = formTypes[form];
          return (
            <Tooltip key={index} placement="left" arrow title={type.name}>
              <IconButton
                key={index}
                variant="contained"
                onClick={() => addMoreClicked(form)}
              >
                <Icon color="black" name={type.icon} size="2xl" />
              </IconButton>
            </Tooltip>
          );
        })}
      </Paper>
    </React.Fragment>
  );
};

export const FormHeader = ({ formData }) => {
  const [editVisible, setEditVisible] = React.useState(false);
  const [name, setName] = React.useState(formData.name);
  const [description, setDescription] = React.useState(formData.description);
  const handleValue = (type, text) => {
    formData[type] = text;
    if (type === "name") setName(text);
    else setDescription(text);
  };
  return (
    <Grid
      style={{
        borderTop:
          formData.configuration && formData.configuration["theme"]
            ? `10px solid ${formData.configuration["theme"]}`
            : "10px solid teal",
        borderRadius: 10,
      }}
    >
      <div>
        <Paper
          id="form-text-header"
          style={{
            width: "100%",
            backgroundSize: "cover",
            backgroundAttachment: "local",
            backgroundImage:
              formData.configuration && formData.configuration["headerImage"]
                ? `linear-gradient(270deg,${formData.configuration["bgColor"]}00 , ${formData.configuration["bgColor"]} 98%),
                url(${formData.configuration["headerImage"]}`
                : "",
          }}
          className="bg-fixed bg-no-repeat bg-cover"
          elevation={2}
        >
          {formData.configuration && formData.configuration["headerImage"] && (
            <Paper
              variant="outlined"
              className="header-image-section"
              style={{ opacity: 0 }}
            ></Paper>
          )}
          {!editVisible ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginLeft: "15px",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="flex justify-between align-top w-full">
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: "sans-serif Roboto",
                    marginBottom: 15,
                  }}
                >
                  {formData.name}
                </Typography>
                <IconButton
                  style={{
                    background: "#9b9b9beb",
                    marginRight: 14,
                  }}
                  className="w-8 h-8 bg-blue-300"
                  onClick={() => setEditVisible(true)}
                >
                  <Icon name="edit" />
                </IconButton>
              </div>
              <Typography variant="subtitle1">
                {formData.description}
              </Typography>
              {formData.configuration &&
                formData.configuration[[COLLECT_EMAIL]] && (
                  <small
                    className="p-3 mr-4"
                    style={{
                      background: formData.configuration["bgColor"] ?? "#eee",
                    }}
                  >
                    This form will allow the users to enter any custom emails.
                    The authenticity of those emails will not be verified. If
                    want to collect email a user using to access this form,
                    please click hear to disable the settings{" "}
                    <a href="">Collect Email</a>
                  </small>
                )}
            </div>
          ) : (
            <div className="p-5">
              <TextField
                fullWidth
                onChange={(e) => handleValue("name", e.target.value)}
                variant="outlined"
                style={{ height: "5em" }}
                value={name}
                label="Title"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                fullWidth
                onChange={(e) => handleValue("description", e.target.value)}
                variant="outlined"
                multiline
                maxRows={3}
                minRows={3}
                value={description}
                label="Description"
                style={{ height: "7em" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <br />
              <Button
                onClick={() => setEditVisible(false)}
                variant="contained"
                color="primary"
              >
                Done
              </Button>
            </div>
          )}
        </Paper>
      </div>
    </Grid>
  );
};

export const AnswerView = ({
  questions,
  index,
  ques,
  isEdit,
  questionAnswer,
  setQuestionAnswer,
  selectedAnswer,
  showAnswer,
}) => {
  return (
    <>
      {isEdit ? (
        <QuestionAnswerView
          ques={ques}
          questionAnswer={questionAnswer}
          setQuestionAnswer={setQuestionAnswer}
          selectedAnswer={selectedAnswer}
          showAnswer={showAnswer}
        />
      ) : (
        <UserAnswerView
          ques={ques}
          questionAnswer={questionAnswer}
          setQuestionAnswer={setQuestionAnswer}
          selectedAnswer={selectedAnswer}
          showAnswer={showAnswer}
        />
      )}
    </>
  );
};
export const QuestionAnswerView = ({
  ques,
  questionAnswer,
  setQuestionAnswer,
}) => {
  console.log("questionAnswer", questionAnswer);
  const [marks, setMarks] = useState(
    questionAnswer && questionAnswer.marks ? questionAnswer.marks : 0
  );
  const [showFeedback, setShowFeedback] = useState(false);
  const handleMarksChange = (e) => {
    let val = e.target.value;
    if (val >= 0) {
      setMarks(val);
      setQuestionAnswer(null, val);
    }
  };

  const isSelectedOption = (id) =>
    questionAnswer &&
    questionAnswer.answer &&
    questionAnswer.answer.includes(id);
  const handleSelectedOptionClick = (opId) => {
    try {
      setQuestionAnswer(opId, -1);
    } catch (error) {}
  };
  const initAnswer = () => {
    setQuestionAnswer("Correct Answer", -1);
    questionAnswer.answer = ["Correct Answer"];
    return questionAnswer.answer;
  };
  const addFeedback = (value) => {
    setQuestionAnswer(null, -1, value);
    setShowFeedback(false);
  };
  const feedbackView = (
    <>
      <Button
        style={{ textTransform: "capitalize" }}
        color="primary"
        variant="text"
        onClick={() => setShowFeedback(true)}
        startIcon={<Assignment />}
      >
        {questionAnswer.feedback ? "Update" : "Add"} answer feedback
      </Button>
      {showFeedback && (
        <AnswerFeedback
          defaultValue={questionAnswer.feedback}
          handleClose={() => setShowFeedback(false)}
          addFeedback={addFeedback}
        />
      )}
    </>
  );
  return (
    <Paper
      aria-controls="panel1a-content"
      id="panel1a-header"
      variant="outlined"
      elevation={1}
      style={{ width: "100%", padding: 10, marginTop: -64, cursor: "default" }}
    >
      <div
        className="flex p-5"
        style={{
          borderBottom: "1px solid #dadce0",
          color: "#202124",
          marginLeft: "-10px",
          marginRight: "-10px",
        }}
      >
        <AssignmentTurnedInOutlined htmlColor="#5f6368" />
        <p style={{ fontSize: 18, marginLeft: "5px" }}>
          Choose correct answer:
        </p>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginLeft: "3px",
          paddingTop: "15px",
          paddingBottom: "15px",
          width: "100%",
        }}
      >
        <div className="flex justify-between w-full">
          <Typography variant="subtitle1" style={{ marginLeft: "0px" }}>
            {ques.questionText}
          </Typography>
          <div>
            <TextField
              id="standard-basic"
              type="number"
              value={marks}
              onChange={handleMarksChange}
              style={{ width: 60, textAlignLast: "center" }}
              variant="standard"
            />
            <span> points</span>
          </div>
        </div>

        {ques.questionImage !== "" ? (
          <div>
            <img src={ques.questionImage} width="400px" height="auto" />
          </div>
        ) : (
          ""
        )}

        {ques.options.map((op, j) => (
          <div className="w-full" key={j + op.id}>
            {groupByListFormType.includes(ques.type) ? (
              <>
                <p key={j + op.id}>{ques.options[j].optionText}</p>
                <InfiniteTextField
                  type={ques.type}
                  fields={
                    questionAnswer.answer ? questionAnswer.answer : initAnswer()
                  }
                />
              </>
            ) : (
              <div
                className={`flex justify-between answer-item ${
                  isSelectedOption(op.id) ? "answer-correct-item" : ""
                }`}
                onClick={() => handleSelectedOptionClick(op.id)}
              >
                <span>
                  {j + 1}. <span>{op.optionText}</span>
                </span>
                {isSelectedOption(op.id) && <Check htmlColor="green" />}
              </div>
            )}

            {op.optionImage && (
              <img src={op.optionImage} width="160px" height="auto" />
            )}
          </div>
        ))}

        <br />
        {questionAnswer.feedback ? (
          <Paper
            variant="outlined"
            style={{
              borderRadius: 11,
              borderStyle: "dashed",
              padding: 8,
              width: "100%",
            }}
          >
            <Typography variant="body2">{questionAnswer.feedback}</Typography>
            {feedbackView}
          </Paper>
        ) : (
          feedbackView
        )}
      </div>
    </Paper>
  );
};
export const UserAnswerView = ({
  ques,
  questionAnswer,
  selectedAnswer,
  showAnswer,
  responseStats,
}) => {
  console.log(
    "ques",
    ques,
    "questionAnswer",
    questionAnswer,
    "selectedAnswer",
    selectedAnswer,
    "showAnswer",
    showAnswer,
    "Stats",
    responseStats
  );

  const isOptionCorrectAnswer = (id) =>
    // selectedAnswer.includes(id) &&
    questionAnswer.answer.includes(id);

  // const isAnswerCorrect = isArraySame(selectedAnswer, questionAnswer.answer);
  const isWrongAnswer = (id) =>
    selectedAnswer.includes(id) && !questionAnswer.answer.includes(id);
  const isAnswerCorrect =
    responseStats[ques.id].point === responseStats[ques.id].total;
  const isOptionCorrectAndSelected = (opId) =>
    questionAnswer.answer.includes(opId) && selectedAnswer.includes(opId);
  return (
    <Paper
      aria-controls="panel1a-content"
      id="panel1a-header"
      variant="outlined"
      elevation={1}
      style={{ width: "100%", padding: 10, marginTop: -64, cursor: "default" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginLeft: "3px",
          paddingTop: "15px",
          paddingBottom: "15px",
          width: "100%",
        }}
      >
        <div className="flex  w-full">
          {isAnswerCorrect ? (
            <Check htmlColor="green" />
          ) : (
            <Close htmlColor="red" />
          )}
          <Typography
            variant="subtitle1"
            style={{
              flex: 1,
              marginLeft: "0px",
              color: isAnswerCorrect ? "green" : "red",
            }}
          >
            {ques.questionText}
          </Typography>
          <div>
            <span
              style={{
                width: 60,
                textAlignLast: "center",
                border: "1px solid gray",
                borderRadius: 5,
                padding: 9,
              }}
            >
              <b>{isAnswerCorrect ? responseStats[ques.id].point : 0}/</b>
              {responseStats[ques.id].total}
            </span>
            <span> points</span>
          </div>
        </div>

        {ques.questionImage !== "" ? (
          <div>
            <img src={ques.questionImage} width="400px" height="auto" />
          </div>
        ) : (
          ""
        )}

        {ques.options.map((op, j) => (
          <div key={op.id} className="w-full">
            {groupByListFormType.includes(ques.type) ? (
              <>
                <p key={j + op.id}>{ques.options[j].optionText}</p>
                <p
                  className={`answer-item ${
                    isAnswerCorrect
                      ? "answer-correct-item"
                      : "answer-wrong-item"
                  }`}
                >
                  {selectedAnswer.toString()}
                </p>
                {!isAnswerCorrect && showAnswer && (
                  <>
                    {" "}
                    <Typography color="primary">Answer Key</Typography>
                    {questionAnswer.answer.map((item) => (
                      <div
                        key={item}
                        className="answer-item answer-correct-item"
                      >
                        {item}
                      </div>
                    ))}
                  </>
                )}
              </>
            ) : (
              <div key={j + op.id}>
                <div
                  key={j + op.id}
                  className={`flex justify-between answer-item ${
                    isWrongAnswer(op.id) ? "answer-wrong-item" : ""
                  } ${
                    (showAnswer && isOptionCorrectAnswer(op.id)) ||
                    isOptionCorrectAndSelected(op.id)
                      ? "answer-correct-item"
                      : ""
                  }`}
                >
                  <span>
                    {j + 1}. <span>{op.optionText}</span>
                  </span>
                  {showAnswer &&
                    isAnswerCorrect &&
                    isOptionCorrectAndSelected(op.id) && (
                      <Check htmlColor="green" />
                    )}
                  {isWrongAnswer(op.id) && <Clear htmlColor="red" />}
                </div>
              </div>
            )}

            {op.optionImage && (
              <img src={op.optionImage} width="160px" height="auto" />
            )}
            {questionAnswer.feedback && !isAnswerCorrect && (
              <div>
                <Divider />
                <small>Feedback</small>
                <p>{questionAnswer.feedback}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </Paper>
  );
};

export const QuestionUI = ({
  getOptionTypeUi,
  index,
  ques,
  removeOption,
  checkImageHereOrNotForOption,
  updateImageLink,
}) => {
  return (
    <div style={{ width: "100%" }}>
      {ques.options.map((op, j) => (
        <div key={j}>
          {getOptionTypeUi(ques.options[j], ques.type, index, j, ques.id)}

          <div>
            {checkImageHereOrNotForOption(op.optionImage) ? (
              <div>
                <div
                  style={{
                    width: "150px",
                    display: "flex",
                    alignItems: "flex-start",
                    paddingLeft: "20px",
                  }}
                >
                  <img src={op.optionImage} width="90px" height="auto" />

                  <IconButton
                    style={{
                      marginLeft: "-15px",
                      marginTop: "-15px",
                      zIndex: 1,
                      backgroundColor: "lightgrey",
                      color: "grey",
                    }}
                    size="small"
                    onClick={() => {
                      updateImageLink("", {
                        question: index,
                        option: j,
                      });
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <br></br>
                <br></br>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export const QuestionActions = ({
  index,
  copyQuestion,
  showAsQuestion,
  deleteQuestion,
  setRequiredField,
  ques,
  isQuiz,
  answerData,
  showAnswerView,
  width,
  shuffleQuestionOption,
  isAnswerOpened,
  answerMarks,
}) => {
  return (
    <>
      {!isAnswerOpened && !skipRequired.includes(ques.type) && (
        <>
          <Divider />
          <div className="flex content-around p-2">
            <Typography
              variant="body2"
              style={{ color: "grey", maxWidth: "80%" }}
              className="pr-10"
            >
              Required field will force the user to enter/select the information
              requested
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={ques.required}
                  onChange={(e) => setRequiredField(e, ques)}
                  name="required"
                />
              }
              label="Required"
            />
          </div>
        </>
      )}
      <Divider />
      <div className="flex justify-between ml-1">
        {isQuiz && !isAnswerOpened && (
          <div className="flex items-center">
            {!skipMarks.includes(ques.type) && (
              <>
                <Button
                  variant="text"
                  onClick={(e) => showAnswerView(true)}
                  color="primary"
                  style={{ textTransform: "capitalize" }}
                  startIcon={<AssignmentTurnedIn />}
                >
                  Answer key
                </Button>
                <p>{`(${answerMarks} points)`}</p>
              </>
            )}
          </div>
        )}
        <AccordionActions>
          {!isAnswerOpened ? (
            <>
              <IconButton
                aria-label="View"
                onClick={() => {
                  showAsQuestion(index);
                }}
              >
                <VisibilityIcon />
              </IconButton>

              <IconButton
                aria-label="Copy"
                onClick={() => {
                  copyQuestion(index);
                }}
              >
                <FilterNoneIcon />
              </IconButton>
              <Divider orientation="vertical" flexItem />

              <IconButton
                aria-label="delete"
                onClick={() => {
                  deleteQuestion(index);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
              {!groupByListFormType.includes(ques.type) && (
                <MenuBar
                  width={width}
                  menuList={[
                    {
                      text: "Shuffle Options",
                      icon: ques[SHUFFLE_OPTIONS] ? (
                        <Check className="menu-icon" />
                      ) : null,
                    },
                  ]}
                  actionList={[() => shuffleQuestionOption(ques)]}
                />
              )}
            </>
          ) : (
            <Button
              onClick={(e) => showAnswerView(true)}
              variant="contained"
              color="primary"
            >
              Done
            </Button>
          )}
        </AccordionActions>
      </div>
    </>
  );
};
