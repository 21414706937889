import { Grid, LinearProgress, Paper, styled } from "@material-ui/core";
import React from "react";

export default function ResponseProgress({
  forceSmall,
  responseData,
  questions,
}) {
  let progress = 0;
  const calculateProgress = () => {
    let value =
      (responseData && Object.keys(responseData).length
        ? Object.keys(responseData).length
        : 0) / (questions && questions.length ? questions.length : 0);
    // setProgress(value);
    progress = value * 100;
    console.log("Progress is ", value, responseData, questions);
  };
  console.log("Progress change ", progress, responseData, questions);
  calculateProgress();
  return (
    <>
      <Grid
        item
        xs={forceSmall ? 12 : 12}
        sm={forceSmall ? 12 : 6}
        lg={forceSmall ? 12 : 6}
        style={{ width: "100%" }}
      >
        <Paper
          variant="outlined"
          style={{
            borderRadius: 11,
            borderStyle: "dashed",
            padding: 6,
            paddingBottom: 3,
          }}
          className="flex w-full items-center justify-between"
        >
          <BorderLinearProgress
            className="w-full"
            style={{ width: "85%" }}
            variant="determinate"
            value={progress}
          />
          <span className="ml-1 mr-2">{`${Object.keys(responseData).length} / ${
            questions.length
          }`}</span>
        </Paper>
      </Grid>
      <br />
    </>
  );
}
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${"colorPrimary"}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${"bar"}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
