import { ImageList, ImageListItem, ImageListItemBar } from "@material-ui/core";
import React, { useState } from "react";
import { UseStorageFiles } from "../../hooks/useStorage";

export default function MyImages({
  selectImage,
  setConfigData,
  configData,
  isSmall,
}) {
  const [images, setImages] = React.useState(
    configData.images ? configData.images : null
  );
  const [selectedIndex, setSelectedIndex] = useState(
    configData.selectedIndex ? configData.selectedIndex : -1
  );

  console.log(images, configData);
  const handleImageClick = (img, index) => {
    setSelectedIndex(index);

    selectImage({
      fileName: img.name.length > 20 ? img.name.substring(0, 20) : img.name,
      fileUrl: img.url,
      file: img,
      fileSize: 100,
      timestamp: new Date().getTime(),
    });
    setTimeout(() => {
      setConfigData("my", { selectedIndex: index });
    }, 100);
  };
  return (
    <ImageList variant="masonry" cols={isSmall ? 2 : 3} gap={8}>
      {!images && (
        <GetFilesFromStorage
          setImages={setImages}
          setConfigData={setConfigData}
        />
      )}
      {images &&
        images.map((item, index) => (
          <ImageListItem
            key={index}
            className={`cursor-pointer ${
              index === selectedIndex ? "selected-image" : ""
            }`}
            style={isSmall ? { width: 130, height: 130 } : null}
            onClick={() => handleImageClick(item, index)}
          >
            <img
              style={isSmall ? { width: 130, height: 130 } : null}
              src={`${item.url}&w=248&fit=crop&auto=format`}
              srcSet={`${item.url}&w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar title={item.name}></ImageListItemBar>
          </ImageListItem>
        ))}
    </ImageList>
  );
}
const GetFilesFromStorage = ({ setImages, setConfigData }) => {
  const handleImage = (images) => {
    setImages(images);
    setConfigData("my", { images: images });
  };
  let { files } = UseStorageFiles(handleImage);
  return <></>;
};
