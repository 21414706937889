import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Typography,
} from "@material-ui/core";
import React from "react";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import CloseIcon from "@material-ui/icons/Close";

export default function FormCopyDetails({
  open,
  handleClose,
  classes,
  formId,
  clipToClipboard,
  openOfAlert,
  handleCloseOfAlert,
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Copy and share link."}
        </DialogTitle>
        <DialogContent>
          <Paper className={classes.paper}>
            <Grid container alignContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="body1">
                  {/* formDetails._id */}
                  {window.location.origin + "/" + formId + "/view"}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  className={classes.button}
                  aria-label="Add"
                  size="medium"
                  onClick={clipToClipboard}
                >
                  <FilterNoneIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>

          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openOfAlert}
        autoHideDuration={3000}
        onClose={handleCloseOfAlert}
        message="Copied to clipboard"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseOfAlert}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
}
