import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "./actiontypes";

let defaultUserState = {
  errorMessage: "",
  successMessage: "",
};

export const taskReducer = (state = defaultUserState, action) => {
  console.log("reducer", state, action);
  if (action.type === ERROR_MESSAGE) {
    let payload = action.payload;
    state = { ...state, errorMessage: payload };
    return state;
  } else if (action.type === SUCCESS_MESSAGE) {
    let payload = action.payload;
    state = { ...state, successMessage: payload };
    return state;
  }
  return state;
};
