import React from "react";
import Accordion from "@material-ui/core/Accordion";

//import QuestionHeader from './QuestionHeader';
import { Grid, withWidth } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ImageUplaodModel from "./ImageUplaodModel";
import CircularProgress from "@material-ui/core/CircularProgress";
import { generateUniqueId, getFormComponent } from "../../../files/util";

import {
  AnswerView,
  EditQuestionView,
  FormActions,
  FormHeader,
  QuestionActions,
  QuestionDemoView,
} from "../../../components/forms/Form/CreateFormUtilComponents";
import {
  QUESTION_POINTS_DEFAULT,
  SHOW_CORRECT_ANS,
  SHOW_POINT_VALUE,
  SHUFFLE_OPTIONS,
  TYPE_QUIZ,
} from "../../../files/Constants";
function QuestionsTab(props) {
  const { questions, setQuestions, answerData, setAnswerData } = props;
  const [openUploadImagePop, setOpenUploadImagePop] = React.useState(false);
  const [showAnswerView, setShowAnswerView] = React.useState({});
  const [imageContextData, setImageContextData] = React.useState({
    question: null,
    option: null,
  });
  const [formData, setFormData] = React.useState({});
  const [loadingFormData, setLoadingFormData] = React.useState(true);

  React.useEffect(() => {
    if (props.formData.questions !== undefined) {
      if (props.formData.questions.length === 0) {
        props.setQuestions([
          {
            questionText: "",
            options: [{ optionText: "", id: generateUniqueId() }],
            type: "text",
            open: true,
            id: generateUniqueId(),
          },
        ]);
      } else {
        props.setQuestions(props.formData.questions);
      }
      setLoadingFormData(false);
      setFormData(props.formData);
    } else if (props.formData.status === 200) {
      setFormData(props.formData);
      setLoadingFormData(false);
    }
  }, [props.formData]);

  function checkImageHereOrNotForQuestion(gg) {
    if (gg === undefined || gg === "") {
      return false;
    } else {
      return true;
    }
  }

  function checkImageHereOrNotForOption(gg) {
    // console.log(gg);
    if (gg === undefined || gg === "") {
      return false;
    } else {
      return true;
    }
  }

  function addMoreQuestionField(type) {
    expandCloseAll();
    let data = {
      questionText: "",
      options: [{ optionText: "", id: generateUniqueId() }],
      type: type,
      open: true,
      id: generateUniqueId(),
    };
    if (type === "file") data.options[0]["allowed"] = "";
    props.setQuestions((questions) => [...questions, data]);
  }

  function copyQuestion(i) {
    let qs = [...questions];
    expandCloseAll();
    const myNewOptions = [];
    qs[i].options.forEach((opn) => {
      if (opn.optionImage !== undefined || opn.optionImage !== "") {
        var opn1new = {
          optionText: opn.optionText,
          optionImage: opn.optionImage,
        };
      } else {
        var opn1new = {
          optionText: opn.optionText,
        };
      }
      myNewOptions.push(opn1new);
    });
    const qImage = qs[i].questionImage || "";
    var newQuestion = {
      questionText: qs[i].questionText,
      questionImage: qImage,
      options: myNewOptions,
      open: true,
      type: qs[i].type,
    };
    props.setQuestions((questions) => [...questions, newQuestion]);
  }

  const handleImagePopupOpen = () => {
    setOpenUploadImagePop(true);
  };

  function uploadImage(i, j) {
    setImageContextData({
      question: i,
      option: j,
    });
    handleImagePopupOpen();
  }

  function updateImageLink(link, context) {
    var optionsOfQuestion = [...questions];
    var i = context.question;

    if (context.option == null) {
      optionsOfQuestion[i].questionImage = link;
    } else {
      var j = context.option;
      optionsOfQuestion[i].options[j].optionImage = link;
    }
    props.setQuestions(optionsOfQuestion);
  }

  function deleteQuestion(i) {
    let qs = [...questions];
    if (questions.length > 1) {
      qs.splice(i, 1);
    }
    props.setQuestions(qs);
  }

  function handleOptionValue(data, i, j) {
    var optionsOfQuestion = [...questions];
    optionsOfQuestion[i].options[j] = data;
    props.setQuestions(optionsOfQuestion);
  }

  function handleQuestionValue(text, i) {
    var optionsOfQuestion = [...questions];
    optionsOfQuestion[i].questionText = text;
    props.setQuestions(optionsOfQuestion);
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    var itemgg = [...questions];

    const itemF = reorder(
      itemgg,
      result.source.index,
      result.destination.index
    );

    props.setQuestions(itemF);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function showAsQuestion(i) {
    let qs = [...questions];
    qs[i].open = false;
    props.setQuestions(qs);
  }

  function addOption(i) {
    var optionsOfQuestion = [...questions];
    optionsOfQuestion[i].options.push({
      optionText: "",
      id: generateUniqueId(),
      type: "single",
    });

    props.setQuestions(optionsOfQuestion);
  }

  function removeOption(i, j) {
    var optionsOfQuestion = [...questions];
    if (optionsOfQuestion[i].options.length > 1) {
      optionsOfQuestion[i].options.splice(j, 1);
      props.setQuestions(optionsOfQuestion);
    }
  }

  function expandCloseAll() {
    let qs = [...questions];
    for (let j = 0; j < qs.length; j++) {
      qs[j].open = false;
    }
    props.setQuestions(qs);
  }

  function handleExpand(i) {
    let qs = [...questions];
    for (let j = 0; j < qs.length; j++) {
      if (i === j) {
        qs[i].open = true;
      } else {
        qs[j].open = false;
      }
    }
    props.setQuestions(qs);
  }
  function getOptionTypeUi(data, type, i, j, questionId) {
    // console.log("text", text, "type", type, i, j);
    let text = data.optionText;
    return getFormComponent(
      type,
      showAnswerView[questionId] ? "answer" : "edit",
      {
        optionData: data,
        handleOptionValue,
        questionIndex: i,
        optionIndex: j,
        uploadImage,
        removeOption,
      }
    );
  }
  const setRequiredField = (e, question) => {
    // console.log("checkbox", question.required, e.target.checked);
    question.required = e.target.checked;
  };
  const handleShuffleQuestion = (question) => {
    question[SHUFFLE_OPTIONS] = !question[SHUFFLE_OPTIONS];
    console.log("Shuffle question", question);
  };
  const getQuestionAnswer = (quesId) => {
    try {
      const defaultValue = {
        marks:
          formData &&
          formData.configuration &&
          formData.configuration[QUESTION_POINTS_DEFAULT]
            ? formData.configuration[QUESTION_POINTS_DEFAULT]
            : 0,
      };
      if (!answerData) {
        return defaultValue;
      } else {
        if (answerData[quesId]) {
          return answerData[quesId].marks
            ? answerData[quesId]
            : { ...defaultValue, ...answerData[quesId] };
        } else {
          return defaultValue;
        }
      }
    } catch (error) {
      console.log(error);

      return { marks: 0 };
    }
  };
  console.log("show answer view", showAnswerView);
  function questionsUI() {
    return (
      questions.length > 0 &&
      questions.map((ques, i) => (
        <React.Fragment key={i}>
          {!ques.hidden && (
            <Draggable key={i + ques} draggableId={i + "id"} index={i}>
              {(provided, snapshot) => (
                <div
                  key={i + ques}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <div style={{ marginBottom: "15px" }}>
                    <div
                      style={{
                        width: "100%",
                        marginBottom: -7,
                        position: "absolute",
                        zIndex: 2,
                        left: 0,
                        textAlign: "center",
                      }}
                    >
                      <DragIndicatorIcon
                        style={{
                          transform: "rotate(-90deg)",
                          color: "#545454",
                        }}
                        fontSize="small"
                      />
                    </div>

                    <Accordion
                      onChange={() => {
                        handleExpand(i);
                      }}
                      expanded={questions[i].open}
                    >
                      <QuestionDemoView
                        index={i}
                        ques={ques}
                        questions={questions}
                      />

                      {!showAnswerView[ques.id] ? (
                        <>
                          <EditQuestionView
                            addOption={addOption}
                            index={i}
                            checkImageHereOrNotForOption={
                              checkImageHereOrNotForOption
                            }
                            checkImageHereOrNotForQuestion={
                              checkImageHereOrNotForQuestion
                            }
                            getOptionTypeUi={getOptionTypeUi}
                            handleQuestionValue={handleQuestionValue}
                            ques={ques}
                            removeOption={removeOption}
                            updateImageLink={updateImageLink}
                            uploadImage={uploadImage}
                          />
                        </>
                      ) : (
                        <AnswerView
                          questions={questions}
                          index={i}
                          isEdit={true}
                          ques={ques}
                          showAnswer={formData.configuration[SHOW_CORRECT_ANS]}
                          showMarks={formData.configuration[SHOW_POINT_VALUE]}
                          questionAnswer={getQuestionAnswer(ques.id)}
                          setQuestionAnswer={(answer, marks, feedback) =>
                            setAnswerData(ques.id, answer, marks, feedback)
                          }
                        />
                      )}
                      <QuestionActions
                        index={i}
                        isQuiz={
                          formData && formData.configuration
                            ? formData.configuration[TYPE_QUIZ]
                            : null
                        }
                        showAnswerView={() =>
                          setShowAnswerView({
                            ...showAnswerView,
                            [ques.id]: !Boolean(showAnswerView[ques.id]),
                          })
                        }
                        answerData={answerData}
                        copyQuestion={copyQuestion}
                        showAsQuestion={showAsQuestion}
                        deleteQuestion={deleteQuestion}
                        setRequiredField={setRequiredField}
                        ques={ques}
                        answerMarks={getQuestionAnswer(ques.id).marks}
                        isAnswerOpened={Boolean(showAnswerView[ques.id])}
                        width={props.width}
                        shuffleQuestionOption={handleShuffleQuestion}
                      />
                    </Accordion>
                  </div>
                </div>
              )}
            </Draggable>
          )}
        </React.Fragment>
      ))
    );
  }
  return (
    <>
      {loadingFormData ? <CircularProgress /> : null}

      <Grid item style={{ width: "100%", textAlign: "initial" }}>
        <FormHeader formData={formData} />

        <div style={{ paddingTop: "10px" }}>
          <div>
            <ImageUplaodModel
              handleImagePopOpen={openUploadImagePop}
              bgColor={props.formData.configuration["bgColor"]}
              handleImagePopClose={() => {
                setOpenUploadImagePop(false);
              }}
              updateImageLink={updateImageLink}
              contextData={imageContextData}
            />

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {questionsUI()}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <FormActions
              addMoreQuestionField={addMoreQuestionField}
              saveQuestions={props.saveQuestions}
            />
          </div>
        </div>
      </Grid>
    </>
  );
}

export default withWidth()(QuestionsTab);
