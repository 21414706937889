import { Checkbox, IconButton, TextField } from "@material-ui/core";
import { Divider, FormControlLabel } from "@material-ui/core";
import React from "react";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import Close from "@material-ui/icons/Close";

export default function MultiSelection({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  edit,
  removeOption,
  questionViewData,
}) {
  return (
    <React.Fragment>
      {edit && edit === "edit" ? (
        <EditView
          optionData={optionData}
          handleOptionValue={handleOptionValue}
          uploadImage={uploadImage}
          questionIndex={questionIndex}
          optionIndex={optionIndex}
          removeOption={removeOption}
        />
      ) : (
        <QuestionView {...questionViewData} />
      )}
    </React.Fragment>
  );
}

const EditView = ({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  removeOption,
}) => {
  const handleValue = (e) => {
    handleOptionValue(
      { ...optionData, optionText: e.target.value },
      questionIndex,
      optionIndex
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginLeft: "-12.5px",
        justifyContent: "space-between",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
    >
      <Checkbox disabled />
      <TextField
        fullWidth={true}
        variant="outlined"
        placeholder="Option text"
        style={{ marginTop: "5px" }}
        value={optionData.optionText}
        onChange={handleValue}
      />

      <IconButton
        aria-label="upload image"
        onClick={() => {
          uploadImage(questionIndex, optionIndex);
        }}
      >
        <CropOriginalIcon />
      </IconButton>
      <IconButton
        aria-label="delete"
        onClick={() => {
          removeOption(questionIndex, optionIndex);
        }}
      >
        <Close />
      </IconButton>
    </div>
  );
};

const QuestionView = ({
  questionId,
  responseData,
  options,
  removeError,
  actionMethod,
}) => {
  // const [checkBoxes, setCheckBoxes] = React.useState(
  //   responseData[questionId] ? responseData[questionId].split(",") : []
  // );
  const [forceUpdate, setForceUpdate] = React.useState(false);
  const handleValueChange = (optionId) => {
    let list = [];
    if (responseData[questionId]) list = responseData[questionId].split(",");

    if (!list.includes(optionId)) {
      list.push(optionId);
    } else {
      const index = list.indexOf(optionId);
      if (index > -1) {
        list.splice(index, 1);
      }
    }
    // setCheckBoxes(list);
    if (removeError) removeError(questionId);
    setForceUpdate(!forceUpdate);
    if (actionMethod) actionMethod(questionId, list.toString());
  };
  console.log(responseData, responseData[questionId]);
  return (
    <>
      {options.map((option, index) => (
        <div key={option.id}>
          <div
            style={{
              display: "flex",
              marginLeft: "7px",
            }}
          >
            <FormControlLabel
              // value={option.id}
              className={actionMethod === null ? "custom-disabled" : ""}
              control={
                <Checkbox
                  disabled={actionMethod === null}
                  checked={
                    responseData[questionId] &&
                    responseData[questionId].includes(option.id)
                  }
                  name={option.id}
                />
              }
              label={option.optionText}
              onChange={(e) => handleValueChange(option.id)}
            />
          </div>

          <div
            style={{
              display: "flex",
              marginLeft: "10px",
            }}
          >
            {option.optionImage !== "" ? (
              <img src={option.optionImage} width="64%" height="auto" />
            ) : (
              ""
            )}
            <Divider />
          </div>
        </div>
      ))}
    </>
  );
};
