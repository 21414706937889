import {
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import formService from "../../../files/apis";

import dayjs from "dayjs";
import React, { Component } from "react";
import { formTypes, responseGraphMenu } from "../../../files/Lists";
import HorizontalGraph from "../../graphs/HorizontalGraph";
import LineGraph from "../../graphs/LineGraph";
import PieGraph from "../../graphs/PieGraph";
import TextGraph from "../../graphs/TextGraph";
import ErrorBoundary from "../../errors/ErrorBoundary";
import { MenuBar } from "../../MenuBar";
import { createCSV, handleDownloadHtml } from "../../../files/util";
import ScoreInsights from "../../graphs/ScoreInsights";
import { TableChart, Update } from "@material-ui/icons";
class SummaryTab extends Component {
  findResponses = () => {
    let res = {};
    this.props.questions.forEach((item) => {
      let r = {};
      r.questionText = item.questionText;
      //count total response
      let quesId = item.id;
    });
  };
  getGraph = (respData, quesType) => {
    let type = formTypes[quesType].graph;
    let data = {
      labels:
        quesType === "rating"
          ? Object.keys(respData["valueCount"])
          : respData["optionText"],
      data:
        quesType === "rating"
          ? Object.values(respData["valueCount"])
          : respData["optionValue"],
    };
    console.log("get graph", data, respData);
    switch (type) {
      case "pie":
        return (
          <ErrorBoundary>
            <PieGraph
              isSmall={this.props.width === "xs"}
              {...data}
              title={respData.questionText}
            />
          </ErrorBoundary>
        );
      case "bar":
        return (
          <ErrorBoundary>
            <HorizontalGraph
              isSmall={this.props.width === "xs"}
              {...data}
              title={respData.questionText}
            />
          </ErrorBoundary>
        );
      case "text":
        return (
          <ErrorBoundary>
            <TextGraph isSmall={this.props.width === "xs"} {...data} />
          </ErrorBoundary>
        );
      case "line":
        return (
          <ErrorBoundary>
            <LineGraph
              isSmall={this.props.width === "xs"}
              {...data}
              title={respData.questionText}
            />
          </ErrorBoundary>
        );
      default:
        return null;
    }
  };
  showResponse = (resId) => {
    this.props.openIndividualResponse(resId);
  };
  handleDownloadCard = (id) => {
    handleDownloadHtml("#" + id, "#icon-" + id);
  };
  downloadGraphData = (ques, type) => {
    console.log("downloadGraphData", ques);
    let data =
      type === "rating"
        ? Object.values(ques["valueCount"])
        : ques["optionValue"];
    let labels =
      type === "rating" ? Object.keys(ques["valueCount"]) : ques["optionText"];
    if (data) {
      createCSV(data, ques.questionText, labels, type);
    }
  };
  removeResponse = (quesId) => {
    formService
      .deleteSpecificQuestionResponse(this.props.formId, quesId)
      .then((res) => {
        if (res) {
          this.props.responses = [];
        }
      });
  };
  releaseScore = (event, responseId) => {
    event.stopPropagation();
    console.log(responseId);
    if (this.props.formId)
      formService.evaluateScore(this.props.formId, responseId);
  };
  render() {
    const {
      questions,
      responses,
      formResponseData,
      isQuiz,
      openIndividualResponse,
    } = this.props;
    console.log("responses", responses);
    return (
      <>
        <Card variant="outlined" className="rounded-paper">
          <div className="flex justify-between">
            <p className="p-3 section-title">Submission</p>
            {isQuiz && (
              <Button
                color="primary"
                size="medium"
                startIcon={<TableChart />}
                onClick={(e) => this.releaseScore(e)}
              >
                Release Score
              </Button>
            )}
          </div>
          <Divider />
          <CardContent>
            <TableContainer className="information-container">
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    {isQuiz && (
                      <>
                        <TableCell>Score</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Score released
                        </TableCell>
                      </>
                    )}
                    <TableCell>Submit Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {responses &&
                    responses.length > 0 &&
                    responses.map((rs, j) => (
                      <TableRow
                        onClick={() => this.showResponse(rs.userId)}
                        key={j}
                        hover
                        className="table-style"
                      >
                        <TableCell component="th" scope="row">
                          {rs.userId}
                        </TableCell>
                        {isQuiz && (
                          <>
                            <TableCell component="th" scope="row">
                              {rs.score ? (
                                <span>
                                  <b>{rs.score.marks}</b>/{rs.score.totalMarks}
                                </span>
                              ) : (
                                <span>Pending</span>
                              )}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <div style={{ textAlign: "center" }}>
                                {rs.score ? (
                                  <>
                                    <span>
                                      {dayjs(
                                        parseInt(rs.score.scoreReleaseDate)
                                      ).format("MMM DD YYYY HH:mm")}
                                    </span>

                                    <span>
                                      <Tooltip title="Reevaluate Score">
                                        <IconButton
                                          size="small"
                                          onClick={(e) =>
                                            this.releaseScore(e, rs._id)
                                          }
                                        >
                                          <Update fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </span>
                                  </>
                                ) : (
                                  <span>
                                    <Tooltip title="Evaluate Score">
                                      <IconButton
                                        size="small"
                                        onClick={(e) =>
                                          this.releaseScore(e, rs._id)
                                        }
                                      >
                                        <Update fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </span>
                                )}
                              </div>
                            </TableCell>
                          </>
                        )}
                        <TableCell scope="row">
                          {dayjs(rs.timeStamp.toMillis()).format(
                            "MMM DD YYYY HH:mm"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
        {isQuiz && responses && responses.length > 0 && (
          <Card
            style={{ textAlign: "-webkit-center" }}
            variant="outlined"
            id={"summary-insights"}
            className="rounded-paper"
          >
            <CardContent>
              <div className={`flex justify-between`}>
                <div className="text-center">
                  <p variant="body1" className="section-title">
                    Insights
                  </p>
                </div>
              </div>

              <br />
              <Divider style={{ marginLeft: -18, marginRight: -18 }} />
              <ErrorBoundary>
                <ScoreInsights
                  data={responses}
                  totalMarks={this.props.totalMarks}
                />
              </ErrorBoundary>
            </CardContent>
          </Card>
        )}

        {Object.keys(formResponseData).map((quesId, index) => {
          let ques = formResponseData[quesId];
          return (
            <Card
              style={{ textAlign: "-webkit-center" }}
              key={quesId + index}
              variant="outlined"
              id={"summary-" + quesId + "-" + index}
              className="rounded-paper"
            >
              <CardContent>
                <div className={`flex justify-between`}>
                  <div className="text-center">
                    <p variant="body1" className="section-title">
                      {ques.questionText}
                    </p>
                    <p>{this.findResponses(quesId)}</p>
                  </div>

                  <MenuBar
                    elemId={"icon-summary-" + quesId + "-" + index}
                    width={this.props.width}
                    menuList={responseGraphMenu}
                    actionList={[
                      () => this.downloadGraphData(ques, ques.type),
                      () =>
                        this.handleDownloadCard(
                          "summary-" + quesId + "-" + index
                        ),
                      null,
                      () => this.removeResponse(quesId),
                    ]}
                  />
                </div>
                <small className="float-left">
                  {ques["valueCount"].length} responses
                </small>
                <br />
                <Divider style={{ marginLeft: -18, marginRight: -18 }} />
                {this.getGraph(ques, ques.type)}
              </CardContent>
            </Card>
          );
        })}
      </>
    );
  }
}

export default SummaryTab;
