import { Alert, AlertTitle } from "@material-ui/lab";
import React, { Component } from "react";
import { groupBy } from "../../files/util";

export default class TextGraph extends Component {
  state = { data: {} };
  componentDidMount() {
    this.setState({ data: groupBy(this.props.data) });
  }
  render() {
    return (
      <div className="information-container">
        {Object.keys(this.state.data).map((text, index) => (
          <Alert key={index} className="mt-3" severity="info" icon={false}>
            <AlertTitle>{this.state.data[text]} Response</AlertTitle>
            {text}
          </Alert>
        ))}
      </div>
    );
  }
}
