const {
  GetApp,
  LocalPrintshop,
  Delete,
  Assessment,
} = require("@material-ui/icons");

export const fileTypes = [
  { name: "Docs", dbPath: "docs", path: "docs", image: "/app_icon.png" },
  {
    name: "Worksheet",
    dbPath: "worksheet",
    path: "worksheet",
    image: "/workbook.png",
  },
  { name: "Forms", dbPath: "form", path: "form", image: "/forms.png" },
  { name: "Files", dbPath: "files", path: "files", image: "/folder.png" },
  { name: "MetaCalls", dbPath: "", path: "metacalls", image: "/metacalls.png" },
  { name: "Share", dbPath: "", path: "share", image: "/share.png" },
  { name: "Chat", dbPath: "", path: "chat", image: "/chat.png" },
  { name: "LetsSocially", dbPath: "", path: "social", image: "/social.png" },
  { name: "Emoji", dbPath: "", path: "emoji", image: "/emoji.png" },
  { name: "Covid", dbPath: "", path: "Covid", image: "/covid.png" },
];

export const spreadsheetOption = {
  mode: "edit", // edit | read
  showToolbar: true,
  showGrid: true,
  showContextmenu: true,
  view: {
    height: () => document.documentElement.clientHeight,
    width: () => document.documentElement.clientWidth,
  },
  row: {
    len: 600,
    height: 25,
  },
  col: {
    len: 26,
    width: 100,
    indexWidth: 60,
    minWidth: 60,
  },
  style: {
    bgcolor: "#ffffff",
    align: "left",
    valign: "middle",
    textwrap: false,
    strike: false,
    underline: false,
    color: "#0a0a0a",
    font: {
      name: "Helvetica",
      size: 10,
      bold: false,
      italic: false,
    },
  },
};
export const colors = [
  "#000000",
  "#0C090A",
  "#34282C",
  "#3B3131",
  "#3A3B3C",
  "#413839",
  "#3D3C3A",
  "#463E3F",
  "#4C4646",
  "#504A4B",
  "#565051",
  "#52595D",
  "#5C5858",
  "#625D5D",
  "#666362",
  "#6D6968",
  "#696969",
  "#726E6D",
  "#736F6E",
  "#757575",
  "#797979",
  "#837E7C",
  "#808080",
  "#848482",
  "#A9A9A9",
  "#B6B6B4",
  "#C0C0C0",
  "#C9C0BB",
  "#D1D0CE",
  "#CECECE",
  "#D3D3D3",
  "#DCDCDC",
  "#E5E4E2",
  "#BCC6CC",
  "#98AFC7",
  "#838996",
  "#778899",
  "#708090",
  "#6D7B8D",
  "#657383",
  "#616D7E",
  "#646D7E",
  "#566D7E",
  "#737CA1",
  "#728FCE",
  "#4863A0",
  "#2B547E",
  "#29465B",
  "#2B3856",
  "#123456",
  "#151B54",
  "#191970",
  "#000080",
  "#151B8D",
  "#00008B",
  "#15317E",
  "#0000A0",
  "#0000A5",
  "#0020C2",
  "#0000CD",
  "#0041C2",
  "#2916F5",
  "#0000FF",
  "#0909FF",
  "#1F45FC",
  "#2554C7",
  "#1569C7",
  "#1974D2",
  "#2B60DE",
  "#4169E1",
  "#2B65EC",
  "#306EFF",
  "#157DEC",
  "#1589FF",
  "#1E90FF",
  "#368BC1",
  "#4682B4",
  "#488AC7",
  "#357EC7",
  "#3090C7",
  "#659EC7",
  "#87AFC7",
  "#95B9C7",
  "#6495ED",
  "#6698FF",
  "#56A5EC",
  "#38ACEC",
  "#00BFFF",
  "#3BB9FF",
  "#5CB3FF",
  "#79BAEC",
  "#82CAFF",
  "#87CEFA",
  "#87CEEB",
  "#A0CFEC",
  "#B7CEEC",
  "#B4CFEC",
  "#ADDFFF",
  "#C2DFFF",
  "#C6DEFF",
  "#BDEDFF",
  "#B0E0E6",
  "#AFDCEC",
  "#ADD8E6",
  "#B0CFDE",
  "#C9DFEC",
  "#D5D6EA",
  "#E3E4FA",
  "#E6E6FA",
  "#EBF4FA",
  "#F0F8FF",
  "#F8F8FF",
  "#F0FFFF",
  "#E0FFFF",
  "#CCFFFF",
  "#9AFEFF",
  "#7DFDFE",
  "#57FEFF",
  "#00FFFF",
  "#0AFFFF",
  "#50EBEC",
  "#4EE2EC",
  "#16E2F5",
  "#8EEBEC",
  "#AFEEEE",
  "#CFECEC",
  "#81D8D0",
  "#77BFC7",
  "#92C7C7",
  "#78C7C7",
  "#7BCCB5",
  "#66CDAA",
  "#AAF0D1",
  "#7FFFD4",
  "#93FFE8",
  "#40E0D0",
  "#48D1CC",
  "#48CCCD",
  "#46C7C7",
  "#43C6DB",
  "#00CED1",
  "#43BFC7",
  "#20B2AA",
  "#3EA99F",
  "#5F9EA0",
  "#3B9C9C",
  "#008B8B",
  "#008080",
  "#045F5F",
  "#033E3E",
  "#25383C",
  "#2C3539",
  "#3C565B",
  "#4C787E",
  "#5E7D7E",
  "#307D7E",
  "#348781",
  "#438D80",
  "#4E8975",
  "#306754",
  "#2E8B57",
  "#31906E",
  "#34A56F",
  "#50C878",
  "#3EB489",
  "#3CB371",
  "#78866B",
  "#848B79",
  "#617C58",
  "#728C00",
  "#6B8E23",
  "#808000",
  "#556B2F",
  "#4B5320",
  "#667C26",
  "#4E9258",
  "#387C44",
  "#347235",
  "#347C2C",
  "#228B22",
  "#008000",
  "#006400",
  "#046307",
  "#254117",
  "#437C17",
  "#347C17",
  "#6AA121",
  "#4AA02C",
  "#41A317",
  "#12AD2B",
  "#3EA055",
  "#73A16C",
  "#6CBB3C",
  "#6CC417",
  "#4CC417",
  "#32CD32",
  "#52D017",
  "#4CC552",
  "#54C571",
  "#99C68E",
  "#8FBC8F",
  "#89C35C",
  "#85BB65",
  "#9CB071",
  "#B0BF1A",
  "#B2C248",
  "#9DC209",
  "#A1C935",
  "#9ACD32",
  "#77DD77",
  "#7FE817",
  "#59E817",
  "#57E964",
  "#16F529",
  "#5EFB6E",
  "#36F57F",
  "#00FF7F",
  "#00FA9A",
  "#5FFB17",
  "#00FF00",
  "#7CFC00",
  "#66FF00",
  "#7FFF00",
  "#87F717",
  "#98F516",
  "#B1FB17",
  "#ADFF2F",
  "#BDF516",
  "#E2F516",
  "#CCFB5D",
  "#BCE954",
  "#64E986",
  "#90EE90",
  "#6AFB92",
  "#98FB98",
  "#98FF98",
  "#B5EAAA",
  "#E3F9A6",
  "#C3FDB8",
  "#DBF9DB",
  "#F0FFF0",
  "#F5FFFA",
  "#FFFACD",
  "#FFFFC2",
  "#FFFFCC",
  "#FAFAD2",
  "#FFFFE0",
  "#F5F5DC",
  "#FFF8DC",
  "#FBF6D9",
  "#F7E7CE",
  "#FAEBD7",
  "#FFEFD5",
  "#FFEBCD",
  "#FFE4C4",
  "#F5DEB3",
  "#FFE4B5",
  "#FFE5B4",
  "#FED8B1",
  "#FFDAB9",
  "#FFDEAD",
  "#F3E3C3",
  "#F3E5AB",
  "#ECE5B6",
  "#EEE8AA",
  "#F0E68C",
  "#EDDA74",
  "#EDE275",
  "#FFE87C",
  "#FFF380",
  "#FAF884",
  "#FFFF00",
  "#FFEF00",
  "#F5E216",
  "#FFDB58",
  "#FFDF00",
  "#F9DB24",
  "#FFD801",
  "#FFD700",
  "#FDD017",
  "#EAC117",
  "#F6BE00",
  "#F2BB66",
  "#FBB917",
  "#FBB117",
  "#FFAE42",
  "#FFA62F",
  "#FFA500",
  "#EE9A4D",
  "#F4A460",
  "#E2A76F",
  "#C19A6B",
  "#E6BF83",
  "#DEB887",
  "#D2B48C",
  "#C8AD7F",
  "#C2B280",
  "#BCB88A",
  "#C8B560",
  "#C9BE62",
  "#BDB76B",
  "#BAB86C",
  "#B5A642",
  "#C7A317",
  "#D4AF37",
  "#E9AB17",
  "#E8A317",
  "#DAA520",
  "#D4A017",
  "#C68E17",
  "#B8860B",
  "#C58917",
  "#CD853F",
  "#CD7F32",
  "#C88141",
  "#B87333",
  "#966F33",
  "#806517",
  "#665D1E",
  "#8E7618",
  "#8B8000",
  "#827839",
  "#AF9B60",
  "#827B60",
  "#786D5F",
  "#483C32",
  "#493D26",
  "#513B1C",
  "#3D3635",
  "#3B2F2F",
  "#43302E",
  "#5C3317",
  "#654321",
  "#6F4E37",
  "#835C3B",
  "#7F5217",
  "#7F462C",
  "#A0522D",
  "#8B4513",
  "#8A4117",
  "#7E3817",
  "#7E3517",
  "#954535",
  "#C34A2C",
  "#C04000",
  "#C35817",
  "#B86500",
  "#B5651D",
  "#C36241",
  "#CB6D51",
  "#C47451",
  "#D2691E",
  "#CC6600",
  "#E56717",
  "#E66C2C",
  "#FF6700",
  "#FF5F1F",
  "#F87217",
  "#F88017",
  "#FF8C00",
  "#F87431",
  "#E67451",
  "#FF8040",
  "#FF7F50",
  "#F88158",
  "#F9966B",
  "#FFA07A",
  "#E9967A",
  "#E78A61",
  "#DA8A67",
  "#FA8072",
  "#F08080",
  "#F67280",
  "#E77471",
  "#F75D59",
  "#E55451",
  "#CD5C5C",
  "#FF6347",
  "#E55B3C",
  "#FF4500",
  "#FF0000",
  "#FF2400",
  "#F62217",
  "#F70D1A",
  "#F62817",
  "#E42217",
  "#E41B17",
  "#DC381F",
  "#C24641",
  "#C11B17",
  "#B22222",
  "#A52A2A",
  "#A70D2A",
  "#9F000F",
  "#990012",
  "#8B0000",
  "#800000",
  "#8C001A",
  "#800517",
  "#660000",
  "#551606",
  "#3D0C02",
  "#3F000F",
  "#2B1B17",
  "#550A35",
  "#810541",
  "#7D0541",
  "#7D0552",
  "#7E354D",
  "#7F4E52",
  "#7F525D",
  "#7F5A58",
  "#997070",
  "#B38481",
  "#BC8F8F",
  "#C5908E",
  "#C48189",
  "#C48793",
  "#E8ADAA",
  "#C4AEAD",
  "#ECC5C0",
  "#FFCBA4",
  "#F8B88B",
  "#EDC9AF",
  "#FFDDCA",
  "#FDD7E4",
  "#FFDFDD",
  "#FFE4E1",
  "#FFCCCB",
  "#FBCFCD",
  "#FBBBB9",
  "#FFC0CB",
  "#FFB6C1",
  "#FAAFBE",
  "#FAAFBA",
  "#F9A7B0",
  "#FEA3AA",
  "#E7A1B0",
  "#E799A3",
  "#E38AAE",
  "#F778A1",
  "#E56E94",
  "#DB7093",
  "#D16587",
  "#C25A7C",
  "#C25283",
  "#E75480",
  "#F660AB",
  "#FF69B4",
  "#FC6C85",
  "#F6358A",
  "#F52887",
  "#FF1493",
  "#F535AA",
  "#E45E9D",
  "#E3319D",
  "#E4287C",
  "#DC143C",
  "#C32148",
  "#C21E56",
  "#C12869",
  "#C12267",
  "#CA226B",
  "#C71585",
  "#C12283",
  "#B93B8F",
  "#DA70D6",
  "#DF73D4",
  "#EE82EE",
  "#F433FF",
  "#FF00FF",
  "#E238EC",
  "#D462FF",
  "#C45AEC",
  "#BA55D3",
  "#A74AC7",
  "#B048B5",
  "#D291BC",
  "#915F6D",
  "#7E587E",
  "#614051",
  "#583759",
  "#5E5A80",
  "#4E5180",
  "#6A5ACD",
  "#6960EC",
  "#736AFF",
  "#7B68EE",
  "#6C2DC7",
  "#6A0DAD",
  "#483D8B",
  "#4E387E",
  "#571B7E",
  "#4B0150",
  "#36013F",
  "#461B7E",
  "#4B0082",
  "#342D7E",
  "#663399",
  "#6A287E",
  "#8B008B",
  "#800080",
  "#86608E",
  "#9932CC",
  "#9400D3",
  "#8D38C9",
  "#A23BEC",
  "#B041FF",
  "#842DCE",
  "#8A2BE2",
  "#7A5DC7",
  "#7F38EC",
  "#8E35EF",
  "#893BFF",
  "#967BB6",
  "#9370DB",
  "#8467D7",
  "#9172EC",
  "#9E7BFF",
  "#DCD0FF",
  "#E0B0FF",
  "#D891EF",
  "#B666D2",
  "#C38EC7",
  "#C8A2C8",
  "#DDA0DD",
  "#E6A9EC",
  "#F2A2E8",
  "#F9B7FF",
  "#C6AEC7",
  "#D2B9D3",
  "#D8BFD8",
  "#E9CFEC",
  "#FCDFFF",
  "#EBDDE2",
  "#E9E4D4",
  "#EDE6D6",
  "#FAF0DD",
  "#F8F0E3",
  "#FFF0F5",
  "#FDEEF4",
  "#FFF9E3",
  "#FDF5E6",
  "#FAF0E6",
  "#FFF5EE",
  "#FAF5EF",
  "#FFFAF0",
  "#FFFFF0",
];
export const formDefault = {
  backgroundColor: "#f7f4f2",
  themes: [
    {
      main: "#db4437",
      bgColors: ["#fae3e1", "#f6d0cd", "#f2beb9", "#f6f6f6"],
      name: "Red",
    },

    {
      main: "#673ab7",
      bgColors: ["#f0ebf8", "#e1d8f1", "#d1c4e9", "#f6f6f6"],
      name: "Purple",
    },
    {
      main: "#3f51b5",
      bgColors: ["#eceef8", "#d9dcf0", "#c5cbe9", "#f6f6f6"],
      name: "Indigo",
    },
    {
      main: "#4285f4",
      bgColors: ["#e3edfd", "#d0e1fc", "#bdd4fb", "#f6f6f6"],
      name: "Blue",
    },
    {
      main: "#03a9f4",
      bgColors: ["#d9f2fd", "#c0eafc", "#a7e1fb", "#f6f6f6"],
      name: "Light Blue",
    },
    {
      main: "#00bcd4",
      bgColors: ["#d9f5f9", "#bfeef4", "#a6e8f0", "#f6f6f6"],
      name: "Cyan",
    },
    {
      main: "#ff5722",
      bgColors: ["#ffe6de", "#ffd5c8", "#ffc4b2", "#f6f6f6"],
      name: "Red Orange",
    },
    {
      main: "#ff9800",
      bgColors: ["#fff0d9", "#ffe5bf", "#ffdba6", "#f6f6f6"],
      name: "Orange",
    },
    {
      main: "#009688",
      bgColors: ["#d9efed", "#bfe5e1", "#a6dad5", "#f6f6f6"],
      name: "Teal",
    },
    {
      main: "#4caf50",
      bgColors: ["#d9efed", "#bfe5e1", "#a6dad5", "#f6f6f6"],
      name: "Green",
    },
    {
      main: "#607d8b",
      bgColors: ["#e4f3e5", "#d2ebd3", "#c0e3c2", "#f6f6f6"],
      name: "Blue Gray",
    },
    {
      main: "#9e9e9e",
      bgColors: ["#e7ecee", "#d7dfe2", "#c7d2d6", "#f6f6f6"],
      name: "Gray",
    },
  ],
};
export const groupByListFormType = ["date", "time", "text", "passage", "file"];
export const groupByListFormMarksOptional = [
  "singleSelection",
  "date",
  "time",
  "text",
  "passage",
];
export const skipRequired = ["passage"];
export const skipMarks = ["passage", "file"];
export const formTypes = {
  singleSelection: {
    name: "Single Choice",
    graph: "pie",
    icon: "radio_button_checked",

    multiple: true,
  },
  multiSelection: {
    name: "Multiple Choice",
    graph: "bar",
    icon: "check_box",
    multiple: true,
  },
  text: {
    name: "Text",
    graph: "text",
    icon: "font_download",
  },
  date: {
    name: "Date",
    graph: "line",
    icon: "date_range",
  },
  time: {
    name: "Time",
    graph: "line",
    icon: "schedule",
  },
  rating: {
    name: "Rating",
    graph: "bar",
    icon: "star_outline",
  },
  file: {
    name: "File Upload",
    icon: "upload_file",
  },
  list: {
    name: "Drop Down",
    graph: "bar",
    icon: "arrow_drop_down_circle",
    multiple: true,
  },
  passage: {
    name: "Pre filled Passage",
    graph: "pie",
    icon: "article",
    multiple: true,
  },
};

export const ignoreFormTypeInResponse = ["file", "passage"];
export const imageTypeList = [".jpg", ".png"];
export const attachmentTypes = [
  { name: "Image", type: imageTypeList.toString() },
  { name: "ZIP", type: ".zip" },
  { name: "Document", type: ".doc,docx" },
  { name: "Presentation", type: ".ppt" },
  { name: "Video", type: ".mp4" },
  { name: "Audio", type: ".mp3" },
  {
    name: "Excel",
    type: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
  },
  { name: "PDF", type: ".pdf" },
];
export const textValidationType = [
  { name: "Text Multiline", type: "multiline" },
  { name: "Single Line", type: "singleline" },
  { name: "Email", type: "email" },
  { name: "Phone Number", type: "phone" },
  { name: "Mobile Number", type: "mobile" },
  { name: "Only Number", type: "number" },
  { name: "Between", type: "between" },
];
export const dateValidationTypes = [
  { name: "Date (Any)", type: "any" },
  { name: "Date Range", type: "range" },
];

export const responseSummeryMainMenu = [
  { text: "Download responses (.csv)", icon: <GetApp className="menu-icon" /> },
  { text: "Print responses", icon: <LocalPrintshop className="menu-icon" /> },
  {},
  { text: "Delete responses", icon: <Delete className="menu-icon" /> },
];
export const questionMenu = [{ text: "Shuffle Options", icon: null }];
export const responseGraphMenu = [
  { text: "Download responses (.csv)", icon: <GetApp className="menu-icon" /> },
  { text: "Download image", icon: <Assessment className="menu-icon" /> },
  {},
  { text: "Delete responses", icon: <Delete className="menu-icon" /> },
];
export const imageTypes = [".jpeg", ".gif", ".png", ".webp", "jpg"];
export const temp_images = {
  page: 1,
  per_page: 20,
  photos: [
    {
      id: 775201,
      width: 1999,
      height: 2998,
      url: "https://www.pexels.com/photo/black-hanging-bridge-surrounded-by-green-forest-trees-775201/",
      photographer: "Kaique Rocha",
      photographer_url: "https://www.pexels.com/@kaiquestr",
      photographer_id: 4672,
      avg_color: "#3C423D",
      src: {
        original:
          "https://images.pexels.com/photos/775201/pexels-photo-775201.jpeg",
        large2x:
          "https://images.pexels.com/photos/775201/pexels-photo-775201.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/775201/pexels-photo-775201.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/775201/pexels-photo-775201.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/775201/pexels-photo-775201.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/775201/pexels-photo-775201.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/775201/pexels-photo-775201.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/775201/pexels-photo-775201.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Black Hanging Bridge Surrounded by Green Forest Trees",
    },
    {
      id: 1671325,
      width: 3312,
      height: 4416,
      url: "https://www.pexels.com/photo/trees-on-a-dark-forest-1671325/",
      photographer: "Francesco Ungaro",
      photographer_url: "https://www.pexels.com/@francesco-ungaro",
      photographer_id: 21273,
      avg_color: "#1A1E1B",
      src: {
        original:
          "https://images.pexels.com/photos/1671325/pexels-photo-1671325.jpeg",
        large2x:
          "https://images.pexels.com/photos/1671325/pexels-photo-1671325.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/1671325/pexels-photo-1671325.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/1671325/pexels-photo-1671325.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/1671325/pexels-photo-1671325.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/1671325/pexels-photo-1671325.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/1671325/pexels-photo-1671325.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/1671325/pexels-photo-1671325.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Trees on a Dark Forest ",
    },
    {
      id: 38136,
      width: 5760,
      height: 3840,
      url: "https://www.pexels.com/photo/green-leafed-tree-38136/",
      photographer: "veeterzy",
      photographer_url: "https://www.pexels.com/@veeterzy",
      photographer_id: 5502,
      avg_color: "#454426",
      src: {
        original:
          "https://images.pexels.com/photos/38136/pexels-photo-38136.jpeg",
        large2x:
          "https://images.pexels.com/photos/38136/pexels-photo-38136.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/38136/pexels-photo-38136.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/38136/pexels-photo-38136.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/38136/pexels-photo-38136.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/38136/pexels-photo-38136.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/38136/pexels-photo-38136.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/38136/pexels-photo-38136.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Green Leafed Tree",
    },
    {
      id: 142497,
      width: 3632,
      height: 2417,
      url: "https://www.pexels.com/photo/green-tree-near-green-plants-142497/",
      photographer: "mali maeder",
      photographer_url: "https://www.pexels.com/@mali",
      photographer_id: 7311,
      avg_color: "#566B35",
      src: {
        original:
          "https://images.pexels.com/photos/142497/pexels-photo-142497.jpeg",
        large2x:
          "https://images.pexels.com/photos/142497/pexels-photo-142497.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/142497/pexels-photo-142497.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/142497/pexels-photo-142497.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/142497/pexels-photo-142497.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/142497/pexels-photo-142497.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/142497/pexels-photo-142497.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/142497/pexels-photo-142497.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Green Tree Near Green Plants",
    },
    {
      id: 1179229,
      width: 4000,
      height: 6000,
      url: "https://www.pexels.com/photo/green-pine-trees-1179229/",
      photographer: "Matthew Montrone",
      photographer_url: "https://www.pexels.com/@matthew-montrone-230847",
      photographer_id: 230847,
      avg_color: "#595C32",
      src: {
        original:
          "https://images.pexels.com/photos/1179229/pexels-photo-1179229.jpeg",
        large2x:
          "https://images.pexels.com/photos/1179229/pexels-photo-1179229.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/1179229/pexels-photo-1179229.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/1179229/pexels-photo-1179229.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/1179229/pexels-photo-1179229.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/1179229/pexels-photo-1179229.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/1179229/pexels-photo-1179229.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/1179229/pexels-photo-1179229.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Green Pine Trees",
    },
    {
      id: 418831,
      width: 6000,
      height: 4000,
      url: "https://www.pexels.com/photo/green-grass-on-forest-418831/",
      photographer: "Rudolf Jakkel",
      photographer_url: "https://www.pexels.com/@jakkel",
      photographer_id: 19456,
      avg_color: "#565C4F",
      src: {
        original:
          "https://images.pexels.com/photos/418831/pexels-photo-418831.jpeg",
        large2x:
          "https://images.pexels.com/photos/418831/pexels-photo-418831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/418831/pexels-photo-418831.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/418831/pexels-photo-418831.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/418831/pexels-photo-418831.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/418831/pexels-photo-418831.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/418831/pexels-photo-418831.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/418831/pexels-photo-418831.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Green Grass on Forest",
    },
    {
      id: 957024,
      width: 5979,
      height: 3986,
      url: "https://www.pexels.com/photo/worms-eyeview-of-green-trees-957024/",
      photographer: "Felix Mittermeier",
      photographer_url: "https://www.pexels.com/@felixmittermeier",
      photographer_id: 344572,
      avg_color: "#828E35",
      src: {
        original:
          "https://images.pexels.com/photos/957024/forest-trees-perspective-bright-957024.jpeg",
        large2x:
          "https://images.pexels.com/photos/957024/forest-trees-perspective-bright-957024.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/957024/forest-trees-perspective-bright-957024.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/957024/forest-trees-perspective-bright-957024.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/957024/forest-trees-perspective-bright-957024.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/957024/forest-trees-perspective-bright-957024.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/957024/forest-trees-perspective-bright-957024.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/957024/forest-trees-perspective-bright-957024.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Worms Eyeview of Green Trees",
    },
    {
      id: 9754,
      width: 1920,
      height: 1200,
      url: "https://www.pexels.com/photo/aerial-photography-of-pine-trees-on-the-mountain-9754/",
      photographer: "Creative Vix",
      photographer_url: "https://www.pexels.com/@creative-vix",
      photographer_id: 2674,
      avg_color: "#768083",
      src: {
        original:
          "https://images.pexels.com/photos/9754/mountains-clouds-forest-fog.jpg",
        large2x:
          "https://images.pexels.com/photos/9754/mountains-clouds-forest-fog.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/9754/mountains-clouds-forest-fog.jpg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/9754/mountains-clouds-forest-fog.jpg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/9754/mountains-clouds-forest-fog.jpg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/9754/mountains-clouds-forest-fog.jpg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/9754/mountains-clouds-forest-fog.jpg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/9754/mountains-clouds-forest-fog.jpg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Aerial Photography of Pine Trees on the Mountain",
    },
    {
      id: 338936,
      width: 5248,
      height: 3048,
      url: "https://www.pexels.com/photo/green-leafed-tree-338936/",
      photographer: "Nejc Košir",
      photographer_url: "https://www.pexels.com/@nejc-kosir-108379",
      photographer_id: 108379,
      avg_color: "#544A20",
      src: {
        original:
          "https://images.pexels.com/photos/338936/pexels-photo-338936.jpeg",
        large2x:
          "https://images.pexels.com/photos/338936/pexels-photo-338936.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/338936/pexels-photo-338936.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/338936/pexels-photo-338936.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/338936/pexels-photo-338936.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/338936/pexels-photo-338936.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/338936/pexels-photo-338936.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/338936/pexels-photo-338936.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Green Leafed Tree",
    },
    {
      id: 167698,
      width: 6000,
      height: 4000,
      url: "https://www.pexels.com/photo/green-leaf-trees-on-forest-167698/",
      photographer: "Lum3n",
      photographer_url: "https://www.pexels.com/@lum3n-44775",
      photographer_id: 44775,
      avg_color: "#4C4635",
      src: {
        original:
          "https://images.pexels.com/photos/167698/pexels-photo-167698.jpeg",
        large2x:
          "https://images.pexels.com/photos/167698/pexels-photo-167698.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/167698/pexels-photo-167698.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/167698/pexels-photo-167698.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/167698/pexels-photo-167698.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/167698/pexels-photo-167698.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/167698/pexels-photo-167698.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/167698/pexels-photo-167698.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Green Leaf Trees on Forest",
    },
    {
      id: 1423600,
      width: 6000,
      height: 4000,
      url: "https://www.pexels.com/photo/forest-covered-in-white-fog-1423600/",
      photographer: "Johannes Plenio",
      photographer_url: "https://www.pexels.com/@jplenio",
      photographer_id: 424445,
      avg_color: "#99A68F",
      src: {
        original:
          "https://images.pexels.com/photos/1423600/pexels-photo-1423600.jpeg",
        large2x:
          "https://images.pexels.com/photos/1423600/pexels-photo-1423600.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/1423600/pexels-photo-1423600.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/1423600/pexels-photo-1423600.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/1423600/pexels-photo-1423600.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/1423600/pexels-photo-1423600.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/1423600/pexels-photo-1423600.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/1423600/pexels-photo-1423600.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Forest Covered in White Fog",
    },
    {
      id: 113338,
      width: 5760,
      height: 3840,
      url: "https://www.pexels.com/photo/bird-s-eye-view-nature-forest-trees-113338/",
      photographer: "Markus Spiske",
      photographer_url: "https://www.pexels.com/@markusspiske",
      photographer_id: 19851,
      avg_color: "#545E5A",
      src: {
        original:
          "https://images.pexels.com/photos/113338/pexels-photo-113338.jpeg",
        large2x:
          "https://images.pexels.com/photos/113338/pexels-photo-113338.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/113338/pexels-photo-113338.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/113338/pexels-photo-113338.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/113338/pexels-photo-113338.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/113338/pexels-photo-113338.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/113338/pexels-photo-113338.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/113338/pexels-photo-113338.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Free stock photo of bird s eye view, forest, green",
    },
    {
      id: 70365,
      width: 1350,
      height: 1800,
      url: "https://www.pexels.com/photo/tree-with-brunch-and-green-leaves-during-sunset-70365/",
      photographer: "Pixabay",
      photographer_url: "https://www.pexels.com/@pixabay",
      photographer_id: 2659,
      avg_color: "#49583A",
      src: {
        original:
          "https://images.pexels.com/photos/70365/forest-sunbeams-trees-sunlight-70365.jpeg",
        large2x:
          "https://images.pexels.com/photos/70365/forest-sunbeams-trees-sunlight-70365.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/70365/forest-sunbeams-trees-sunlight-70365.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/70365/forest-sunbeams-trees-sunlight-70365.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/70365/forest-sunbeams-trees-sunlight-70365.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/70365/forest-sunbeams-trees-sunlight-70365.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/70365/forest-sunbeams-trees-sunlight-70365.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/70365/forest-sunbeams-trees-sunlight-70365.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Tree With Brunch and Green Leaves during Sunset",
    },
    {
      id: 355321,
      width: 2048,
      height: 1365,
      url: "https://www.pexels.com/photo/waterfalls-in-forest-355321/",
      photographer: "Pixabay",
      photographer_url: "https://www.pexels.com/@pixabay",
      photographer_id: 2659,
      avg_color: "#313612",
      src: {
        original:
          "https://images.pexels.com/photos/355321/pexels-photo-355321.jpeg",
        large2x:
          "https://images.pexels.com/photos/355321/pexels-photo-355321.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/355321/pexels-photo-355321.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/355321/pexels-photo-355321.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/355321/pexels-photo-355321.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/355321/pexels-photo-355321.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/355321/pexels-photo-355321.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/355321/pexels-photo-355321.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Waterfalls in Forest",
    },
    {
      id: 33109,
      width: 3456,
      height: 2304,
      url: "https://www.pexels.com/photo/red-leaf-trees-near-the-road-33109/",
      photographer: "Pixabay",
      photographer_url: "https://www.pexels.com/@pixabay",
      photographer_id: 2659,
      avg_color: "#924B26",
      src: {
        original:
          "https://images.pexels.com/photos/33109/fall-autumn-red-season.jpg",
        large2x:
          "https://images.pexels.com/photos/33109/fall-autumn-red-season.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/33109/fall-autumn-red-season.jpg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/33109/fall-autumn-red-season.jpg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/33109/fall-autumn-red-season.jpg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/33109/fall-autumn-red-season.jpg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/33109/fall-autumn-red-season.jpg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/33109/fall-autumn-red-season.jpg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Red Leaf Trees Near the Road",
    },
    {
      id: 1173777,
      width: 1580,
      height: 2809,
      url: "https://www.pexels.com/photo/aerial-view-of-road-in-the-middle-of-trees-1173777/",
      photographer: "Deva Darshan",
      photographer_url: "https://www.pexels.com/@darshan394",
      photographer_id: 325118,
      avg_color: "#294F45",
      src: {
        original:
          "https://images.pexels.com/photos/1173777/pexels-photo-1173777.jpeg",
        large2x:
          "https://images.pexels.com/photos/1173777/pexels-photo-1173777.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/1173777/pexels-photo-1173777.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/1173777/pexels-photo-1173777.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/1173777/pexels-photo-1173777.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/1173777/pexels-photo-1173777.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/1173777/pexels-photo-1173777.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/1173777/pexels-photo-1173777.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Aerial View of Road in the Middle of Trees",
    },
    {
      id: 167699,
      width: 6000,
      height: 4000,
      url: "https://www.pexels.com/photo/green-pine-trees-covered-with-fogs-under-white-sky-during-daytime-167699/",
      photographer: "Lum3n",
      photographer_url: "https://www.pexels.com/@lum3n-44775",
      photographer_id: 44775,
      avg_color: "#98AAB8",
      src: {
        original:
          "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg",
        large2x:
          "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Green Pine Trees Covered With Fogs Under White Sky during Daytime",
    },
    {
      id: 886051,
      width: 3456,
      height: 4832,
      url: "https://www.pexels.com/photo/tree-pathway-886051/",
      photographer: "Adnan Uddin",
      photographer_url: "https://www.pexels.com/@adnan55",
      photographer_id: 307472,
      avg_color: "#3C3733",
      src: {
        original:
          "https://images.pexels.com/photos/886051/pexels-photo-886051.jpeg",
        large2x:
          "https://images.pexels.com/photos/886051/pexels-photo-886051.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/886051/pexels-photo-886051.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/886051/pexels-photo-886051.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/886051/pexels-photo-886051.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/886051/pexels-photo-886051.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/886051/pexels-photo-886051.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/886051/pexels-photo-886051.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Tree Pathway",
    },
    {
      id: 132428,
      width: 3456,
      height: 5184,
      url: "https://www.pexels.com/photo/empty-forest-132428/",
      photographer: "Anthony",
      photographer_url: "https://www.pexels.com/@inspiredimages",
      photographer_id: 37464,
      avg_color: "#40320C",
      src: {
        original:
          "https://images.pexels.com/photos/132428/pexels-photo-132428.jpeg",
        large2x:
          "https://images.pexels.com/photos/132428/pexels-photo-132428.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/132428/pexels-photo-132428.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/132428/pexels-photo-132428.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/132428/pexels-photo-132428.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/132428/pexels-photo-132428.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/132428/pexels-photo-132428.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/132428/pexels-photo-132428.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Empty Forest",
    },
    {
      id: 1834399,
      width: 2667,
      height: 4000,
      url: "https://www.pexels.com/photo/pine-trees-1834399/",
      photographer: "Yaroslav Shuraev",
      photographer_url: "https://www.pexels.com/@yaroslav-shuraev",
      photographer_id: 649765,
      avg_color: "#74746F",
      src: {
        original:
          "https://images.pexels.com/photos/1834399/pexels-photo-1834399.jpeg",
        large2x:
          "https://images.pexels.com/photos/1834399/pexels-photo-1834399.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        large:
          "https://images.pexels.com/photos/1834399/pexels-photo-1834399.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
        medium:
          "https://images.pexels.com/photos/1834399/pexels-photo-1834399.jpeg?auto=compress&cs=tinysrgb&h=350",
        small:
          "https://images.pexels.com/photos/1834399/pexels-photo-1834399.jpeg?auto=compress&cs=tinysrgb&h=130",
        portrait:
          "https://images.pexels.com/photos/1834399/pexels-photo-1834399.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
        landscape:
          "https://images.pexels.com/photos/1834399/pexels-photo-1834399.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
        tiny: "https://images.pexels.com/photos/1834399/pexels-photo-1834399.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
      },
      liked: false,
      alt: "Pine Trees",
    },
  ],
  total_results: 8000,
  next_page:
    "https://api.pexels.com/v1/search/?page=2&per_page=20&query=forest",
};
