import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  Box,
  Divider,
  LinearProgress,
  styled,
  Tab,
  Tabs,
  Typography,
  withWidth,
} from "@material-ui/core";

import LocalImage from "../../imageSelctor/LocalImage";
import SearchImage from "../../imageSelctor/SearchImage";
import MyImages from "../../imageSelctor/MyImages";

function ImageUplaodModel(props) {
  const [image, setImage] = React.useState(undefined);
  const [startUpload, setStartUpload] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [configuration, setConfiguration] = React.useState({
    local: {},
    search: { selectedIndex: -1 },
    my: {},
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setImage(null);
    setStartUpload(false);
    console.log("image init triggered");
    setConfiguration({
      ...configuration,
      search: { ...configuration.search, selectedIndex: -1 },
    });
  }, [props]);
  const uploadImage = () => {
    console.log(props.contextData);
    console.log(image);
    console.log(image.size);
    if (value === 0) setStartUpload(true);
    else updateImage(image.fileUrl, props.contextData);
  };
  const updateImage = (link, context) => {
    console.log("updating images", link, context);
    props.handleImagePopClose();
    props.updateImageLink(link, context);
  };

  console.log(image, startUpload, props.contextData);
  const setConfigData = (id, data) => {
    setConfiguration({
      ...configuration,
      [id]: { ...configuration[id], ...data },
    });
  };
  console.log(configuration);
  return (
    <div>
      <Dialog
        open={props.handleImagePopOpen}
        onClose={props.handleImagePopClose}
        aria-labelledby="form-dialog-title"
        className="image-uploader-dialog"
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            style={{
              justifyContent: "space-around",
              background: "#ebe9e973",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Tab
              style={{ textTransform: "capitalize" }}
              label="Local Image"
              {...a11yProps(0)}
            />
            <Tab
              style={{ textTransform: "capitalize" }}
              label="Search Online"
              {...a11yProps(1)}
            />
            <Tab
              style={{ textTransform: "capitalize" }}
              label="My Images"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <Divider />
        <DialogContent
          style={{ background: props.bgColor ? props.bgColor : "" }}
        >
          <Box sx={{ width: "100%" }}>
            <TabPanel value={value} index={0}>
              <LocalImage
                contextData={props.contextData}
                image={image}
                setConfigData={setConfigData}
                configData={configuration["local"]}
                isSmall={props.width === "xs"}
                setImage={setImage}
                startUpload={startUpload}
                updateImage={updateImage}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <SearchImage
                forQuestion={Boolean(props.isBigImage)}
                selectImage={setImage}
                setConfigData={setConfigData}
                configData={configuration["search"]}
                isSmall={props.width === "xs"}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <MyImages
                selectImage={setImage}
                setConfigData={setConfigData}
                configData={configuration["my"]}
                isSmall={props.width === "xs"}
              />
            </TabPanel>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={props.handleImagePopClose} color="primary">
            Cancel
          </Button>
          <Button disabled={!image} color="primary" onClick={uploadImage}>
            Add Image
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default withWidth()(ImageUplaodModel);
