import { TextField, useTheme } from "@material-ui/core";
import React from "react";
import TextValidationUI from "../validations/TextValidationUI";
import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { validations } from "../../../files/util";
export default function TextDataField({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  edit,
  questionViewData,
}) {
  return (
    <React.Fragment>
      {edit && edit === "edit" ? (
        <EditView
          optionData={optionData}
          handleOptionValue={handleOptionValue}
          uploadImage={uploadImage}
          questionIndex={questionIndex}
          optionIndex={optionIndex}
        />
      ) : (
        <QuestionView {...questionViewData} />
      )}
    </React.Fragment>
  );
}

const EditView = ({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  removeOption,
  questionViewData,
}) => {
  console.log("TextDataField EditView", handleOptionValue, optionIndex);

  const handleValue = (e) => {
    handleOptionValue(
      { ...optionData, optionText: e.target.value },
      questionIndex,
      optionIndex
    );
  };
  console.log("text field");
  return (
    <>
      <div>
        <TextField
          fullWidth={true}
          variant="outlined"
          placeholder="Option text"
          style={{ marginTop: "5px" }}
          value={
            optionData && optionData.optionText ? optionData.optionText : ""
          }
          onChange={handleValue}
        />
      </div>
      <TextValidationUI
        handleOptionValue={handleOptionValue}
        optionData={optionData}
        optionIndex={optionIndex}
        questionIndex={questionIndex}
      />
    </>
  );
};

const QuestionView = ({
  questionId,
  responseData,
  options,
  removeError,
  actionMethod,
}) => {
  const [error, setError] = React.useState("");
  const theme = useTheme();

  console.log(
    "textDataField questionview",
    questionId,
    responseData,
    options,
    actionMethod
  );
  let value = responseData[questionId];
  const handleValue = (e, option) => {
    console.log("option", option, e);
    let value = e.target.value;
    actionMethod(questionId, value);
    let isInvalid = validations(option.allowed, value);
    if (isInvalid) setError(isInvalid);
    else setError("");
    console.log("is valid", isInvalid);
    removeError(questionId);
  };
  return (
    <>
      {options.map((option, index) => (
        <div key={index}>
          <div
            style={{
              display: "flex",
              marginLeft: "7px",
            }}
          >
            <TextField
              fullWidth={true}
              variant="outlined"
              disabled={actionMethod === null}
              label={actionMethod === null ? "" : option.optionText}
              error={Boolean(error)}
              // defaultValue={value}
              value={value}
              helperText={error}
              onChange={actionMethod ? (e) => handleValue(e, option) : null}
              className={actionMethod === null ? "custom-disabled" : ""}
              style={
                actionMethod === null
                  ? {
                      color: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                      marginTop: 10,
                    }
                  : null
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              marginLeft: "10px",
            }}
          >
            {option.optionImage !== "" ? (
              <img src={option.optionImage} width="64%" height="auto" />
            ) : (
              ""
            )}
            <Divider />
          </div>
        </div>
      ))}
    </>
    // </RadioGroup>
  );
};
