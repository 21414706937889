import {
  AppBar,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { Component, useState } from "react";
import Icon from "@material-tailwind/react/Icon";
import { Cancel, Palette } from "@material-ui/icons";
import FormFileUploader from "../../FormFileUploader";
import { formDefault } from "../../../files/Lists";
import Close from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";
import SwitchView from "../../SwitchView";
import {
  ACCEPT_RESPONSE,
  ALLOW_EDIT,
  COLLECT_EMAIL,
  DISABLE_AUTOSAVE,
  DISABLE_COPY_PASTE,
  FORM_END_DATE,
  FORM_START_DATE,
  QUESTION_POINTS_DEFAULT,
  RELEASE_GRADE,
  RELEASE_GRADE_LATER,
  RELEASE_GRADE_NOW,
  REQUIRE_LOGIN,
  RESPONSE_IMAGE,
  RESPONSE_MESSAGE,
  RESULT_SUMMERY,
  SEND_RESPONSE,
  SETTINGS_VIEW,
  SHOW_CORRECT_ANS,
  SHOW_MISSED_QUES,
  SHOW_POINT_VALUE,
  SHOW_PROGRESS,
  SHUFFLE_QUES_ORDER,
  SINGLE_QUESTION_AT_A_TIME,
  THEME_VIEW,
  TYPE_QUIZ,
} from "../../../files/Constants";
import { findObj } from "../../../files/util";
class FormSettings extends Component {
  render() {
    console.log("form settings parent", this.props);
    const { configJson, type, closeView, forceUpdate, saveQuestions } =
      this.props;
    console.log(configJson);
    const getView = () => {
      switch (type) {
        case THEME_VIEW:
          return (
            <ThemeSelector
              setForceUpdate={forceUpdate}
              closeView={closeView}
              configJson={configJson}
              saveQuestions={saveQuestions}
            />
          );
        case SETTINGS_VIEW:
          return (
            <SettingsView
              closeView={closeView}
              configJson={configJson}
              saveQuestions={saveQuestions}
            />
          );
        default:
          return null;
      }
    };
    return <>{getView()}</>;
  }
}

const ThemeSelector = ({
  closeView,
  configJson,
  setForceUpdate,
  saveQuestions,
}) => {
  const [openImage, setOpenImage] = useState(false);
  const [themeSelected, setThemeSelected] = useState({});
  console.log("theme selector", saveQuestions);
  React.useEffect(() => {
    let res = findObj(configJson.theme, formDefault.themes, "main");
    if (res > -1) {
      let bgIndex = 0;
      if (configJson["bgColor"])
        bgIndex = formDefault.themes[res].bgColors.findIndex(
          (ele) => ele === configJson["bgColor"]
        );
      setThemeSelected({
        index: res,
        theme: formDefault.themes[res],
        bgColorIndex: bgIndex,
      });
    } else
      setThemeSelected({
        index: 0,
        theme: formDefault.themes[0],
        bgColorIndex: 0,
      });
  }, [configJson]);
  const [fileInfo, setFileInfo] = useState({
    headerId: "headerImage",
    bgId: "bgImage",
    responseData: {
      headerImage: configJson["headerImage"],
      bgImage: configJson["bgImage"],
    },
  });
  console.log(configJson, fileInfo);
  const handleFileSelection = (id, imageLink) => {
    console.log("file selected", id, imageLink);

    // return;
    configJson[id] = imageLink;
    setFileInfo({
      ...fileInfo,
      responseData: { ...fileInfo.responseData, [id]: imageLink },
    });
    console.log(
      "handlefile selection",
      "fileinfo->",
      fileInfo,
      "id->",
      id,
      "imglink->",
      imageLink
    );
    setForceUpdate();
  };
  const selectTheme = (theme, index) => {
    setThemeSelected({ index: index, theme, bgColorIndex: 0 });
    configJson.theme = theme.main;
    configJson.bgColor = formDefault.themes[index].bgColors[0];
    setForceUpdate();
  };
  const selectBGColor = (color, index) => {
    setThemeSelected({ ...themeSelected, color, bgColorIndex: index });
    configJson.bgColor = color;
    setForceUpdate();
  };
  const removeImage = (id) => {
    configJson[id] = "";
    setFileInfo({
      ...fileInfo,
      responseData: { ...fileInfo.responseData, [id]: "" },
    });
    setForceUpdate();
  };
  return (
    <>
      <Section
        title="Theme Options"
        closeView={closeView}
        sectionName={"HEADER"}
        IconObj={Palette}
        saveQuestions={saveQuestions}
      >
        <FormFileUploader
          edit="edit"
          questionViewData={{
            file: fileInfo.responseData[fileInfo.headerId],
            removeImage: removeImage,

            setOpenPopup: setOpenImage,
            openPopup: openImage,
            questionId: "headerImage",
            responseData: fileInfo.responseData,
            options: [{ id: fileInfo.headerId, allowed: ".png,.jpg" }],
            actionMethod: handleFileSelection,
            buttonProps: { iconName: "image", btText: "Choose Image" },
            customUpdate: setForceUpdate,
          }}
        />
      </Section>
      <Section sectionName={"THEME COLOR"}>
        <div className="flex flex-wrap">
          {formDefault.themes.map((theme, index) => (
            <Tooltip
              key={index}
              title={theme.name}
              placement="top"
              className="w-8 h-8 mt-3 mr-3"
            >
              <div
                onClick={() => selectTheme(theme, index)}
                className={`w-6 h-6 rounded-full cursor-pointer`}
                style={{
                  backgroundColor: theme.main,
                  border: "1px solid #00000029",
                }}
              >
                {themeSelected && themeSelected.index === index && (
                  <Icon
                    style={{ marginTop: 4, marginLeft: 4, fontSize: 21 }}
                    size="1xl"
                    color="white"
                    name="done"
                  />
                )}
              </div>
            </Tooltip>
          ))}
        </div>
      </Section>
      <Section sectionName="BACKGROUND">
        <div className="flex flex-wrap">
          {themeSelected.theme?.bgColors.map((theme, index) => (
            <Tooltip
              title={theme}
              key={index}
              placement="top"
              className="w-8 h-8 mt-3 mr-3"
            >
              <div
                onClick={() => selectBGColor(theme, index)}
                className={`w-6 h-6 rounded-full cursor-pointer`}
                style={{
                  backgroundColor: theme,
                  border: "1px solid #00000029",
                }}
              >
                {themeSelected && themeSelected.bgColorIndex === index && (
                  <Icon
                    style={{ marginTop: 4, marginLeft: 4, fontSize: 21 }}
                    size="1xl"
                    color="black"
                    name="done"
                  />
                )}
              </div>
            </Tooltip>
          ))}
        </div>
        <br />
        <FormFileUploader
          edit="edit"
          questionViewData={{
            file: fileInfo.responseData[fileInfo.bgId],
            removeImage: removeImage,
            bgColor: configJson["bgColor"],
            questionId: "bgImage",
            responseData: fileInfo.responseData,
            options: [{ id: fileInfo.bgId, allowed: ".png,.jpg" }],
            buttonProps: {
              iconName: "image",
              btText: "Choose Background Image",
            },
            customUpdate: setForceUpdate,

            actionMethod: handleFileSelection,
          }}
        />
      </Section>
    </>
  );
};

const SettingsView = ({ closeView, configJson, saveQuestions }) => {
  const [isQuiz, setIsQuiz] = useState(configJson[TYPE_QUIZ]);
  const [showEditResponse, setShowEditResponse] = useState(false);
  const responseText = React.useRef(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [openImageSelector, setOpenImageSelector] = useState(false);
  const [responseImage, setResponseImage] = useState(
    configJson[RESPONSE_IMAGE] ?? ""
  );
  const handleChange = (isChecked, settingsName) => {
    if (settingsName === TYPE_QUIZ) setIsQuiz(isChecked);
    configJson[settingsName] = isChecked;
    setForceUpdate(!forceUpdate);
    console.log(forceUpdate);
  };
  const handleValueChange = (value, from) => {
    console.log("Value change", value, from);
    if (from === RESPONSE_MESSAGE) {
      configJson[from] = responseText.current.value;
      if (responseImage || responseImage === "") {
        configJson[RESPONSE_IMAGE] = responseImage;
      }
      setShowEditResponse(false);
    } else configJson[from] = value;
    setForceUpdate(!forceUpdate);
  };
  const handleFileSelection = (id, imageLink) => {
    console.log("handleFileSelection ", id, imageLink);

    console.log(id, imageLink);
    if (id === RESPONSE_IMAGE) setResponseImage(imageLink);
  };
  return (
    <>
      <Section
        sectionName="FORM"
        title="Settings"
        saveQuestions={saveQuestions}
        closeView={closeView}
        IconObj={SettingsIcon}
      >
        <SwitchView
          label="Accept Response"
          defaultChecked={configJson[ACCEPT_RESPONSE]}
          actionMethod={handleChange}
          extraPropsForActionMethod={ACCEPT_RESPONSE}
        />
        {configJson[ACCEPT_RESPONSE] && (
          <div>
            <br />
            <TextField
              fullWidth
              onChange={(e) => handleChange(e.target.value, FORM_START_DATE)}
              type="datetime-local"
              variant="outlined"
              defaultValue={configJson[FORM_START_DATE]}
              label="Start Date"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <TextField
              fullWidth
              type="datetime-local"
              variant="outlined"
              label="End Date"
              defaultValue={configJson[FORM_END_DATE]}
              style={{ marginTop: 10 }}
              onChange={(e) => handleChange(e.target.value, FORM_END_DATE)}
              helperText="Optional"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
        <SwitchView
          label="Make this a quiz"
          actionMethod={handleChange}
          defaultChecked={configJson[TYPE_QUIZ]}
          extraPropsForActionMethod={TYPE_QUIZ}
          helperText="Assign point values, set answers, and automatically provide feedback"
        />

        {(isQuiz || configJson[TYPE_QUIZ]) && (
          <div>
            <FormControl style={{ marginLeft: 14 }} component="fieldset">
              <FormLabel component="legend">Release Grades</FormLabel>
              <RadioGroup
                aria-label="release-grade"
                name="release-grade1"
                defaultValue={configJson[RELEASE_GRADE]}
                value={configJson[RELEASE_GRADE]}
                onChange={(e) => handleChange(e.target.value, RELEASE_GRADE)}
              >
                <FormControlLabel
                  value={RELEASE_GRADE_NOW}
                  control={<Radio />}
                  label="Immediately after each submission"
                />
                <FormControlLabel
                  value={RELEASE_GRADE_LATER}
                  control={<Radio />}
                  label="Later, after manual review"
                />
              </RadioGroup>
            </FormControl>

            <SwitchView
              label="Missed questions"
              actionMethod={handleChange}
              defaultChecked={configJson[SHOW_MISSED_QUES]}
              extraPropsForActionMethod={SHOW_MISSED_QUES}
              helperText="Respondents can see which questions were answered incorrectly"
            />
            <SwitchView
              label="Copy and paste"
              actionMethod={handleChange}
              defaultChecked={configJson[DISABLE_COPY_PASTE]}
              extraPropsForActionMethod={DISABLE_COPY_PASTE}
              helperText="User can copy and paste content if enabled"
            />
            <SwitchView
              label="Correct answers"
              defaultChecked={configJson[SHOW_CORRECT_ANS]}
              actionMethod={handleChange}
              extraPropsForActionMethod={SHOW_CORRECT_ANS}
              helperText="Respondents can see correct answers after grades are released"
            />
            <SwitchView
              label="Point values"
              actionMethod={handleChange}
              extraPropsForActionMethod={SHOW_POINT_VALUE}
              defaultChecked={configJson[SHOW_POINT_VALUE]}
              helperText="Respondents can see total points and points received for each question"
            />
            <div style={{ marginLeft: 14 }} className="flex justify-between">
              <div>
                <Typography>Default question point value</Typography>
                <FormHelperText variant="outlined">
                  Point values for every new question
                </FormHelperText>
              </div>
              <div>
                <TextField
                  type="number"
                  defaultValue={
                    configJson[QUESTION_POINTS_DEFAULT]
                      ? configJson[QUESTION_POINTS_DEFAULT]
                      : "1"
                  }
                  className="w-16"
                  onChange={(e) =>
                    handleValueChange(e.target.value, QUESTION_POINTS_DEFAULT)
                  }
                  style={{ textAlignLast: "center" }}
                ></TextField>
                <Typography variant="caption">points</Typography>
              </div>
            </div>
          </div>
        )}
      </Section>
      <Section sectionName="RESPONSES">
        <SwitchView
          label="Collect email addresses"
          actionMethod={handleChange}
          defaultChecked={configJson[COLLECT_EMAIL]}
          extraPropsForActionMethod={COLLECT_EMAIL}
          helperText="Allow users to enter their any unregistered email id"
        />
        <SwitchView
          label="Send response copy"
          actionMethod={handleChange}
          defaultChecked={configJson[SEND_RESPONSE]}
          extraPropsForActionMethod={SEND_RESPONSE}
          helperText="Send responders a copy of their response"
        />
        <SwitchView
          label="Allow response editing"
          actionMethod={handleChange}
          defaultChecked={configJson[ALLOW_EDIT]}
          extraPropsForActionMethod={ALLOW_EDIT}
          helperText="Responses can be changed after being submitted"
        />
        <SwitchView
          label="Require Sign in"
          actionMethod={handleChange}
          defaultChecked={configJson[REQUIRE_LOGIN]}
          extraPropsForActionMethod={REQUIRE_LOGIN}
        />
      </Section>

      <Section sectionName="PRESENTATION">
        <SwitchView
          label="Show single question"
          helperText="Show questions one after another"
          actionMethod={handleChange}
          defaultChecked={configJson[SINGLE_QUESTION_AT_A_TIME]}
          extraPropsForActionMethod={SINGLE_QUESTION_AT_A_TIME}
        />
        <SwitchView
          label="Show progress bar"
          helperText="Manage how the form and responses are presented"
          actionMethod={handleChange}
          defaultChecked={configJson[SHOW_PROGRESS]}
          extraPropsForActionMethod={SHOW_PROGRESS}
        />
        <SwitchView
          label="Shuffle question order"
          actionMethod={handleChange}
          defaultChecked={configJson[SHUFFLE_QUES_ORDER]}
          extraPropsForActionMethod={SHUFFLE_QUES_ORDER}
        />

        <SwitchView
          label="View results summary"
          actionMethod={handleChange}
          defaultChecked={configJson[RESULT_SUMMERY]}
          helperText="Share results summary with respondents"
          extraPropsForActionMethod={RESULT_SUMMERY}
        />
        <SwitchView
          label="Disable autosave for all respondents"
          actionMethod={handleChange}
          defaultChecked={configJson[DISABLE_AUTOSAVE]}
          extraPropsForActionMethod={DISABLE_AUTOSAVE}
        />
      </Section>
      <Section sectionName="MESSAGE">
        <div style={{ marginLeft: 14 }} className="flex justify-between">
          {!showEditResponse ? (
            <>
              <div>
                <Typography>Confirmation message</Typography>
                <FormHelperText variant="standard">
                  {configJson[RESPONSE_MESSAGE]
                    ? configJson[RESPONSE_MESSAGE]
                    : "Your response has been recorded"}
                </FormHelperText>
              </div>
              <Button
                variant="text"
                color="primary"
                onClick={() => setShowEditResponse(true)}
              >
                Edit
              </Button>
            </>
          ) : (
            <div className="w-full">
              <TextField
                fullWidth
                variant="outlined"
                label="Confirmation Message"
                style={{ marginBottom: 10 }}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={configJson[RESPONSE_MESSAGE]}
                inputRef={responseText}
                maxRows={3}
              ></TextField>

              <FormFileUploader
                edit="edit"
                questionViewData={{
                  file: responseImage,
                  removeImage: () => {
                    setForceUpdate(!forceUpdate);
                    setResponseImage("");
                  },
                  setOpenPopup: setOpenImageSelector,
                  openPopup: openImageSelector,
                  questionId: RESPONSE_IMAGE,
                  // responseData: fileInfo.responseData,
                  options: [{ id: RESPONSE_IMAGE, allowed: ".png,.jpg" }],
                  actionMethod: handleFileSelection,
                  buttonProps: { iconName: "image", btText: "Response Image" },
                  customUpdate: () => {
                    setForceUpdate(!forceUpdate);
                  },
                }}
              />
              <Button
                variant="text"
                color="primary"
                onClick={() => handleValueChange("", RESPONSE_MESSAGE)}
              >
                Save
              </Button>
              <Button variant="text" onClick={() => setShowEditResponse(false)}>
                Cancel
              </Button>
            </div>
          )}
        </div>
      </Section>
    </>
  );
};

const Section = ({
  title,
  closeView,
  IconObj,
  sectionName,
  children,
  saveQuestions,
}) => {
  console.log("saveQuestions", saveQuestions);
  return (
    <>
      {title && (
        <header className="top-0 z-50 sm:sticky flex items-center px-4 h-16  shadow-md bg-white">
          <IconObj color="primary" />
          <p className="flex-1 pl-1">{title}</p>
          <div>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={saveQuestions}
              style={{ textTransform: "capitalize" }}
            >
              Save changes
            </Button>
            <IconButton onClick={closeView}>
              <Close />
            </IconButton>
          </div>
        </header>
      )}
      <div className="p-3">
        <Typography variant="body2">{sectionName}</Typography>
        <br />
        {children}
      </div>
      <Divider />
    </>
  );
};
export default FormSettings;
