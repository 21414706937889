import React from "react";

import { Bar } from "react-chartjs-2";
import { getRandomColors } from "../../files/util";

export default class HorizontalGraph extends React.Component {
  state = {
    data: {},
  };
  constructor(props) {
    super(props);
    let colors = getRandomColors(this.props.data.length);
    this.state = {
      colors: colors,
      data: {
        labels: this.props.labels,
        datasets: [
          {
            label: "",
            backgroundColor: colors,
            hoverBackgroundColor: colors,
            data: this.props.data,
          },
        ],
      },
    };
  }
  componentDidMount() {}
  render() {
    console.log(this.props);
    return (
      <div className={`max-h-80 w-80 ${this.props.isSmall ? "" : "flex"}`}>
        {this.props.data && (
          <>
            <Bar
              data={this.state.data}
              options={{
                indexAxis: "y",
                responsive: true,
                plugins: {
                  title: {
                    display: false,
                    text: this.props.title,
                    fontSize: 20,
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
            />
            <div
              className={`self-center ${this.props.isSmall ? "ml-20" : "ml-2"}`}
            >
              {this.state.colors &&
                this.props.labels.map((item, index) => (
                  <div key={item} className="flex items-center mb-1">
                    <div
                      className="w-8 h-3 mr-1"
                      style={{ backgroundColor: this.state.colors[index] }}
                    ></div>
                    <small>{item}</small>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    );
  }
}
