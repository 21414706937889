import { Box, Button, LinearProgress, Paper, styled } from "@material-ui/core";
import { Image } from "@material-ui/icons";
import React from "react";
import { Component } from "react";
import { useEffect } from "react";
import { UseStorage } from "../../hooks/useStorage";

export default function LocalImage({
  setImage,
  image,
  startUpload,
  contextData,
  updateImage,
}) {
  const handleImage = (event, isDrop) => {
    try {
      console.log(event);

      //if (!fileClick) dt = event.dataTransfer;
      //else
      let file;
      if (isDrop) file = event;
      else file = event.target.files[0];
      let fileName = file.name;
      var reader = new FileReader();
      let preview = document.getElementById("preview-img-upload");
      reader.onloadend = function () {
        preview.src = reader.result;
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        preview.src = "";
      }
      setImage({
        fileName: fileName.length > 20 ? fileName.substring(0, 20) : fileName,
        fileUrl: "",
        file,
        fileSize: file.size,
        timestamp: new Date().getTime(),
      });
    } catch (error) {
      console.log(error);
    }
  };
  console.log(image);

  return (
    <>
      <DragAndDrop handleDrop={handleImage} />
      <br />
      <>
        <div
          style={{
            visibility: `${image ? "visible" : "hidden"}`,
            display: "flex",
            // flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{
              border: "1px dotted #eae9e9",
              padding: 4,
              marginTop: 10,
              width: 200,
            }}
            id="preview-img-upload"
            alt={image?.fileName}
          />
          <p>{image?.fileName}</p>
        </div>
        {startUpload && (
          <FileUploaderToStorage
            file={image}
            context={contextData}
            setFile={updateImage}
          />
        )}
      </>
      <input
        hidden="hidden"
        id="file-custom-uploader"
        type="file"
        accept="image/png, image/gif, image/jpeg"
        onChange={handleImage}
      />
    </>
  );
}
export const FileUploaderToStorage = ({
  file,
  setFile,
  context,
  userInfo = null,
}) => {
  let { url, progress } = UseStorage(file, userInfo);
  console.log("progress", progress, file);
  useEffect(() => {
    if (url) {
      console.log("file uploaded", url);
      setFile(url, context);
    } else console.log("file not uploaded");
  }, [url]);
  if (file !== null && progress === 0) progress = 5;
  return (
    <>
      <Box sx={{ width: "100%", padding: 10 }}>
        <p>Uploading {file.fileName}</p>
        <BorderLinearProgress variant="determinate" value={progress} />
      </Box>
    </>
  );
};
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
}));

class DragAndDrop extends Component {
  state = {
    drag: false,
  };
  dropRef = React.createRef();
  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };
  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      console.log(e.dataTransfer);
      this.props.handleDrop(e.dataTransfer.files[0], true);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };
  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }
  render() {
    console.log(this.state);
    return (
      <Paper
        variant="outlined"
        onClick={() => document.getElementById("file-custom-uploader").click()}
        style={{
          borderRadius: 11,
          cursor: "pointer",
          borderStyle: "dashed",
          flexDirection: "column",
          background: "#f8f0f052",
          display: "flex",
          alignItems: "center",
          height: "60vh",
          width: "100%",
          justifyContent: "center",
        }}
        ref={this.dropRef}
      >
        {this.state.drag ? (
          <p>Drop here </p>
        ) : (
          <>
            <Image
              htmlColor="gray"
              style={{ fontSize: "14em", opacity: 0.16 }}
            />
            <div
              style={{
                color: "#797979",
                maxWidth: "16em",
                textAlign: "center",
              }}
            >
              <p>Drag and drop your image here </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <hr style={{ height: 1, width: "100%" }} />
                <p style={{ margin: 5 }}>or</p>
                <hr style={{ width: "100%", height: 1 }} />
              </div>
              <p>Click here to upload the image</p>
            </div>
          </>
        )}
      </Paper>
    );
  }
}
