import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { skipMarks } from "../../../files/Lists";
import FormResponseHeader from "./FormResponseHeader";

export const ShowAllQuestions = ({
  questions,
  formError,
  showPoints,
  defaultPoint,
  getUiType,
  submitResponse,
  formData,
  userId,
  userImage,
}) => {
  return (
    <div>
      <FormResponseHeader
        formData={formData}
        userId={userId}
        userImage={userImage}
      />
      <Grid>
        {questions.map((ques, i) => (
          <div key={ques.id}>
            <br></br>
            <Paper>
              <div
                style={formError[ques.id] ? { border: "1px red solid" } : {}}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginLeft: "6px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  <div className="flex justify-between w-full pr-2">
                    <Typography variant="subtitle1" className="pl-3">
                      {i + 1}. {ques.questionText}{" "}
                      {ques.required && <span className="text-red-500">*</span>}
                    </Typography>
                    {!ques.isDefault &&
                      showPoints &&
                      !skipMarks.includes(ques.type) && (
                        <Typography className="text-gray-700" variant="caption">
                          {showPoints[ques.id] && showPoints[ques.id].marks
                            ? showPoints[ques.id].marks
                            : defaultPoint}{" "}
                          points
                        </Typography>
                      )}
                  </div>

                  {ques.questionImage && ques.questionImage !== "" ? (
                    <div className="ml-3">
                      <img
                        src={ques.questionImage}
                        width="80%"
                        alt=""
                        height="auto"
                      />
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                  <br />
                  {getUiType(ques.type, ques.id, ques.options)}
                </div>
              </div>
            </Paper>
          </div>
        ))}
      </Grid>
      <Grid>
        <br></br>
        <div style={{ display: "flex" }}>
          <Button variant="contained" color="primary" onClick={submitResponse}>
            Submit
          </Button>
        </div>
        <br></br>

        <br></br>
      </Grid>
    </div>
  );
};
