import React from "react";
import formService from "../../../files/apis";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  withWidth,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import {
  ACCEPT_RESPONSE,
  FORM_END_DATE,
  FORM_START_DATE,
  TYPE_QUIZ,
} from "../../../files/Constants";
import {
  createCSVForAllResponse,
  deleteArrayElement,
  prepareFormResponse,
  sumMarks,
} from "../../../files/util";
import SummaryTab from "./SummaryTab";
import QuestionTab from "./QuestionTab";
import IndividualResponse from "./IndividualResponse";
import { TabPanel } from "../Form/EditForm";
import ErrorBoundary from "../../errors/ErrorBoundary";
import MenuBar from "../../MenuBar";
import { responseSummeryMainMenu } from "../../../files/Lists";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function ResponseTab(props) {
  const classes = useStyles();

  const [formData, setFormData] = React.useState({});
  const [formResponseData, setFormResponseData] = React.useState({});
  const [responseData, setResponseData] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [totalMarks, setTotalMarks] = React.useState(0);
  const [extraPropsForTab, setExtraPropsForTab] = React.useState("");

  const [forceUpdate, setForceUpdate] = React.useState(false);
  React.useEffect(() => {
    if (props.formData) {
      setQuestions(props.formData.questions);
      setFormData(props.formData);
      setTotalMarks(
        props.formData.configuration[TYPE_QUIZ]
          ? props.answerData
            ? sumMarks(props.answerData)
            : 0
          : 0
      );
    }
    var formId = props.formId;
    if (formId !== undefined && formId !== "") {
      formService.getResponse(formId).then(
        (data) => {
          setResponseData(data);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(resMessage);
        }
      );
    }
  }, [props.formId, props.formData]);
  React.useEffect(() => {
    prepareResponseData(responseData);
  }, [responseData]);
  const prepareResponseData = (responseData) => {
    let res = prepareFormResponse(questions, responseData);
    console.log("prepare for response", res, "resData", responseData);
    setFormResponseData(res);
  };
  const getFormStatus = () => {
    let configJson = props.formData.configuration;
    return configJson[ACCEPT_RESPONSE] &&
      configJson[FORM_START_DATE] &&
      new Date(configJson[FORM_START_DATE]).getTime() <= new Date().getTime() &&
      configJson[FORM_END_DATE] &&
      new Date(configJson[FORM_END_DATE]).getTime() >= new Date().getTime()
      ? "Active"
      : "Inactive";
  };
  console.log(
    "response tab state",
    "quest",
    questions,
    "response",
    responseData,
    props
  );
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleResponseDelete = (index) => {
    let response = responseData[index];
    let arr = deleteArrayElement(responseData, index);
    setResponseData(arr);
    setForceUpdate(!forceUpdate);
    formService.deleteResponse(response._id).then((res) => {
      if (!res) {
        // TODO show error
        responseData.push(response);
      }
    });
  };
  const openIndividualResponse = (resId) => {
    setExtraPropsForTab(resId);
    setTabValue(2);
  };
  const downloadResponseInCSV = () => {
    createCSVForAllResponse(
      questions,
      responseData,
      props.formData.configuration[TYPE_QUIZ]
    );
  };
  const printResponse = () => {
    console.log("menu item clicked");
  };
  const deleteResponse = () => {
    console.log("menu item clicked");
    formService.deleteAllResponse(props.formId).then((res) => {
      if (res) {
        console.log("Response deleted...");
        setResponseData([]);
      }
    });
  };
  return (
    <>
      <Grid item style={{ width: "100%", textAlign: "initial" }}>
        <Paper variant="outlined" className="rounded-paper form-detail">
          <div className={`flex justify-between p-2`}>
            <div className="text-center">
              <Typography variant="h6">{responseData.length}</Typography>
              <p>Responses</p>
            </div>
            <div className="text-center">
              <Typography variant="h6">{getFormStatus()}</Typography>
              <p>Status</p>
            </div>
            {responseData.length > 0 && (
              <div className="flex text-center items-center">
                <img className="w-5 h-5 mr-3" src="/excel.svg" />
                <MenuBar
                  width={props.width}
                  menuList={responseSummeryMainMenu}
                  actionList={[
                    () => downloadResponseInCSV(),
                    () => printResponse(),
                    () => null,
                    () => deleteResponse(),
                  ]}
                />
              </div>
            )}
          </div>
          {responseData.length > 0 && (
            <Tabs
              value={tabValue}
              className="w-full"
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab style={{ textTransform: "capitalize" }} label="Summery" />
              <Tab style={{ textTransform: "capitalize" }} label="Question" />
              <Tab style={{ textTransform: "capitalize" }} label="Individual" />
            </Tabs>
          )}
        </Paper>

        {responseData.length > 0 ? (
          <>
            <TabPanel value={tabValue} index={0}>
              <SummaryTab
                width={props.width}
                isQuiz={props.formData.configuration[TYPE_QUIZ]}
                questions={questions}
                responses={responseData}
                totalMarks={totalMarks}
                formId={props.formId}
                formResponseData={formResponseData}
                openIndividualResponse={openIndividualResponse}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <ErrorBoundary>
                <QuestionTab
                  answerData={props.answerData}
                  isQuiz={props["formData"]?.configuration[TYPE_QUIZ]}
                  questions={questions}
                  responses={responseData}
                />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <ErrorBoundary>
                <IndividualResponse
                  questions={questions}
                  responses={responseData}
                  answerData={props.answerData}
                  isQuiz={props["formData"]?.configuration[TYPE_QUIZ]}
                  handleResponseDelete={handleResponseDelete}
                  extraProps={extraPropsForTab}
                />
              </ErrorBoundary>
            </TabPanel>
          </>
        ) : (
          <>
            <br />
            <Alert severity="info">No Responses received</Alert>
          </>
        )}
      </Grid>
      {/* <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              {questions.map((ques, i) => (
                <TableCell key={i} align="right">
                  {ques.questionText}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {responseData &&
              responseData.map((rs, j) => (
                <TableRow key={j}>
                  <TableCell component="th" scope="row">
                    {rs.userId}
                  </TableCell>
                  {questions.map((ques, index) => (
                    <TableCell key={index} align="right">
                      {getSelectedOption(ques.id, index, j)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    */}
    </>
  );
}
export default withWidth()(ResponseTab);
