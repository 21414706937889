import { IconButton, TextField } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import React from "react";
import { generateUniqueId } from "../files/util";
import { deleteArrayElement } from "../util/helper";

export default class InfiniteTextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      arrayOfDivs: [],
      divID: 1,
    };
  }

  handleChange = (event, id, index) => {
    this.setState((prevState) => {
      let newArrOfDivs = prevState.arrayOfDivs;
      newArrOfDivs.find((item) => item.id === id)["value"] = event.target.value;
      return { arrayOfDivs: newArrOfDivs };
    });

    this.props.fields[index] = event.target.value;
  };
  componentDidMount() {
    let inputs = [];
    if (this.props.fields) {
      console.log(this.props.fields);
      this.props.fields.forEach((item, index) => {
        inputs.push({ id: generateUniqueId(), value: item });
      });
    }
    this.setState((prevState) => {
      return {
        arrayOfDivs: [
          ...prevState.arrayOfDivs,
          ...inputs,
          // { value: "", id: generateUniqueId() },
        ],
      };
    });
    this.setState((prevState) => {
      return { divID: prevState.divID + inputs.length + 1 };
    });
  }
  removeElement = (e, id, index) => {
    this.setState((prevState) => {
      let newArr = prevState.arrayOfDivs.filter((item) => item.id !== id);
      return { arrayOfDivs: newArr };
    });
    deleteArrayElement(this.props.fields, index);
  };
  addElement = () => {
    this.setState((prevState) => {
      return {
        arrayOfDivs: [
          ...prevState.arrayOfDivs,
          { value: "", id: prevState.divID },
        ],
      };
    });
    this.setState((prevState) => {
      return { divID: prevState.divID + 1 };
    });
    this.props.fields.push("");
  };
  getTextType = () => {
    if (this.props.type === "date") return "date";
    else if (this.props.type === "time") return "time";
    else return "text";
  };
  render() {
    console.log(this.state, this.props);
    return (
      <div className="flex flex-col w-full">
        {this.state.arrayOfDivs.map((item, index) => (
          <div className="flex m-1" key={item.id}>
            <TextField
              variant="standard"
              onChange={(e) => this.handleChange(e, item.id, index)}
              value={item.value}
              type={this.getTextType()}
            />
            <IconButton
              onClick={(e) => this.removeElement(e, item.id, index)}
              size="small"
            >
              <Clear />
            </IconButton>
          </div>
        ))}
        <TextField
          variant="standard"
          value="Add answer"
          disabled
          onClick={this.addElement}
        />
      </div>
    );
  }
}
