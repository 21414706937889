import {
  Button,
  CardContent,
  Divider,
  Fab,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { skipMarks } from "../../../files/Lists";
import FormResponseHeader from "./FormResponseHeader";

export const ShowOneQuestion = ({
  questions,
  formError,
  showPoints,
  defaultPoint,
  getUiType,
  submitResponse,
  formData,
  userId,
  userImage,
  isSmall,
  responseData,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showQuestions, setShowQuestions] = useState(isSmall ? false : true);
  const goPrevious = () => {
    scrollToQuestion(selectedIndex - 1);
  };
  const goNext = () => {
    scrollToQuestion(selectedIndex + 1);
  };
  const scrollToQuestion = (index) => {
    setSelectedIndex(index);
    document.getElementById("question-single-item").scrollIntoView();
  };
  return (
    <>
      <div className="flex w-full">
        {showQuestions && (
          <Grid
            item
            xs={isSmall ? 12 : 12}
            sm={isSmall ? 12 : 2}
            lg={isSmall ? 12 : 2}
            style={{
              marginLeft: "15px",
              position: "fixed",
              height: "80%",
              zIndex: 10,
            }}
          >
            <Paper
              variant="outlined"
              className={`h-full ${isSmall ? "m-2" : ""}`}
            >
              <p style={{ padding: 10, background: "whitesmoke" }}>Questions</p>
              <Divider />

              <CardContent>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {questions.map((item, index) => (
                    <Fab
                      size="medium"
                      color="primary"
                      onClick={() => scrollToQuestion(index)}
                      key={index}
                      className={`response-number-item ${
                        formError && formError[item.id]
                          ? "response-number-error"
                          : selectedIndex === index
                          ? "response-number-current"
                          : responseData[item.id]
                          ? "response-number-visited"
                          : "response-number-not-visited"
                      }`}
                    >
                      {index + 1}
                    </Fab>
                  ))}
                </div>
              </CardContent>
              {isSmall && (
                <div style={{ position: "absolute", bottom: 0 }}>
                  <Button
                    style={
                      isSmall ? { fontSize: 10, margin: 10 } : { margin: 10 }
                    }
                    color="primary"
                    variant="contained"
                    onClick={() => setShowQuestions(false)}
                  >
                    Close
                  </Button>
                </div>
              )}
            </Paper>
          </Grid>
        )}
        {<Grid item sm={3} lg={3}></Grid>}
        <Grid
          item
          xs={isSmall ? 12 : 12}
          sm={isSmall ? 12 : 9}
          lg={isSmall ? 12 : 8}
          xl={isSmall ? 12 : 8}
          style={{ margin: 10 }}
        >
          <div>
            <FormResponseHeader
              formData={formData}
              userId={userId}
              userImage={userImage}
            />

            <Grid>
              <div id="question-single-item" key={questions[selectedIndex].id}>
                <br></br>
                <Paper>
                  <div
                    style={
                      formError[questions[selectedIndex].id]
                        ? { border: "1px red solid" }
                        : {}
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginLeft: "6px",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                      }}
                    >
                      <div className="flex justify-between w-full pr-2">
                        <Typography variant="subtitle1" className="pl-3">
                          {selectedIndex + 1}.{" "}
                          {questions[selectedIndex].questionText}{" "}
                          {questions[selectedIndex].required && (
                            <span className="text-red-500">*</span>
                          )}
                        </Typography>
                        {!questions[selectedIndex].isDefault &&
                          showPoints &&
                          !skipMarks.includes(
                            questions[selectedIndex].type
                          ) && (
                            <Typography
                              className="text-gray-700"
                              variant="caption"
                            >
                              {showPoints[questions[selectedIndex].id] &&
                              showPoints[questions[selectedIndex].id].marks
                                ? showPoints[questions[selectedIndex].id].marks
                                : defaultPoint}{" "}
                              points
                            </Typography>
                          )}
                      </div>

                      {questions[selectedIndex].questionImage &&
                      questions[selectedIndex].questionImage !== "" ? (
                        <div className="ml-3">
                          <img
                            src={questions[selectedIndex].questionImage}
                            width="80%"
                            alt=""
                            height="auto"
                          />
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                      <br />
                      {getUiType(
                        questions[selectedIndex].type,
                        questions[selectedIndex].id,
                        questions[selectedIndex].options
                      )}
                    </div>
                  </div>
                </Paper>
              </div>
              <br />
              <div className="flex justify-between">
                <Button
                  style={
                    isSmall
                      ? { fontSize: 10, height: "fit-content" }
                      : { height: "fit-content" }
                  }
                  disabled={selectedIndex <= 0}
                  color="primary"
                  variant="contained"
                  onClick={goPrevious}
                >
                  Previous
                </Button>
                {isSmall ? (
                  <div className="flex flex-col">
                    <Button
                      style={isSmall ? { fontSize: 10 } : null}
                      color="primary"
                      variant="contained"
                      onClick={() => setShowQuestions(true)}
                    >
                      Show Questions
                    </Button>
                    <Button
                      style={
                        isSmall
                          ? { fontSize: 10, marginTop: 10 }
                          : { marginTop: 10 }
                      }
                      color="primary"
                      variant="contained"
                      onClick={submitResponse}
                    >
                      Submit Form
                    </Button>
                  </div>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={formError && Object.keys(formError).length > 0}
                    onClick={submitResponse}
                  >
                    Submit Form
                  </Button>
                )}
                <Button
                  style={
                    isSmall
                      ? { fontSize: 10, height: "fit-content" }
                      : { height: "fit-content" }
                  }
                  disabled={selectedIndex >= questions.length - 1}
                  color="primary"
                  variant="contained"
                  onClick={goNext}
                >
                  Next
                </Button>
              </div>
              <br />
            </Grid>
          </div>
        </Grid>
        {/* {<Grid item sm={1} lg={1}></Grid>} */}
      </div>
      <br />
      <br />
      <br />
    </>
  );
};
