import { Checkbox, IconButton, TextField, Typography } from "@material-ui/core";
import { Divider, FormControlLabel } from "@material-ui/core";
import React from "react";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import Close from "@material-ui/icons/Close";

export default function PassageView({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  edit,
  removeOption,
  questionViewData,
}) {
  return (
    <React.Fragment>
      {edit && edit === "edit" ? (
        <EditView
          optionData={optionData}
          handleOptionValue={handleOptionValue}
          uploadImage={uploadImage}
          questionIndex={questionIndex}
          optionIndex={optionIndex}
          removeOption={removeOption}
        />
      ) : (
        <QuestionView {...questionViewData} />
      )}
    </React.Fragment>
  );
}

const EditView = ({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  removeOption,
}) => {
  const handleValue = (e) => {
    handleOptionValue(
      { ...optionData, optionText: e.target.value },
      questionIndex,
      optionIndex
    );
  };
  return (
    <div
      style={{
        flexDirection: "row",
        marginLeft: "-12.5px",
        justifyContent: "space-between",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
    >
      <div>
        <TextField
          fullWidth={true}
          maxRows={8}
          multiline
          minRows={5}
          variant="outlined"
          label="Passage"
          style={{ marginTop: "5px" }}
          value={optionData.optionText}
          onChange={handleValue}
        />
      </div>
      <div>
        <IconButton
          aria-label="upload image"
          onClick={() => {
            uploadImage(questionIndex, optionIndex);
          }}
        >
          <CropOriginalIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => {
            removeOption(questionIndex, optionIndex);
          }}
        >
          <Close />
        </IconButton>
      </div>
    </div>
  );
};

const QuestionView = ({ questionId, responseData, options, actionMethod }) => {
  return (
    <>
      {options.map((option, index) => (
        <div key={option.id} className="mb-8">
          <div
            style={{
              display: "flex",
              marginLeft: 12,
              marginRight: 12,
            }}
          >
            <Typography>{option.optionText}</Typography>
          </div>

          <div
            style={{
              display: "flex",
              marginLeft: "10px",
            }}
          >
            {option.optionImage !== "" ? (
              <img src={option.optionImage} width="64%" height="auto" />
            ) : (
              ""
            )}
            <Divider />
          </div>
        </div>
      ))}
    </>
  );
};
