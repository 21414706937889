import {
  Button,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { Delete, Print } from "@material-ui/icons";
import dayjs from "dayjs";
import React, { Component } from "react";
import { getFormComponent } from "../../../files/util";
import ResponseTabHeader from "./ResponseTabHeader";
import ConfirmationDialog from "../../ConfirmationDialog";
import ErrorBoundary from "../../errors/ErrorBoundary";
import { UserAnswerView } from "../Form/CreateFormUtilComponents";
import { isNumber } from "../../../util/helper";
import formService from "../../../files/apis";

class IndividualResponse extends Component {
  constructor(props) {
    super(props);
    let index = -1;
    if (props.extraProps) {
      index = props.responses.findIndex(
        (item) => item.userId === props.extraProps
      );

      console.log(index);
    }
    this.state = {
      confirmationDialogData: null,
      selectedResponseIndex: index > -1 ? index : 0,
    };
    console.log("extra props", props.extraProps, this.state);
  }
  handleResponseChange = (pageNumber) => {
    this.setState({ selectedResponseIndex: pageNumber });
  };
  getUiType(type, questionId, options, responses, selectedResponseIndex) {
    return getFormComponent(type, "question", null, {
      questionId: questionId,
      options: options,
      actionMethod: null,
      responseData: responses[selectedResponseIndex].response,
    });
  }
  handlePrint = () => {
    try {
      document.getElementsByTagName("header")[0].style = "display:none";
      document.getElementById("form-main").style = "padding-top:0";
      for (let item of document.getElementsByClassName("form-detail")) {
        item.style = "display:none";
      }

      window.print();
      document.getElementsByTagName("header")[0].style = "display:block";
      document.getElementById("form-main").style = `padding-top: 10em;
      padding-bottom: 30px;
      text-align: -webkit-center;`;
      for (let item of document.getElementsByClassName("form-detail")) {
        item.style = "display:''";
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleResponseDelete = () => {
    let dialogProps = {
      title: "Delete Response",
      primaryButtonText: "Delete",
      primaryButtonColor: "red",
      primaryAction: () => {
        this.props.handleResponseDelete(this.state.selectedResponseIndex);
        this.setState({ ...this.state, confirmationDialogData: null });
      },
      message: "Are you sure you want to delete this response",
      secondButtonText: "Cancel",
      secondAction: () =>
        this.setState({ ...this.state, confirmationDialogData: null }),
    };
    this.setState({ ...this.state, confirmationDialogData: dialogProps });
  };
  getScoreUI = () => {
    let res = this.props.responses[this.state.selectedResponseIndex];
    return (
      <>
        <Typography className="font-bold">
          {res.score && (
            <span>
              {" "}
              {res.score.marks} of {res.score.totalMarks} points
            </span>
          )}
        </Typography>
        {/* <Typography className="font-bold"> */}
        {!res.score ? (
          <span>Score not released</span>
        ) : (
          <div>
            <p>Score Released</p>
            <small>
              {dayjs(parseInt(res.score.scoreReleaseDate)).format(
                "MMM DD YYYY HH:mm"
              )}
            </small>
          </div>
        )}
        {/* </Typography> */}
      </>
    );
  };
  evaluateScore = () => {
    // console.log(this.props.responses[this.state.selectedResponseIndex]);
    try {
      let response = this.props.responses[this.state.selectedResponseIndex];
      formService.evaluateScore(response.formId, response._id);
    } catch (error) {
      console.error(error);
    }
  };
  getOptionUI = (answerData, i, isQuiz, ques, responses) => {
    console.log("Response", responses);
    if (!responses[this.state.selectedResponseIndex].response[ques.id]) {
      return <Paper>Question not found in this response</Paper>;
    } else if (isQuiz && responses[this.state.selectedResponseIndex].score)
      return (
        <>
          <AnswerView
            answerData={answerData}
            i={i}
            isQuiz={isQuiz}
            showAnswer={!Boolean(this.props.notShowCorrectAnswer)}
            ques={ques}
            responses={responses}
            selectedResponseIndex={this.state.selectedResponseIndex}
          />
        </>
      );
    else
      return (
        <QuestionView
          i={i}
          ques={ques}
          getUiType={this.getUiType}
          responses={responses}
          selectedResponseIndex={this.state.selectedResponseIndex}
        />
      );
  };
  render() {
    console.log(this.props);

    const {
      questions,
      responses,
      isQuiz,
      answerData,
      isUserView,
      notShowCorrectAnswer,
    } = this.props;
    return (
      <>
        {responses && responses.length > 0 && (
          <div id="response-section">
            {!isUserView && (
              <ErrorBoundary
                defaultMessage={"Error occurred in ResponseTabHeader"}
              >
                <ResponseTabHeader
                  DropdownTextArr={responses.map((item) => item.userId)}
                  handleChange={this.handleResponseChange}
                  selectedIndex={this.state.selectedResponseIndex}
                />
              </ErrorBoundary>
            )}
            <ErrorBoundary defaultMessage={"Error occurred in Paper"}>
              <Paper variant="outlined" className="mt-4">
                <div className="flex justify-between mr-3">
                  <p className="p-4">Summery</p>
                  {!isQuiz && (
                    <p className="text-gray-400  italic self-center">
                      {dayjs(
                        isNumber(
                          responses[this.state.selectedResponseIndex].timeStamp
                        )
                          ? responses[this.state.selectedResponseIndex]
                              .timeStamp
                          : responses[
                              this.state.selectedResponseIndex
                            ].timeStamp.toMillis()
                      ).format("MMM DD YYYY HH:mm")}
                    </p>
                  )}
                </div>

                <Divider />
                {isQuiz && (
                  <>
                    <div className="flex justify-between p-4">
                      {this.getScoreUI()}
                      <p className="text-gray-400  italic">
                        <ErrorBoundary>
                          {dayjs(
                            isNumber(
                              responses[this.state.selectedResponseIndex]
                                .timeStamp
                            )
                              ? responses[this.state.selectedResponseIndex]
                                  .timeStamp
                              : responses[
                                  this.state.selectedResponseIndex
                                ].timeStamp.toMillis()
                          ).format("MMM DD YYYY HH:mm")}
                        </ErrorBoundary>
                      </p>
                    </div>
                  </>
                )}
                <Divider />
                {!isUserView && (
                  <CardContent>
                    <div className="flex justify-between form-detail">
                      <div>
                        <IconButton onClick={this.handlePrint}>
                          <Print />
                        </IconButton>
                        <IconButton onClick={this.handleResponseDelete}>
                          <Delete />
                        </IconButton>
                      </div>
                      {isQuiz && (
                        <Button
                          style={{ textTransform: "capitalize" }}
                          variant="outlined"
                          color="primary"
                          onClick={this.evaluateScore}
                        >
                          {this.props.responses[
                            this.state.selectedResponseIndex
                          ].score
                            ? "Reevaluate score"
                            : "Release score"}
                        </Button>
                      )}
                    </div>
                  </CardContent>
                )}
              </Paper>
            </ErrorBoundary>
            <Grid>
              {questions.map((ques, i) => (
                <div key={ques.id}>
                  <br></br>
                  {this.getOptionUI(answerData, i, isQuiz, ques, responses)}
                </div>
              ))}
            </Grid>
          </div>
        )}
        {this.state.confirmationDialogData && (
          <ConfirmationDialog
            open={true}
            dialogProps={this.state.confirmationDialogData}
          />
        )}
      </>
    );
  }
}
const QuestionView = ({
  ques,
  i,
  getUiType,
  responses,
  selectedResponseIndex,
}) => {
  return (
    <Paper>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: "6px",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <Typography variant="subtitle1" style={{ marginLeft: "10px" }}>
            {i + 1}. {ques.questionText}{" "}
            {ques.required && <span className="text-red-500">*</span>}
          </Typography>

          {ques.questionImage !== "" ? (
            <div>
              <img src={ques.questionImage} width="80%" height="auto" />
              <br />
            </div>
          ) : (
            ""
          )}
          <ErrorBoundary>
            {getUiType(
              ques.type,
              ques.id,
              ques.options,
              responses,
              selectedResponseIndex
            )}
          </ErrorBoundary>
        </div>
      </div>
    </Paper>
  );
};
export const AnswerView = ({
  ques,
  responses,
  answerData,
  selectedResponseIndex,
  from,
  showAnswer,
}) => {
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: "6px",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <ErrorBoundary defaultMessage="Error in UI">
            <br />
            <br />
            <UserAnswerView
              ques={ques}
              responseStats={responses[selectedResponseIndex].score.stats}
              questionAnswer={answerData[ques.id]}
              selectedAnswer={
                responses[selectedResponseIndex].response[ques.id]
                  ? responses[selectedResponseIndex].response[ques.id].split(
                      ","
                    )
                  : []
              }
              showAnswer={showAnswer}
            />
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};
export default IndividualResponse;
