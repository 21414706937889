import { IconButton, TextField } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import React from "react";
import { isNumber } from "../../../files/util";
import Icon from "@material-tailwind/react/Icon";
import { Rating } from "@material-ui/lab";
export default function FormRating({
  optionData,
  handleOptionValue,
  uploadImage,
  questionIndex,
  optionIndex,
  edit,
  removeOption,
  questionViewData,
}) {
  return (
    <React.Fragment>
      {edit && edit === "edit" ? (
        <EditView
          optionData={optionData}
          handleOptionValue={handleOptionValue}
          questionIndex={questionIndex}
          optionIndex={optionIndex}
          removeOption={removeOption}
        />
      ) : (
        <QuestionView {...questionViewData} />
      )}
    </React.Fragment>
  );
}

const EditView = ({
  optionData,
  handleOptionValue,
  questionIndex,
  optionIndex,
  removeOption,
}) => {
  // const [stars, setStars] = React.useState(0);
  const handleTextValue = (e) => {
    handleOptionValue(
      {
        ...optionData,
        maxNumber: optionData.maxNumber,
        optionText: e.target.value,
      },
      questionIndex,
      optionIndex
    );
  };
  const handleRatingValue = (e) => {
    // setStars(e.target.value);
    console.log("stars", e.target.value);
    handleOptionValue(
      {
        maxNumber: isNumber(e.target.value) ? parseInt(e.target.value) : "",
        optionText: optionData.optionText,
      },
      questionIndex,
      optionIndex
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginLeft: "-12.5px",
        justifyContent: "space-between",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
    >
      <div>
        <TextField
          fullWidth={true}
          variant="outlined"
          label="Option text"
          style={{ marginTop: "5px" }}
          value={optionData.optionText}
          onChange={handleTextValue}
        />
        <TextField
          fullWidth={true}
          variant="outlined"
          label="Number of stars"
          type="number"
          style={{ marginTop: "5px" }}
          value={optionData.maxNumber}
          onChange={handleRatingValue}
        />
      </div>
      <IconButton
        aria-label="delete"
        onClick={() => {
          removeOption(questionIndex, optionIndex);
        }}
      >
        <Close />
      </IconButton>
    </div>
  );
};

const QuestionView = ({
  questionId,
  responseData,
  options,
  removeError,
  actionMethod,
}) => {
  const handleStarClick = (e, newValue) => {
    actionMethod(questionId, newValue + " stars");
    removeError(questionId);
  };
  return (
    <>
      {options.map((option, index) => (
        <div
          key={option.id}
          style={{
            display: "flex",
            marginLeft: "7px",
          }}
        >
          <Rating
            name="half-rating"
            precision={0.5}
            disabled={actionMethod === null}
            defaultValue={responseData[questionId]}
            max={option.maxNumber}
            onChange={handleStarClick}
            size="large"
          />
        </div>
      ))}
    </>
  );
};
