import {
  Divider,
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Paper,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core";
import React from "react";
import CreateNew from "../../CreateNew";
import ContentCopyIcon from "@material-ui/icons/FilterNone";
import MoreIcon from "@material-ui/icons/MoreVertOutlined";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {
  Computer,
  Delete,
  KeyboardBackspace,
  PhoneAndroid,
  PostAdd,
  Print,
  RestorePage,
} from "@material-ui/icons";
import { TabPanel } from "./EditForm";
import UserView from "../Responding/UserView";
import services from "../../../files/apis";
import { fileTypes } from "../../../files/Lists";
import { findObj } from "../../../files/util";
export const PreviewForm = ({
  user,
  formDetails,
  formID,
  setSideView,
  userimage,
  width,
}) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <header className="top-0 z-50 sm:sticky flex items-center px-4 shadow-md bg-white">
        <div className="">
          <Button
            onClick={() => setSideView(null)}
            style={{ textTransform: "capitalize" }}
            variant="text"
            color="primary"
            startIcon={<KeyboardBackspace />}
          >
            Back
          </Button>
        </div>
        {width !== "xs" && (
          <Tabs
            value={value}
            className="w-full"
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab
              style={{ textTransform: "capitalize", display: "flex" }}
              label="Desktop"
              className="horizontal-tab-icon"
              icon={
                <Computer
                  style={{ display: "inline-block", marginRight: "10px" }}
                />
              }
            />
            <Tab
              style={{ textTransform: "capitalize" }}
              label="Mobile"
              icon={
                <PhoneAndroid
                  style={{ display: "inline-block", marginRight: "10px" }}
                />
              }
            />
          </Tabs>
        )}
      </header>
      {width !== "xs" ? (
        <>
          <TabPanel value={value} index={0}>
            <UserView
              user={user}
              formId={formID}
              userimage={userimage}
              formProps={formDetails}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div>
              <div
                style={{
                  textAlign: "-webkit-center",
                  background: "aliceblue",
                }}
              >
                <div
                  style={{
                    width: 396,
                    height: 829,
                    overflow: "hidden",
                    textAlign: "start",
                    backgroundImage:
                      "url('https://firebasestorage.googleapis.com/v0/b/meta-doc.appspot.com/o/assets%2Fphoneview.svg?alt=media&token=fa168ea3-ed81-4719-a355-9acc1605ab98')",
                  }}
                >
                  <div
                    style={{
                      width: 360,
                      height: 640,
                      overflow: "auto",
                      textAlign: "start",
                      marginLeft: 26,
                      marginTop: 100,

                      marginBottom: 143,
                    }}
                  >
                    <UserView
                      user={user}
                      formId={formID}
                      forceSmall
                      userimage={userimage}
                      formProps={formDetails}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </>
      ) : (
        <UserView
          user={user}
          formId={formID}
          userimage={userimage}
          formProps={formDetails}
        />
      )}
    </>
  );
};

export function MenuView({ user, formId }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [openCreateFormModel, setOpenCreateFormModel] = React.useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    try {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
    } catch (error) {}

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const copyForm = () => {
    services.copyForm(user, formId).then((doc) => {
      console.log("Copy form", doc);
      if (doc && doc.id) {
        window.open(window.location.origin + "/" + doc.id);
        // if (urlPath) router(`/${urlPath}/${doc.id}`);
      }
    });

    handleClose();
  };
  return (
    <>
      <Tooltip title="More options">
        <IconButton
          ref={anchorRef}
          edge="end"
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 1 }}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <RestorePage />
                    </ListItemIcon>
                    <ListItemText>Settings to default</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={copyForm}>
                    <ListItemIcon>
                      <ContentCopyIcon />
                    </ListItemIcon>
                    <ListItemText>Copy form</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <Delete />
                    </ListItemIcon>
                    <ListItemText>Delete form</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <Print />
                    </ListItemIcon>
                    <ListItemText>Print form</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => setOpenCreateFormModel(!openCreateFormModel)}
                  >
                    <ListItemIcon>
                      <PostAdd />
                    </ListItemIcon>
                    <ListItemText>New Form</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <CreateNew
        dbPath={fileTypes[findObj("form", fileTypes, "path")].dbPath}
        setShowModel={setOpenCreateFormModel}
        showModel={openCreateFormModel}
        user={user}
      />
    </>
  );
}
