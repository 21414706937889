import React from "react";
import { useState } from "react";

import { Line } from "react-chartjs-2";
import { getRandomColors, groupBy } from "../../files/util";
import ErrorBoundary from "../errors/ErrorBoundary";

export default function LineGraph({ data, title, isSmall }) {
  const [graphData, setGraphData] = useState(null);
  React.useEffect(() => {
    let colors = getRandomColors(data.length);
    let dateJson = groupBy(data);
    let dates = Object.keys(dateJson);
    let count = Object.values(dateJson);
    let temp = {
      labels: dates,
      datasets: [
        {
          label: "No. of records",
          data: count,

          backgroundColor: colors,
          hoverBackgroundColor: colors,

          fill: false,
          borderColor: colors,
          tension: 0.1,
        },
      ],
    };
    console.log("dateJson", dateJson, dates, count, colors, temp, title);
    setGraphData(temp);
  }, [data]);

  return (
    <>
      {graphData && (
        <div className="max-h-80 w-80">
          <ErrorBoundary>
            <Line
              data={graphData}
              width="200"
              height="100"
              options={{
                plugins: {
                  title: {
                    display: false,
                    text: title,
                    fontSize: 20,
                  },
                  legend: {
                    display: false,
                    position: isSmall ? "bottom" : "right",
                  },
                },
              }}
            />
          </ErrorBoundary>
        </div>
      )}
    </>
  );
}
