import dayjs from "dayjs";
import { auth, db, firestore } from "../firebase";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "../store/actiontypes";
import store from "../store/store";
import { URLS, URL_PREFIX } from "../util/constants";
import { generateUniqueId } from "./util";
// eslint-disable-next-line
export default {
  async createDocs(userid, name) {
    return await this.createForm({
      createdBy: userid,
      name: name,
      description: "",
      fileName: name,
    });
  },
  async copyForm(userid, formId) {
    //first fetch the old form
    return await this.getForm(formId).then((data) => {
      if (data.status === 200) {
        delete data["status"];
        delete data["_id"];
        return this.createForm({
          ...data,
          createdBy: userid,
        });
      }
    });
  },
  async getForms(userId) {
    return await db
      .collection("form")
      .doc("forms")
      .collection("allforms")
      .where("userid", "==", userId)
      .orderBy("createdAt", "desc")
      .get()
      .then((doc) => {
        let arr = [];
        if (!doc.empty) {
          doc.forEach((item) => {
            arr.push({ ...item.data(), _id: item.id });
          });
        }
        return arr;
      });
  },

  async createForm(data) {
    console.log(data);
    return await db
      .collection("form")
      .doc("forms")
      .collection("allforms")
      .add({
        ...data,
        timeStamp: firestore.FieldValue.serverTimestamp(),
      })
      .then((res) => {
        console.log("Create from", res);
        dispatchMessage("Form created successfully", true);

        return res;
      });
  },

  async getForm(formId) {
    return await db
      .collection("form")
      .doc("forms")
      .collection("allforms")
      .doc(formId)
      .get()
      .then((doc) => {
        console.log((doc, doc.data()));
        if (doc.exists) return { _id: doc.id, ...doc.data(), status: 200 };
        else return { status: 404 };
      });
  },
  async getAnswer(formId) {
    return await db
      .collection("form")
      .doc("forms")
      .collection("answers")
      .doc(formId)
      .get()
      .then((doc) => {
        console.log((doc, doc.data()));
        if (doc.exists) return { _id: doc.id, data: doc.data(), status: 200 };
        else return { status: 404 };
      });
  },
  async autoSave(data, answer) {
    console.log(data);
    if (data.formId) {
      let id = data.formId;
      delete data["formId"];
      return await db
        .collection("form")
        .doc("forms")
        .collection("allforms")
        .doc(id)
        .update(data)
        .then(() => {
          if (answer) {
            db.collection("form")
              .doc("forms")
              .collection("answers")
              .doc(id)
              .set(answer)
              .then(() => {})
              .catch((err) => {
                console.log("Answer store failed", err);
              });
          }
          dispatchMessage("Form changes saved  successfully", true);
          return { questions: data.questions };
        })
        .catch((err) => {
          console.log(err);
          dispatchMessage("Saving changes failed", false);

          return null;
        });
    } else return null;
  },

  async submitResponse(data, responseId, successFunction) {
    console.log(data);
    let dbRef = db.collection("form").doc("forms").collection("responses");
    if (!responseId)
      dbRef = dbRef.add({
        ...data,
        timeStamp: firestore.FieldValue.serverTimestamp(),
      });
    else
      dbRef = dbRef.doc(responseId).update({
        ...data,
        timeStamp: firestore.FieldValue.serverTimestamp(),
      });
    if (data.formId) {
      return await dbRef
        .then(() => {
          data["timeStamp"] = new Date().getTime();
          data["_id"] = generateUniqueId();
          successFunction(data);
          dispatchMessage("Response added successfully", true);

          return { questions: data.questions };
        })
        .catch((err) => {
          console.log(err);
          dispatchMessage("Failed to add the response", false);

          return null;
        });
    } else {
      dispatchMessage("Failed to add the response", false);
      return null;
    }
  },

  async getResponse(formId) {
    //  console.log(formId);
    return await db
      .collection("form")
      .doc("forms")
      .collection("responses")
      .where("formId", "==", formId)
      .orderBy("timeStamp", "desc")
      .get()
      .then((doc) => {
        let arr = [];
        if (!doc.empty) {
          doc.forEach((item) => {
            arr.push({ ...item.data(), _id: item.id });
          });
        }
        return arr;
      });
  },
  async getUserResponse(formId, userId) {
    console.log("fetching user response", formId, userId);
    return await db
      .collection("form")
      .doc("forms")
      .collection("responses")
      .where("formId", "==", formId)
      .where("userId", "==", userId)
      .get()
      .then((doc) => {
        let arr = [];
        if (!doc.empty) {
          doc.forEach((item) => {
            arr.push({ ...item.data(), _id: item.id });
          });
        } else return null;
        return arr[0];
      });
  },
  async uploadImage(data) {
    return {};
  },
  async deleteResponse(responseId) {
    return await db
      .collection("form")
      .doc("forms")
      .collection("responses")
      .doc(responseId)
      .delete()
      .then((doc) => {
        dispatchMessage("Response deleted successfully", false);

        return { status: 200 };
      })
      .catch((err) => {
        dispatchMessage("Failed to delete the response", false);

        return null;
      });
  },
  async deleteAllResponse(formId) {
    return await db
      .collection("form")
      .doc("forms")
      .collection("responses")
      .where("formId", "==", formId)
      .get()
      .then((doc) => {
        if (!doc.empty) {
          doc.forEach((item) => {
            item.ref.delete();
          });
          dispatchMessage("Response deleted successfully", false);
        } else {
          dispatchMessage("Response deletion failed", false);

          return null;
        }

        return [];
      });
  },
  async deleteSpecificQuestionResponse(formId, quesId) {
    return await db
      .collection("form")
      .doc("forms")
      .collection("responses")
      .where("formId", "==", formId)
      .get()
      .then((doc) => {
        if (!doc.empty) {
          doc.forEach((item) => {
            item.ref.update({
              [quesId]: null,
            });
          });
        } else {
          dispatchMessage("Question deletion failed", false);
          return null;
        }
        dispatchMessage("Question deleted successfully", true);

        return [];
      });
  },

  async evaluateScore(formId, responseId) {
    console.log("fetching token");
    let token = await auth.currentUser.getIdToken();
    console.log("token found", token);

    if (!token) return { error: "No token found" };
    try {
      return await fetch(URL_PREFIX + URLS.ScoreEvaluation, {
        method: "post",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          formId,
          responseId,
        }),
      })
        .then((res) => {
          if (res.status !== 200) {
            dispatchMessage("Score evaluation failed", false);
            return { success: false };
          } else
            dispatchMessage(
              "Score evaluation initiated successfully. Refresh the page after some time to see the status",
              true
            );

          return { success: true };
        })
        .catch((error) => {
          dispatchMessage("Score evaluation failed", false);

          return { error: error.message };
        });
    } catch (error) {
      dispatchMessage(
        "Score evaluation failed. Client side error occurred",
        false
      );
    }
  },
};

const dispatchMessage = (message, isSuccess) => {
  if (isSuccess) {
    store.dispatch({
      type: SUCCESS_MESSAGE,
      payload: message,
    });
  } else
    store.dispatch({
      type: ERROR_MESSAGE,
      payload: message,
    });
};
