import { useState, useEffect } from "react";
import { userEmail } from "../App";
import { imageTypes } from "../files/Lists";
import { fetchNameFromUrl } from "../files/util";
import { storageRef, storageRoot } from "../firebase";

const UseStorage = (fileRef, userInfo) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);
  console.log("fireref", fileRef);
  useEffect(() => {
    //references
    let file = fileRef.file;
    var name = "" + fileRef.fileName;
    if (!name && fileRef.file.name) name = fileRef.file.name;
    var d = new Date();
    var final_name =
      name.substring(0, name.lastIndexOf(".")) +
      "-" +
      d.getTime() +
      name.substring(name.lastIndexOf("."));
    console.log(final_name);
    let storage;
    if (userInfo) {
      storage = storageRef
        .child("SanjayApps-User-Files")
        .child("form-files")
        .child("user-form-files")
        .child(userInfo.data)
        .child(final_name);
    } else
      storage = storageRef
        .child("SanjayApps-User-Files")
        .child(userEmail)
        .child("form")
        .child(final_name);
    var metadata = {};
    if (userInfo) {
      metadata = { ...userInfo };
    }
    storage.put(file, metadata).on(
      "state_changed",
      (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgress(percentage);
      },
      (error) => {
        setError(error);
      },
      async () => {
        const url = await storage.getDownloadURL();
        setUrl(url);
      }
    );
  }, [fileRef]);
  return { progress, url, error };
};

const UseStorageFiles = (setImages) => {
  const [files, setFiles] = useState([]);
  useEffect(() => {
    var imagesRef = storageRef.child(
      "SanjayApps-User-Files/" + userEmail + "/form"
    );
    let imageList = [];
    // Find all the prefixes and items.
    imagesRef
      .listAll()
      .then((res) => {
        res.items.forEach((itemRef) => {
          console.log(itemRef.getMetadata());
          itemRef
            .getDownloadURL()
            .then(function (url) {
              // console.log(url);
              if (imageTypes.some((t) => url.toLowerCase().includes(t))) {
                imageList.push(url);
                let temp = files;
                temp.push({ url: url, name: fetchNameFromUrl(url) });
                setFiles(temp);
                setImages(JSON.parse(JSON.stringify(temp)));
              }
            })
            .catch(function (error) {
              // Handle any errors
              console.log(error);
            });
          setFiles(imageList);
          itemRef.listAll().then((folderRef) => {
            console.log("files", folderRef.items);
          });
        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });

    // var listRef = storageRef.child("SanjayApps-User-Files/" + userEmail);
    // let list = [];
    // // Find all the prefixes and items.
    // listRef
    //   .listAll()
    //   .then((res) => {
    //     res.prefixes.forEach((folderRef) => {
    //       // All the prefixes under listRef.
    //       // You may call listAll() recursively on them.
    //       console.log(folderRef);

    //       folderRef.listAll().then((imageFilesRef) => {
    //         console.log(imageFilesRef);
    //         imageFilesRef.items.forEach((img1) => {
    //           console.log(img1.getDownloadURL());
    //           list.push(img1.getDownloadURL());
    //         });
    //         setFiles(list);
    //       });
    //     });
    //     res.items.forEach((itemRef) => {
    //       // All the items under listRef for root.
    //       itemRef.listAll().then((imageFiles) => {
    //         imageFiles.items.forEach((img) => {
    //           console.log(img);
    //         });
    //         console.log(imageFiles);
    //       });
    //     });
    //   })
    //   .catch((error) => {
    //     // Uh-oh, an error occurred!
    //   });
  }, []);
  return { files };
};

const UseStorageDeletion = (url) => {
  console.log("deleting ", url);
  if (!url) return;

  storageRoot
    .refFromURL(url)
    .delete()
    .then(() => {
      console.log("deleted successfully");
    })
    .catch((error) => {
      console.log("Delete failed", error);
    });
};
export { UseStorage, UseStorageDeletion, UseStorageFiles };
