exports.THEME_VIEW = "THEME";
exports.SETTINGS_VIEW = "SETTINGS";
exports.MORE_OPTIONS = "MORE_OPTION";
exports.COLLECT_EMAIL = "COLLECT_EMAIL";
exports.ALLOW_EDIT = "ALLOW_EDIT";
exports.REQUIRE_LOGIN = "REQUIRE_SIGNIN";
exports.SHOW_PROGRESS = "SHOW_PROGRESS";
exports.SHUFFLE_QUES_ORDER = "SHUFFLE_QUES_ORDER";
exports.RESULT_SUMMERY = "RESULT_SUMMERY";
exports.DISABLE_AUTOSAVE = "DISABLE_AUTOSAVE";
exports.TYPE_QUIZ = "TYPE_QUIZ";
exports.RELEASE_GRADE = "RELEASE_GRADE";
exports.RELEASE_GRADE_NOW = "RELEASE_GRADE_NOW";
exports.RELEASE_GRADE_LATER = "RELEASE_GRADE_LATER";
exports.SHOW_MISSED_QUES = "SHOW_MISSED_QUES";
exports.SHOW_MISSED_QUES = "SHOW_MISSED_QUES";
exports.SHOW_MISSED_QUES = "SHOW_MISSED_QUES";
exports.SHOW_CORRECT_ANS = "SHOW_CORRECT_ANS";
exports.SHOW_POINT_VALUE = "SHOW_POINT_VALUE";
exports.SEND_RESPONSE = "SEND_RESPONSE";
exports.ACCEPT_RESPONSE = "ACCEPT_RESPONSE";
exports.FORM_START_DATE = "FORM_START_DATE";
exports.FORM_END_DATE = "FORM_END_DATE";
exports.PREVIEW_FORM = "PREVIEW_FORM";
exports.DISABLE_COPY_PASTE = "DISABLE_COPY_PASTE";
exports.COLLECT_EMAIL = "COLLECT_EMAIL";
exports.SHUFFLE_OPTIONS = "SHUFFLE_OPTIONS";
exports.QUESTION_POINTS_DEFAULT = "QUESTION_POINTS_DEFAULT";
exports.RESPONSE_MESSAGE = "RESPONSE_MESSAGE";
exports.RESPONSE_IMAGE = "RESPONSE_IMAGE";
exports.SINGLE_QUESTION_AT_A_TIME = "SINGLE_QUESTION_AT_A_TIME";
exports.ERROR_CODE = {
  FORM_EXPIRED: 11,
  FORM_NOT_START: 10,
  FORM_ENDED: 406,
  FORM_HAVE_RESPONSE: 401,
  FORM_SUBMITTED: 402,
};
