import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from "react-router-dom";
import AuthHome from "./auth/authHome";
import Home from "./pages/home";
import { createTheme, ThemeProvider } from "@material-ui/core";
import ViewForm from "./pages/view";
import { Provider } from "react-redux";
import store from "./store/store";
import GlobalMessage from "./components/GlobalMessage";

document.title = `Meta Forms`;
var uid = "";
var userName = "";
var userImage = "";

var userEmail = "";
try {
  uid = localStorage.getItem("uid");
  userName = localStorage.getItem("userName");
  userImage = localStorage.getItem("userImage");
  userEmail = localStorage.getItem("userEmail");
} catch (error) {
  console.log(error);
}
export { uid };
export { userName };
export { userImage };
export { userEmail };
function App() {
  const theme = createTheme({
    palette: {
      secondary: {
        main: "#00685c",
      },
      primary: {
        main: "#00996b",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" element={<Home />} />
            <Route path="/:id" element={<Home />} />
            <Route path="/:id/view" element={<ViewForm />} />
            <Route path="/auth" element={<AuthHome />} />
          </Switch>
        </Router>
        <GlobalMessage />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
